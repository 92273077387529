import { validatenull } from "@util/validate"

export const calcDate = (date1, date2) => {
  const date3 = date2 - date1

  const days = Math.floor(date3 / (24 * 3600 * 1000))

  const leave1 = date3 % (24 * 3600 * 1000) // 计算天数后剩余的毫秒数
  const hours = Math.floor(leave1 / (3600 * 1000))

  const leave2 = leave1 % (3600 * 1000) // 计算小时数后剩余的毫秒数
  const minutes = Math.floor(leave2 / (60 * 1000))

  const leave3 = leave2 % (60 * 1000) // 计算分钟数后剩余的毫秒数
  const seconds = Math.round(date3 / 1000)

  return {
    leave1,
    leave2,
    leave3,
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds
  }
}

/**
 * 日期格式化
 */
export function dateFormat(v, format) {
  if (validatenull(v)) return ''

  format = format || 'yyyy-MM-dd hh:mm:ss'

  let date = v

  if (typeof v === 'string') {
    if (v.indexOf('/Date(') > -1) {
      date = new Date(parseInt(v.replace('/Date(', '').replace(')/', ''), 10))
    } else {
      date = new Date(Date.parse(v.replace(/-/g, '/').replace('T', ' ').split('.')[0]))
    }
  }

  if (date !== 'Invalid Date') {
    const o = {
      "M+": date.getMonth() + 1, // month
      "d+": date.getDate(), // day
      "h+": date.getHours(), // hour
      "m+": date.getMinutes(), // minute
      "s+": date.getSeconds(), // second
      "q+": Math.floor((date.getMonth() + 3) / 3), // quarter
      "S": date.getMilliseconds() // millisecond
    }
    if (/(y+)/.test(format)) format = format.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length))

    for (const k in o) {
      if (new RegExp("(" + k + ")").test(format)) {
        format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length))
      }
    }

    return format
  }

  return ''
}

export function diffMonth(month, format) {
  const currTime = new Date()
  currTime.setMonth(currTime.getMonth() + (month))

  return dateFormat(currTime, format)
}

export function formatDateString(dateStr) {
  if(validatenull(dateStr)) {
    return '-'
  }
  // 将字符串切割成年、月、日
  var parts = dateStr.split('');
  var year = parts.slice(0, 4).join('');
  var month = parts.slice(4, 6).join('');
  var day = parts.slice(6).join('');

  // 返回格式化的日期字符串
  return [year, '-', month, '-', day].join('');
}

/**
 * 获取当前日期
 * @param format
 * @param week
 * @returns {string}
 */
export function currentDate(format, week = false) {
  const currTime = new Date()

  if (week) {
    const time = dateFormat(currTime, format)
    const weeks = new Array("日", "一", "二", "三", "四", "五", "六")

    return `${time} 星期${weeks[currTime.getDay()]}`
  } else {
    return dateFormat(currTime, format)
  }
}

/**
 * 计算日期差
 * @param format
 * @returns {string}
 */
export function diffDate(val, format = 'yyyy-MM-dd hh:mm:ss', type = 'date') {
  const currTime = new Date()

  switch (type) {
    case "date": {
      currTime.setDate(currTime.getDate() + (val))
      break
    }
    case "month": {
      currTime.setMonth(currTime.getMonth() + (val))
      break
    }
    case "seconds": {
      currTime.setSeconds(currTime.getSeconds() + (val))
    }
  }

  return dateFormat(currTime, format)
}

/**
 * 计算日期间隔
 * @param day
 * @returns {{end: string}}
 */
export function intervalDate(day, format) {
  const currTime = new Date(new Date().setHours(0, 0, 0, 0))
  currTime.setDate(currTime.getDate())
  const ret = {
    end: dateFormat(new Date(currTime.getTime() + 86399000), format)
  }

  const start = currTime.setDate(currTime.getDate() + (day))
  ret['start'] = dateFormat(new Date(start), format)

  return ret
}

/**
 * 计算日期间隔2,不包含今天
 * @param day
 * @returns {{end: string}}
 */
export function intervalDate2(day, format) {
  const currTime = new Date(new Date().setHours(0, 0, 0, 0))
  currTime.setDate(currTime.getDate())
  const ret = {
    end: dateFormat(new Date(currTime.getTime() - 1), format)
  }

  const start = currTime.setDate(currTime.getDate() + (day))
  ret['start'] = dateFormat(new Date(start), format)

  return ret
}

/**
 * 获取当月日期到当前日期
 * @param format
 * @returns {string}
 */
export function rangeDate(format = 'yyyy-MM-dd') {
  const currTime = new Date()
  const dateCur = dateFormat(currTime, format)
  const rangeArr = [dateCur.substring(0, 8) + '01 00:00:00', dateCur + ' 23:59:59']
  return rangeArr
}

// 获取当前日期时间
export function getCurrentTime() {
  const now = new Date()
  const year = now.getFullYear()
  const month = String(now.getMonth() + 1).padStart(2, '0')
  const day = String(now.getDate()).padStart(2, '0')
  const hours = String(now.getHours()).padStart(2, '0')
  const minutes = String(now.getMinutes()).padStart(2, '0')
  const seconds = String(now.getSeconds()).padStart(2, '0')
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}