import Vue from 'vue'
import VueI18n from 'vue-i18n'
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui lang
import enLocale from './en'
import zhLocale from './zh'

import { getStore } from '@/util/store'

import VPCJingPlugin from 'vpcjing-plugins'

Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale,
    ...VPCJingPlugin.locale.en
  },
  zh: {
    ...zhLocale,
    ...elementZhLocale,
    ...VPCJingPlugin.locale.zh
  }
}

const i18n = new VueI18n({
  locale: getStore({name: 'language'}) || 'zh',
  messages
})

export default i18n
