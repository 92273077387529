import Vue from 'vue'
import store from './store'
import { mapGetters } from 'vuex'

Vue.mixin({
  data() {
    return {
      maskLoading: false,
      btnDisabled: false,
      disabledAll: false
    }
  },
  methods: {
    // 返回
    closeCurrentTab() {
      this.$router.$avueRouter.closeTag()
      this.$router.back()
    },
    handleAwaitTips() {
      this.$message({
        message: '开发中，敬请期待！',
        type: 'warning',
        duration: this.$duration
      })
    }
  },
  computed: {
    ...mapGetters({
      localLoginUser: 'userInfo'
    })
  },
  beforeRouteLeave: function(to, from, next) {
    if (this.$route.meta.keepAlive === true) {
      const result = this.$route.meta.keepAlive === true && store.state.tags.tagList.some(ele => {
        return ele.value === this.$route.fullPath
      })
      if (this.$vnode && !result) {
        if (this.$vnode.parent && this.$vnode.parent.componentInstance && this.$vnode.parent.componentInstance.cache) {
          if (this.$vnode.componentOptions) {
            const key = this.$vnode.key == null
              ? this.$vnode.componentOptions.Ctor.cid + (this.$vnode.componentOptions.tag ? `::${this.$vnode.componentOptions.tag}` : '')
              : this.$vnode.key
            const cache = this.$vnode.parent.componentInstance.cache
            const keys = this.$vnode.parent.componentInstance.keys
            if (cache[key]) {
              if (keys.length) {
                const index = keys.indexOf(key)
                if (index > -1) {
                  keys.splice(index, 1)
                }
              }
              delete cache[key]
            }
          }
        }
      }
    }
    next()
  }
})
