import http from '@/router/axios'
import { clipApiPrefix } from '@/config/env'

const platformDriverApi = {}

/**
 * @Description: 分页查询数据导入记录
 */
 platformDriverApi.dataImportRecord = (object) => {
  return http.$POST(`${clipApiPrefix}/reportDataImportRecord/queryPage`,
  {
    showLoading: true,
    params: object
  })
 }

/**
 * @Description: 分页查询数据查看详情数据导入记录
 */
 platformDriverApi.dataImportDetail = (object) => {
  return http.$POST(`${clipApiPrefix}/reportDataImportRecord/queryPageDetail`,
  {
    showLoading: true,
    params: object
  })
 }

/**
 * @Description: 联盟驾驶员分页查询
 */
 platformDriverApi.queryChauffeurAlliance = (object) => {
  return http.$POST(`${clipApiPrefix}/chauffeur/queryChauffeurAlliancePage`, {
    params: object
  })
 }

/**
 * @Description: 司机黑名单管理
 */
platformDriverApi.queryChauffeurBelongPage = (object) => {
  return http.$POST(`${clipApiPrefix}/chauffeur/queryChauffeurBelongPage`, {
    params: object
  })
}

/**
 * @Description: 取消黑名单管理
 */
platformDriverApi.removeBackChauffeur = (object) => {
  return http.$POST(`${clipApiPrefix}/chauffeur/pullBackChauffeur`, {
    params: object
  })
}

/**
 * @Description: 司机黑名单拉黑操作
 */
platformDriverApi.pullBackChauffeur = (object) => {
  return http.$POST(`${clipApiPrefix}/chauffeur/pullBackChauffeur`, {
    params: object
  })
}

/**
 * @Description: 查询收款信息列表
 */
platformDriverApi.offlinePayAccount = (object) => {
  return http.$POST(`${clipApiPrefix}/imprest/offlinePayAccountQueryPage`, {
    params: object
  })
}
/**
 * @Description: 查询收款信息列表操作里面的编辑按钮的保存操作
 */
platformDriverApi.modifyOfflinePay = (object) => {
  return http.$POST(`${clipApiPrefix}/imprest/modifyOfflinePayAccount`, {
    params: object,
    showLoading: true
  })
}

/**
 * @Description: 查询收款信息里面的删除操作
 */
platformDriverApi.removeById = (object) => {
  return http.$GET(`${clipApiPrefix}/imprest/removeById`, {
    params: object,
    showLoading: true
  })
}

/**
 * @Description: 查询收款信息里面的导出操作
 */
platformDriverApi.exportOfflinePay = (object) => {
  return http.$POST(`${clipApiPrefix}/imprest/exportOfflinePayAccount`, {
    params: object,
    meta: {
      responseType: 'blob',
      filterResponse: false
    }
  })
}

/**
 * @Description: 新增线下付款账户数据保存按钮
 */
platformDriverApi.addOfflinePay = (object) => {
  return http.$POST(`${clipApiPrefix}/imprest/addOfflinePayAccount`, {
    params: object
  })
}

/**
 * @Description: 查询驾驶员列表
 */
platformDriverApi.pagePlatformDriver = (object) => {
  return http.$POST(`${clipApiPrefix}/chauffeur/v2/queryPageChauffeurBelong`, {
    // showLoading: true,
    params: object
  })
}

/**
 * @Description: 导出司机列表
 */
platformDriverApi.exportExcelChauffeur = (object) => {
  return http.$POST(`${clipApiPrefix}/chauffeur/v2/exportExcel`, {
    meta: {
      responseType: 'blob',
      filterResponse: false
    },
    showLoading: true,
    params: object
  })
}

/**
 * @Description: 导出司机信息
 */
platformDriverApi.exportFileZip = (object) => {
  return http.$POST(`${clipApiPrefix}/chauffeur/v2/exportFileZip`, {
    showLoading: true,
    params: object
  })
}

/**
 * @Description: 查询驾驶员详情
 */
platformDriverApi.getDriverInfoById = (object) => {
  return http.$POST(`${clipApiPrefix}/chauffeur/v2/chauffeurBelong/query`, {
    showLoading: true,
    params: object
  })
}

/**
 * @Description: 添加驾驶员信息
 */
platformDriverApi.addChauffeur = (object) => {
  return http.$POST(`${clipApiPrefix}/chauffeur/v2/chauffeurBelong/add`, {
    showLoading: true,
    params: object
  })
}

/**
 * @Description: 司机注册检测v2
 */
platformDriverApi.addChauffeurRegister = (object) => {
  return http.$POST(`${clipApiPrefix}/chauffeur/v2/checkChauffeurRegister`, {
    showLoading: false,
    params: object
  })
}

/**
 * @Description: 根据用户id查看司机认证信息
 */
platformDriverApi.queryChauffeurAuthByUserId = (object) => {
  return http.$POST(`${clipApiPrefix}/chauffeur/v2/queryChauffeurAuthByUserId`, {
    isParams: true,
    showLoading: false,
    params: object
  })
}

/**
 * @Description: 修改驾驶员信息
 */
platformDriverApi.modifyChauffeur = (object) => {
  return http.$POST(`${clipApiPrefix}/chauffeur/v2/chauffeurBelong/modify`, {
    showLoading: true,
    params: object
  })
}

/**
 * @Description: 引入驾驶员信息
 */
platformDriverApi.introduceChauffeur = (object) => {
  return http.$POST(`${clipApiPrefix}/chauffeur/v2/chauffeurBelong/introduce`, {
    showLoading: true,
    params: object
  })
}

/**
 * @Description: 添加司机实名认证信息
 */
platformDriverApi.addRealNameAuth = (object) => {
  return http.$POST(`${clipApiPrefix}/chauffeur/v2/chauffeurAuth/add`, {
    showLoading: true,
    params: object
  })
}

/**
 * @Description: 修改司机实名认证信息
 */
platformDriverApi.editRealNameAuth = (object) => {
  return http.$POST(`${clipApiPrefix}/chauffeur/v2/chauffeurAuth/belongModify`, {
    showLoading: true,
    params: object
  })
}

/**
 * @Description: 审核驾驶员信息
 */
platformDriverApi.modifyChauffeurAuditState = (object) => {
  return http.$POST(`${clipApiPrefix}/chauffeur/chauffeurBelongAuditState`, {
    showLoading: true,
    params: object,
    isParams: true
  })
}

/**
 * @Description: OCR身份证正面
 */
platformDriverApi.idcardFrnt = (object) => {
  return http.$POST(`${clipApiPrefix}/api/ocr/idcardFrnt`, {
    showLoading: true,
    params: object
  })
}

/**
 * @Description: OCR身份证反面
 */
platformDriverApi.idcardBack = (object) => {
  return http.$POST(`${clipApiPrefix}/api/ocr/idcardBack`, {
    showLoading: true,
    params: object
  })
}

/**
 * @Description: OCR驾驶证正面
 */
platformDriverApi.driverLicenseFrnt = (object) => {
  return http.$POST(`${clipApiPrefix}/api/ocr/driverLicenseFrnt`, {
    showLoading: true,
    params: object
  })
}

/**
 * @Description: OCR驾驶证反面
 */
platformDriverApi.driverLicenseBack = (object) => {
  return http.$POST(`${clipApiPrefix}/api/ocr/driverLicenseBack`, {
    showLoading: true,
    params: object
  })
}

/**
 * @Description: 导出收款信息
 */
platformDriverApi.exportOfflinePayAccount = (object) => {
  return http.$POST(`${clipApiPrefix}/imprest/exportOfflinePayAccount`, {
    meta: {
      responseType: 'blob',
      filterResponse: false
    },
    showLoading: true,
    params: object
  })
}

/**
 * @Description: 保存司机临时数据
 */
platformDriverApi.saveTempChauffeur = (object) => {
  return http.$POST(`${clipApiPrefix}/chauffeur/save/temp`, {
    showLoading: false,
    params: object
  })
}

/**
 * @Description: 查询司机临时数据
 */
platformDriverApi.getTempChauffeur = (object) => {
  return http.$GET(`${clipApiPrefix}/chauffeur/get/temp`, {
    showLoading: true,
    params: object
  })
}

/**
 * @Description: 司机同步到推送模块
 */
platformDriverApi.addDriverSendPush = (object) => {
  return http.$POST(`${clipApiPrefix}/waybill/addDriverSendPush`, {
    showLoading: true,
    params: object
  })
}

/**
 * @Description: 保存驾驶员标签
 */
platformDriverApi.saveChauffeurTag = (object) => {
  return http.$POST(`${clipApiPrefix}/chauffeur/saveChauffeurTag`, {
    showLoading: true,
    params: object
  })
}

/**
 * @Description: 获取企业的司机标签列表
 */
platformDriverApi.getEnterpriseChauffeurTabList = (object) => {
  return http.$POST(`${clipApiPrefix}/chauffeur/getEnterpriseChauffeurTabList`, {
    showLoading: false,
    params: object
  })
}

/**
 * @Description: 司机绑定工行账户
 */
platformDriverApi.chauffeurBindICBC = (object) => {
  return http.$POST(`${clipApiPrefix}/icbcBank/personRegister`, {
    showLoading: false,
    params: object
  })
}

/**
 * @Description: 获取司机绑定的工行账户
 */
platformDriverApi.getICBCTransportAccount = (object) => {
  return http.$GET(`${clipApiPrefix}/icbcBank/getTransportAccount`, {
    showLoading: false,
    params: object
  })
}

/**
 * @Description: 获取司机绑定的工行账户
 */
platformDriverApi.bankcardInfoQuery = (object) => {
  return http.$GET(`${clipApiPrefix}/realName/bankcardInfoQuery`, {
    showLoading: false,
    params: object
  })
}

/**
 * @Description: 获取司机绑定的工行账户
 */
platformDriverApi.bankInfoQuery = (object) => {
  return http.$POST(`${clipApiPrefix}/icbcBank/bankInfoQuery`, {
    showLoading: false,
    params: object
  })
}

export default platformDriverApi
