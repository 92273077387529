import http from '@/router/axios'
import { clipApiPrefix } from '@/config/env'

const ssoApi = {}

/**
 * @date 2021-07-03
 * @Description: 本地登陆
 * @Param:
 */
ssoApi.localLogin = (object) => {
  return http.$POST(`${clipApiPrefix}/api/login/doMobileLogin`, {
    params: object,
    meta: {
      isToken: false
    }
  })
}

/**
 * @date 2023-07-18
 * @Description: 本地登陆，密码验证码,双重监测
 * @Param:
 */
ssoApi.localLoginDoubleCheck = (object) => {
  return http.$POST(`${clipApiPrefix}/api/login/doLoginByMobileAndPassword`, {
    params: object,
    meta: {
      isToken: false
    }
  })
}

/**
 * @date 2021-07-03
 * @Description: 手机号密码 + 数字验证码登录
 * @Param:
 */
ssoApi.doPasswordLogin = (object) => {
  return http.$POST(`${clipApiPrefix}/api/login/doPasswordLogin`, {
    params: object,
    meta: {
      isToken: false
    }
  })
}

/**
 * @date 2021-07-03
 * @Description: 忘记密码-修改密码
 * @Param:
 */
ssoApi.forgetUpdatePwd = (object) => {
  return http.$POST(`${clipApiPrefix}/api/login/forgetUpdatePwd`, {
    params: object,
    meta: {
      isToken: false
    }
  })
}

/**
 * @date 2021-07-03
 * @Description: 修改密码(不知道原有密码的情况下，重置密码)
 * @Param:
 */
ssoApi.updatePwd = (object) => {
  return http.$POST(`${clipApiPrefix}/api/login/updatePwd`, {
    params: object,
    meta: {
      isToken: true
    }
  })
}

/**
 * @date 2021-07-03
 * @Description: 修改密码(知道原有密码)
 * @Param:
 */
ssoApi.updatePwd2 = (object) => {
  return http.$POST(`${clipApiPrefix}/api/login/updatePwd2`, {
    params: object,
    meta: {
      isToken: true
    }
  })
}

/**
 * @date 2021-07-03
 * @Description: 修改密码(重置密码,发短信验证码)
 * @Param:
 */
ssoApi.updatePwd3 = (object) => {
  return http.$POST(`${clipApiPrefix}/api/login/updatePwd3`, {
    params: object,
    meta: {
      isToken: true
    }
  })
}
/**
 * @date 2021-07-03
 * @Description: 生成图形验证码
 * @Param:
 */
ssoApi.genCaptcha = (object) => {
  return http.$GET(`${clipApiPrefix}/api/login/genCaptcha`, {
    params: object,
    meta: {
      isToken: false
    }
  })
}

/**
 * @date 2021-07-03
 * @Description: 发送验证码
 * @Param:
 */
ssoApi.getVerifyCode = (object) => {
  return http.$POST(`${clipApiPrefix}/api/login/sendVerifyCode`, {
    params: object,
    meta: {
      isToken: false
    }

  })
}

/**
 * @date 2019-12-26 09:22:40
 * @Description: 登出
 * @Param:
 */
ssoApi.localLogout = (object) => {
  return http.$POST(`${clipApiPrefix}/api/logout/local`, {
    params: object,
    meta: {
      catchError: false
    }
  })
}

/**
 * @Description: 获取企业审核状态和信息
 */
ssoApi.getEnterpriseAuditVo = (object) => {
  return http.$POST(`${clipApiPrefix}/enterprise/getEnterpriseAuditVo`, {
    params: object
  })
}

/**
 * @Description: 入驻平台
 */
 ssoApi.enterpriseRegister = (object) => {
  return http.$POST(`${clipApiPrefix}/enterprise/enterpriseRegister`, {
    params: object
  })
}

/**
 * @Description: 灰色主题
 */
 ssoApi.isLamentConfig = (object) => {
  return http.$GET(`${clipApiPrefix}/config/isLamentConfig`, {
    params: object
  })
}

/**
 * @Description: 微信扫码获取信息
 */
 ssoApi.getWxCode = (object) => {
  return http.$GET(`${clipApiPrefix}/api/login/getWxCode`, {
    params: object
  })
}

/**
 * @Description: 微信扫码获取信息
 */
 ssoApi.scanQrCodeValue = (object) => {
  return http.$GET(`${clipApiPrefix}/api/login/get/scanQrCode/value`, {
    params: object
  })
}
export default ssoApi
