/**
 * 全站权限配置
 */
import router from './router'
import store from '../store'
import { validatenull } from '@/util/validate'
import { getToken } from '@/util/auth'
// import NProgress from 'nprogress' // progress bar
// import 'nprogress/nprogress.css'
// import website from '@/config/website'
import { resetRouter } from './router'

// NProgress.configure({ showSpinner: false })

// 检查是否为系统升级
// const checkUpgrade = (to, next) => {
//   if (to.path === '/upgrade' || !website.isSystemUpgrade) return true

//   let isUpgrade = to.query.isUpgrade // 升级时的特有标识
//   if (!validatenull(isUpgrade)) store.commit('SET_ISUPGRADE', isUpgrade)

//   isUpgrade = store.getters.isUpgrade
//   if (validatenull(isUpgrade) || Number(isUpgrade) !== 1) {
//     next({ path: '/upgrade' })
//   }
//   return true
// }

const noVerifyPageRouterList = [
  '/',
  '/main',
  '/main/index',
  '/login',
  '/404',
  '/404/index',
  '/upgrade',
  '/test',
  '/test/index',
  '/test/ocr',
  '/myiframe',
  '/goodsSupply/goodsSupplyDetail',
  '/goodsSupply/planDetailNew',
  '/goodsSupply/planImportList',
  '/goodsSupply/publishGoodsSupply',
  '/goodsSupply/rePublishGoodsSupply',
  '/goodsSupply/addPlanOrder',
  '/goodsSupply/editPlanOrder',
  '/orderManager/buildOrder',
  '/orderManager/editOrder',
  '/orderManager/orderAcceptance',
  '/orderManager/lclOrderAcceptance',
  '/orderManager/orderDetail',
  '/orderManager/lclOrderDetail',
  '/orderManager/lclOrderEdit',
  '/orderManager/splitOrderByQuantity',
  '/orderManager/splitOrderByPiece',
  '/orderManager/splitOrder',
  '/orderManager/subcontract',
  '/orderManager/addFiscalEntrust',
  '/orderManager/fiscalEntrustDetail',
  '/orderManager/settlementAdd',
  '/orderManager/settlementDetail',
  '/orderManager/queckOrder',
  '/waybill/waybillDetail',
  '/finance/billDetail',
  '/finance/addTemplates',
  '/finance/editTemplates',
  '/finance/imprestPay',
  '/etcInvoice/etcInvoiceRecord',
  '/finance/profitPrint',
  '/goodsSupply/planAdd',
  '/orderManager/planToOrder',
  '/goodsSupply/planEdit',
  '/onlineStore/payForOrder',
  '/onlineStore/mallDetail',
  '/operationsManagement/orderAcceptanceAdd',
  '/operationsManagement/orderAcceptanceAdd2',
  '/operationsManagement/createdPickUp',
  '/operationsManagement/editPickUp',
  '/operationsManagement/buildDispatch',
  '/operationsManagement/dispatchDetail',
  '/operationsManagement/createdDelivery',
  '/operationsManagement/deliveryDetail',
  '/operationsManagement/arriveCarDetail',
  '/operationsManagement/arriveCarUnloading',
  '/operationsManagement/createTransfer',
  '/operationsManagement/editTransfer',
  '/operationsManagement/transferDetail',
  '/operationsManagement/transferInAccept',
  '/operationsManagement/detailTransfer',
  '/message/index',
  '/statementCenter/reportCard',
  '/goodsSupply/planDetail',
  '/platform/vehiclePoolMap',
  '/statementCenter/financeData',
  '/platform/vehiclePosition',
  '/orderManager/orderImport',
  '/operationsManagement/recycleList',
  '/operationsManagement/sendList',
  '/operationsManagement/takeList',
  '/operationsManagement/giveOutList',
  '/finance/generateVoucher',
  '/finance/generateVoucherByOrder',
  '/finance/voucher',
  '/finance/voucherEdit',
  '/finance/voucherPrint',
  '/allianceManagement/createOrder',
  '/allianceManagement/allianceApply',
  '/platform/receiveInfo',
  '/operationsManagement/addScheduledTask',
  '/operationsManagement/editScheduledTask',
  '/operationsManagement/scheduledTaskDetail',
  '/operationsManagement/addOrder',
  '/operationsManagement/orderImport',
  '/operationsManagement/addNewOrder',
  '/operationsManagement/acceptOrder',
  '/operationsManagement/editOrder',
  '/operationsManagement/orderDetail',
  '/operationsManagement/waybillDetail',
  '/operationsManagement/scanDispatch',
  '/operationsManagement/addScanDispatch',
  '/operationsManagement/editScanDispatch',
  '/operationsManagement/addNewScanDispatch',
  '/operationsManagement/scanDispatchDetail',
  '/company/enterpriseVali',
] // 不鉴权白名单
store.commit('SET_noVerifyPageRouterList', noVerifyPageRouterList)
router.beforeEach((to, from, next) => { // 路由守卫
  const axiosCancel = store.getters.axiosCancel
  axiosCancel.forEach((e) => {
    e && e()
  })
  store.commit('SET_CLEARCANCEL')

  // checkUpgrade(to, next)

  const meta = to.meta || {}
  const isMenu = meta.menu === undefined ? to.query.menu : meta.menu
  store.commit('SET_IS_MENU', isMenu === undefined)

  if (getToken()) {
    if (to.path === '/login') {
      // 如果登录成功访问登录页跳转到主页
      next({ path: '/' })
    } else {
      const menuPathList = store.getters.menuPathList
      // const menuIconList = store.getters.menuIconList
      // 嵌套动态路由，详情的支持
      const pathSplitList = to.path.split('/')
      if (pathSplitList.length > 3) {
        let buildPathSplit = ''
        pathSplitList.forEach((item, index) => {
          if (index < pathSplitList.length - 2) {
            buildPathSplit += item + '/'
          }
          if (index === pathSplitList.length - 2) {
            buildPathSplit += item
          }
        })
        if (
          !noVerifyPageRouterList.includes(buildPathSplit)
        ) {
          next({ path: '/404/index', replace: true })
          return
        }
      } else {
        if (
          !menuPathList.includes(to.path) &&
          !noVerifyPageRouterList.includes(to.path)
        ) {
          next({ path: '/404/index', replace: true })
          return
        }
      }
      const value = to.query.src || to.fullPath
      const label = to.query.name || to.name
      const meta = to.meta || router.$avueRouter.meta || {}
      const i18n = to.query.i18n
      // const menuIcon = menuIconList.find((item) => {
      //   return item.path === value
      // })
      if (
        meta.isTab !== false &&
        !validatenull(value) &&
        !validatenull(label)
      ) {
        store.commit('ADD_TAG', {
          label: label,
          value: value,
          // icon: menuIcon ? menuIcon.icon : '',
          params: to.params,
          query: to.query,
          meta: (() => {
            if (!i18n) {
              return meta
            }
            return {
              i18n: i18n
            }
          })(),
          group: router.$avueRouter.group || []
        })
        const menuList = store.getters.menu
        let menuIndex = -1
        let menuName = ''
        menuList.forEach((item, index) => {
          if (item.path === value) {
            menuIndex = index
            menuName = item.name
          } else {
            if (item.children && item.children.length > 0) {
              for (let i = 0; i < item.children.length; i++) {
                const element = item.children[i]
                if (element.path === value) {
                  menuIndex = index
                  menuName = element.name
                  break
                }
              }
            }
          }
        })
        if (menuIndex > -1) {
          store.commit('SET_RECENTLY_VISITED', {
            name: menuName,
            icon: menuList[menuIndex].icon,
            path: value
          })
        }
      }
      next()
    }
  } else {
    // 判断是否需要认证，没有登录访问去登录页
    if (meta.isAuth === false) {
      next()
    } else {
      store.dispatch('FedLogOut').then(() => {
        resetRouter()
        next('/login')
      })
    }
  }
})

router.afterEach((to) => {
  // NProgress.done()

  // let title = to.name // store.getters.tag.label

  // const i18n = store.getters.tag.meta.i18n
  // title = router.$avueRouter.generateTitle(title, i18n)
  const value = to.query.src || to.fullPath
  const menuList = store.getters.menu
  let menuName = ''
  menuList.forEach((item, index) => {
    if (item.path === value) {
      menuName = item.name
    } else {
      if (item.children && item.children.length > 0) {
        for (let i = 0; i < item.children.length; i++) {
          const element = item.children[i]
          if (element.path === value) {
            menuName = element.name
            break
          }
        }
      }
    }
  })
  // 根据当前的标签也获取label的值动态设置浏览器标题
  router.$avueRouter.setTitle(menuName)
})
