<template>
  <el-input-number
    :placeholder="placeholderNew"
    :step="step"
    :stepStrictly="stepStrictly"
    :max="max"
    :min="min"
    v-model="setValue"
    :disabled="disabled"
    :size="size"
    :controls="controls"
    :controlsPosition="controlsPosition"
    :name="name"
    :label="label"
    :precision="precision"
    @blur="handleBlur"
    @focus="handleFocus"
    @change="handleChange"
  ></el-input-number>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'my-input-number',

  props: {
    step: {
      type: Number,
      default: 1
    },
    stepStrictly: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number,
      default: Infinity
    },
    min: {
      type: Number,
      default: -Infinity
    },
    value: Number,
    disabled: Boolean,
    size: String,
    controls: {
      type: Boolean,
      default: true
    },
    controlsPosition: {
      type: String,
      default: ''
    },
    name: String,
    label: String,
    placeholder: String,
    precision: {
      type: Number,
      validator(val) {
        return val >= 0 && val === parseInt(val, 10)
      }
    }
  },
  data() {
    return {
      setValue: this.value
    }
  },
  watch: {
    setValue(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.setValue = val
    }
  },
  computed: {
    ...mapGetters(['globalReplaceTextList']),
    placeholderNew() {
      let placeholderNew = this.placeholder
      if(placeholderNew) {
        this.globalReplaceTextList.forEach((element) => {
          if (placeholderNew.indexOf(element.name) > -1) {
            placeholderNew = placeholderNew.replaceAll(
              element.name,
              element.value
            )
          }
        })
      }
      return placeholderNew
    }
  },
  methods: {
    handleBlur(event) {
      this.$emit('blur', event)
    },
    handleFocus(event) {
      this.$emit('focus', event)
    },
    handleChange(value) {
      this.$nextTick(() => {
        this.$emit('change', value)
      })
    },
  }
}
</script>
