var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "basic-container" },
    [
      _c(
        "el-card",
        {
          staticClass: "basic-container--height",
          class: { "basic-container__menu": _vm.$slots.menu },
          attrs: { shadow: "always" }
        },
        [
          !_vm.validatenull(_vm.title)
            ? _c(
                "section",
                {
                  staticClass:
                    "title-container d-flex flex-justify-content-between"
                },
                [
                  _c(
                    "span",
                    { staticClass: "title pt5 pb5 pl10 text-size-14" },
                    [_vm._v(_vm._s(_vm.title))]
                  ),
                  _vm._t("headerMenu")
                ],
                2
              )
            : _vm._e(),
          _vm._t("default")
        ],
        2
      ),
      _vm.$slots.menu
        ? _c(
            "section",
            { staticClass: "basic-container--menu" },
            [_vm._t("menu")],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }