var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-input-number", {
    attrs: {
      placeholder: _vm.placeholderNew,
      step: _vm.step,
      stepStrictly: _vm.stepStrictly,
      max: _vm.max,
      min: _vm.min,
      disabled: _vm.disabled,
      size: _vm.size,
      controls: _vm.controls,
      controlsPosition: _vm.controlsPosition,
      name: _vm.name,
      label: _vm.label,
      precision: _vm.precision
    },
    on: {
      blur: _vm.handleBlur,
      focus: _vm.handleFocus,
      change: _vm.handleChange
    },
    model: {
      value: _vm.setValue,
      callback: function($$v) {
        _vm.setValue = $$v
      },
      expression: "setValue"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }