import { validatenull } from '@/util/validate'
import store from '@/store/index'
import dictionaryApi from "@/api/system/dictionary"

export const chooseAll = [
    {
        label: '全部', value: ''
    }
]

/**
 * key：字典key
 * needAll：是否要全选
 * type：字典类型
 * replaceAll：全部的替换文字
 * */
// 根据KEY获取枚举
export const getEnumOnKey = (key, { needAll = false, type = 'dict', replaceAll } = {}) => {
    if (validatenull(key)) return []
    let temp = []
    switch (type) {
      case 'dict': {
        const enums = store.getters.dictionary
        let filterTemp = enums.filter((item) => {
          return item.categoryCode === key
        })
        if (validatenull(filterTemp)) {
          dictionaryApi.getDictByCode({'fieldName': key}).then((res) => {
            filterTemp = res.data
            if (!validatenull(filterTemp)) {
              store.commit('SET_DICTIONARY', Object.assign(enums, filterTemp))
            }
          })
        }
        temp = filterTemp.map((item) => {
          return {
            label: item.name,
            value: item.value
          }
        })
        break
      }
    }
    if (needAll) {
        if (validatenull(replaceAll)) {
            return chooseAll.concat(temp)
        } else {
            const tempAll = Object.assign({}, chooseAll[0])
            tempAll.label = `--- ${replaceAll} ---`
            return [tempAll].concat(temp)
        }
    }
    return temp
}

/**
 * 跟上面一样的方法,为了解决后端表数据字典字段有些为数字有些为字符串的问题。
 * */
export const getEnumOnKey2 = (key, { needAll = false, type = 'dict', replaceAll } = {}) => {
    if (validatenull(key)) return []
    let temp = []
    switch (type) {
        case 'dict': {
            const enums = store.getters.dictionary
            let filterTemp = enums.filter((item) => {
                return item.categoryCode === key
            })
            if (validatenull(filterTemp)) {
              dictionaryApi.getDictByCode({'fieldName': key}).then((res) => {
                filterTemp = res.data
                if (!validatenull(filterTemp)) {
                  store.commit('SET_DICTIONARY', Object.assign(enums, filterTemp))
                }
              })
            }
            temp = filterTemp.map((item) => {
                return {
                    label: item.name,
                    value: parseInt(item.value)
                }
            })
            break
        }
    }
    if (needAll) {
        if (validatenull(replaceAll)) {
            return chooseAll.concat(temp)
        } else {
            const tempAll = Object.assign({}, chooseAll[0])
            tempAll.label = `--- ${replaceAll} ---`
            return [tempAll].concat(temp)
        }
    }
    return temp
}

// 根据value获取枚举的描述
export const getEnumLabelByVal = (key, val, type = 'dict') => {
    if (validatenull(key) || validatenull(val)) return ''
    const enums = getEnumOnKey(key, { needAll: false, type: type })
    const ret = enums.filter((item) => {
        return item.value === val || item.value === val.toString()
    })
    if (ret.length <= 0) return ''
    return ret[0].label
}

// 根据label获取枚举的值
export const getEnumValByLabel = (key, label, type = 'dict') => {
    if (validatenull(key) || validatenull(label)) return ''
    const enums = getEnumOnKey(key, { needAll: false, type: type })
    const ret = enums.filter((item) => {
        return item.label === label
    })
    if (ret.length <= 0) return ''
    return ret[0].value
}

// 文本替换
export const replaceEnumLabel = (type, key, label, reLabel = '') => {
    if (type === 'unitType' && key === 1) return '/吨'
    return label
}
