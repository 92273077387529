<template>
  <div class="avue-contail" :class="{ 'avue--collapse': isCollapse }">
    <div class="avue-header">
      <!-- 顶部导航栏 -->
      <top ref="top" />
      <upgrade-browser></upgrade-browser>
    </div>

    <div class="avue-layout">
      <div class="avue-left">
        <!-- 左侧导航栏 -->
        <sidebar @tagsMore="tagsMore" />
      </div>
      <div class="avue-main" :class="{ 'avue-main--fullscreen': !isMenu }">
        <!-- 顶部标签卡 -->
        <tags :isTagsMore="isTagsMore" />
        <!-- 主体视图层 -->
        <div id="avue-view" class="avue-view-container">
          <keep-alive :exclude="keepAliveExclude">
            <router-view
              class="avue-view"
              v-if="$route.meta.$keepAlive"
              :key="$route.fullPath"
            />
          </keep-alive>
          <router-view
            class="avue-view"
            v-if="!$route.meta.$keepAlive"
            :key="$route.fullPath"
          />
        </div>
      </div>
    </div>

    <div class="avue-shade" @click="showCollapse"></div>
    <my-pdf :pdfUrl="pdfUrl" :isShowPdf="isShowPdf" @close="closePdf"></my-pdf>
    <img-viewer
      :imgList="imgViewerList"
      v-if="isShowImgViewer"
      :on-close="closeViewer"
    ></img-viewer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import admin from '@/util/admin'
import addressApi from '@/api/address/addressApi'
import configSettingApi from "@/api/configSetting/configSettingApi"
import UpgradeBrowser from './top/upgrade-browser.vue'

export default {
  components: {
    top: () => import('./top/index'),
    tags: () => import('./tags'),
    sidebar: () => import('./sidebar/index'),
    UpgradeBrowser
  },
  name: 'index',
  provide() {
    return {
      index: this
    }
  },
  watch: {
    pdfUrl: {
      handler(val) {
        if (val !== '') {
          this.isShowPdf = true
        }
      }
    },
    imgViewerList: {
      handler(val) {
        if (val.length > 0) {
          this.isShowImgViewer = true
        }
      }
    }
  },
  data() {
    return {
      isShowPdf: false,
      isShowImgViewer: false,
      isTagsMore: false
    }
  },
  created() {
    this.getWebElementIsShow()
  },
  mounted() {
    this.$store.commit('SET_GLOBAL_REPLACE_TEXT_LIST', [])
    this.getCountryList()
    this.$store.dispatch('LoadSysTextReplaceConfig')
    this.$nextTick(() => {
      this.init()
    })
  },
  computed: mapGetters([
    'keepAliveExclude',
    'isMenu',
    'isCollapse',
    'website',
    'menu',
    'userInfo',
    'noVerifyPageRouterList',
    'pdfUrl',
    'imgViewerList'
  ]),
  methods: {
    getCountryList() {
      addressApi.queryAllCountry().then((ret) => {
        const countryList = ret.data.map((item) => {
          return { name: item.countryName, code: item.countryCode, latitude: item.latitude, longitude: item.longitude }
        })
        window.localStorage.setItem('countryList', JSON.stringify(countryList))
        window.localStorage.setItem('defaultCountry', 'CHN')
        window.localStorage.setItem('defaultCountryName', '中国')
      })
    },
    getDefaultCountry(countryList = []) {
      configSettingApi
        .batchGetValue({ codes: ['BUSINESS_NATION_DEFAULT'] })
        .then((ret) => {
          const retData = ret.data['BUSINESS_NATION_DEFAULT']
          const defaultCountryName = countryList.find((item) => {
            return item.code === retData
          })
        })
    },
    getWebElementIsShow() {
      const params = [
        'HOME_PAGE_SHOW_ENTERPRISE_LOGO',
        'HOME_PAGE_SHOW_FREE_ENTRY',
        'HOME_PAGE_SHOW_ALLIANCE',
        'HOME_PAGE_SHOW_USER_EXPERIENCE',
        'HOME_PAGE_SHOW_QUICK_SCAN_ORDER',
        'HOME_PAGE_SHOW_QUICK_HELP',
        'HOME_PAGE_SHOW_INSTRUCTIONS',
        'EXTERNAL_SYSTEM_CARNETWORKING_ACCOUNT', // 天行健账号，密码
        'EXTERNAL_SYSTEM_CARNETWORKING_PASSWORD'
      ]
      configSettingApi
        .batchGetValue({ codes: params })
        .then((ret) => {
          window.localStorage.setItem('webElementIsShow', JSON.stringify(ret.data))
          window.localStorage.setItem('TXJACCOUNT', ret.data['EXTERNAL_SYSTEM_CARNETWORKING_ACCOUNT'])
          window.localStorage.setItem('TXJPWD', ret.data['EXTERNAL_SYSTEM_CARNETWORKING_PASSWORD'])
        })
    },
    closePdf() {
      this.isShowPdf = false
      this.$store.commit('SET_PDFURL', '')
    },
    closeViewer() {
      this.isShowImgViewer = false
      this.$store.commit('SET_IMG_VIEWER_LIST', [])
    },
    tagsMore(tagsMore) {
      this.isTagsMore = tagsMore
    },
    showCollapse() {
      this.$store.commit('SET_COLLAPSE')
    },
    // 屏幕检测
    init() {
      this.$store
        .dispatch('LoadResourceTree', { employeeId: this.userInfo.employeeId })
        .then((ret) => {
          const tagList = this.$store.getters.tagList
          tagList.forEach((element) => {
            const pathNoParams = element.value.split('?')[0]
            const pathLength = pathNoParams.split('/').length // 是否为三级路由，动态路由判断
            if (
              ret.indexOf(element.value) === -1 &&
              this.noVerifyPageRouterList.indexOf(pathNoParams) === -1 &&
              pathLength < 3
            ) {
              this.$router.$avueRouter.closeTag(element.value)
              this.$router.push(this.website.firstPage.value)
            }
          })
          this.$nextTick(() => {
            setTimeout(() => {
              if(!this.userInfo.isEnterpriseUnvali) {
                this.$confirm('企业未认证通过，请完善或修改企业资料', '提示', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning'
              })
                .then(() => {
                  this.$router
                    .push({ path: '/company/enterpriseVali' })
                    .catch(() => {})
                })
                .catch(() => {
                  this.$router
                    .push({ path: '/company/enterpriseVali' })
                    .catch(() => {})
                })
              }
            }, 2000);
          })
        })
      this.$store.commit('SET_SCREEN', admin.getScreen())
      window.onresize = () => {
        setTimeout(() => {
          this.$store.commit('SET_SCREEN', admin.getScreen())
        }, 0)
      }
    },
    // 打开菜单
    openMenu() {
      const menu = this.$store.getters.menu
      if (menu.length === 0) {
        this.$store.dispatch('LoadResourceTree', {
          employeeId: this.userInfo.employeeId
        })
      }
    }
  }
}
</script>
