import { validatenull } from "@util/validate"

const getters = {
  vehicleLocationAndTrajectoryPlateNo: state => {
    return state.common.vehicleLocationAndTrajectoryPlateNo
  },
  voucherPrintDataList: state => {
    return state.common.voucherPrintDataList
  },
  subjectList: state => {
    return state.common.subjectList
  },
  auxiliaryObj: state => {
    return state.common.auxiliaryObj
  },
  recentlyVisitedList: state => { // 要过滤掉不存在权限path
    const recentlyList = state.common.recentlyVisitedList
    const perRecentlyList = recentlyList.filter(item => {
      const some = state.user.menuPathList.some(item2 => {
        return item2 === item.path
      })
      return some
    })
    return perRecentlyList
  },
  quickMenuList: state => { // 要过滤掉不存在权限path
    const quickMenuList = state.common.quickMenuList
    const perQuickMenuList = quickMenuList.filter(item => {
      const some = state.user.menuPathList.some(item2 => {
        return item2 === item.path
      })
      return some
    })
    return perQuickMenuList
  },
  defaultOpenSubMenu: state => state.tags.defaultOpenSubMenu,
  firstPageComponentKey: state => state.tags.firstPageComponentKey,
  tag: state => state.tags.tag,
  tagList: state => state.tags.tagList,
  tagWel: state => state.tags.tagWel,
  messageDetail: state => state.common.messageDetail,
  fontConfig: state => state.common.fontConfig,
  heatmapProvince: state => state.common.heatmapProvince,
  heatmapVehicleNo: state => state.common.heatmapVehicleNo,
  // isUpgrade: state => state.common.isUpgrade, // 是否系统升级
  language: state => state.common.language,
  showLanguage: state => state.common.showLanguage,
  website: state => state.common.website,
  userInfo: state => state.user.userInfo,
  themeName: state => state.common.themeName,
  isCollapse: state => state.common.isCollapse,
  keyCollapse: (state, getters) => getters.screen > 1 ? getters.isCollapse : false,
  screen: state => state.common.screen,
  isFullScren: state => state.common.isFullScren,
  isMenu: state => state.common.isMenu,
  dictionary: (state) => state.common.dictionary,
  dictFee: (state) => state.common.dictFee,
  userMqtt: (state) => state.user.userMqtt,
  unReadData: (state) => state.common.unReadData,
  sceneFeePcpi: state => (sceneCode) => {
    if (validatenull(sceneCode)) return []
    const sceneFeePcpi = state.common.sceneFeePcpi

    return sceneFeePcpi.filter((item) => {
      return item.sceneCode === sceneCode
    })
  },
  axiosCancel: (state) => state.common.axiosCancel,
  platformConfig: (state) => state.common.platformConfig,
  token: state => state.user.token,
  permission: state => state.user.permission,
  menuId: state => state.user.menuId,
  menuPathList: state => state.user.menuPathList,
  menuIconList: state => state.user.menuIconList,
  menu: state => state.user.menu,
  menuAll: state => state.user.menuAll,
  noVerifyPageRouterList: state => state.common.noVerifyPageRouterList,
  keepAliveExclude: state => state.common.keepAliveExclude,
  freshDispatch: state => state.common.freshDispatch,
  payOrReceive: state => state.common.payOrReceive,
  receiptList: state => state.receipt.receiptList,
  sendList: state => state.receipt.sendList,
  takeList: state => state.receipt.takeList,
  giveOutList: state => state.receipt.giveOutList,
  pdfUrl: state => state.common.pdfUrl,
  imgViewerList: state => state.common.imgViewerList,
  globalReplaceTextList: state => state.common.globalReplaceTextList
}
export default getters
