import http from '@/router/axios'
import { clipApiPrefix } from '@/config/env'

const paymentRegistrationApi = {}

/**
 * @Description: 收支登记列表查询
 */
paymentRegistrationApi.queryPage = (object) => {
  return http.$POST(`${clipApiPrefix}/income/queryPage`,
    {
      showLoading: false,
      params: object
    })
}

/**
 * @Description: 收支登记撤回
 */
paymentRegistrationApi.incomeAuditRevocation = (object) => {
  return http.$POST(`${clipApiPrefix}/income/incomeAuditRevocation`,
    {
      showLoading: false,
      isParams: true,
      params: object
    })
}

/**
 * @Description: 收支登记列表添加
 */
paymentRegistrationApi.add = (object) => {
  return http.$POST(`${clipApiPrefix}/income/add`,
    {
      showLoading: true,
      params: object
    })
}

/**
 * @Description: 收支登记列表审核
 */
paymentRegistrationApi.incomeAuditState = (object) => {
  return http.$POST(`${clipApiPrefix}/income/incomeAuditState`,
    {
      isParams: true,
      params: object
    })
}

/**
 * @Description: 收支登记列表修改
 */
paymentRegistrationApi.modifye = (object) => {
  return http.$POST(`${clipApiPrefix}/income/modify`,
    {
      showLoading: true,
      params: object
    })
}

/**
 * @Description: 收支登记列表删除
 */
paymentRegistrationApi.remove = (object) => {
  return http.$GET(`${clipApiPrefix}/income/remove`,
    {
      showLoading: true,
      params: object
    })
}

/**
 * @Description: 收支登记记账机构下拉列表查询
 */
paymentRegistrationApi.listSubOrganization = (object) => {
  return http.$POST(`${clipApiPrefix}/organizationCommon/listSubOrganization`,
    {
      showLoading: false,
      params: object
    })
}

/**
 * @Description: 获取所在集团所有节点
 */
paymentRegistrationApi.listTopSubOrganization = (object) => {
  return http.$POST(`${clipApiPrefix}/organizationCommon/listTopSubOrganization`,
    {
      showLoading: false,
      params: object
    })
}

/**
 * @Description: 科目类别列表查询
 */
paymentRegistrationApi.subjectQueryPage = (object) => {
  return http.$POST(`${clipApiPrefix}/incomeSujectCategory/queryPage`,
    {
      showLoading: false,
      params: object
    })
}

/**
 * @Description: 科目类别列表查询(修改和详情)
 */
paymentRegistrationApi.subjectQueryPage2 = (object) => {
  return http.$GET(`${clipApiPrefix}/income/selectByAllDetailsList`,
    {
      isParams: true,
      showLoading: true,
      params: object
    })
}
/**
 * @Description: 科目类别列表新增
 */
paymentRegistrationApi.subjectAdd = (object) => {
  return http.$POST(`${clipApiPrefix}/incomeSujectCategory/add`,
    {
      showLoading: true,
      params: object
    })
}

/**
 * @Description: 科目类别列表修改
 */
paymentRegistrationApi.subjectModify = (object) => {
  return http.$POST(`${clipApiPrefix}/incomeSujectCategory/modify`,
    {
      showLoading: true,
      params: object
    })
}

/**
 * @Description: 科目类别列表删除
 */
paymentRegistrationApi.subjectRemove = (object) => {
  return http.$GET(`${clipApiPrefix}/incomeSujectCategory/remove`,
    {
      showLoading: true,
      params: object
    })
}

/**
 * @Description: 收支账户列表查询（详情和修改）
 */
paymentRegistrationApi.query = (object) => {
  return http.$GET(`${clipApiPrefix}/incomeAccount/query`,
    {
      isParams: true,
      showLoading: true,
      params: object
    })
}

/**
 * @Description: 收支账户列表查询
 */
paymentRegistrationApi.accountQueryPage = (object) => {
  return http.$POST(`${clipApiPrefix}/incomeAccount/queryPage`,
    {
      showLoading: false,
      params: object
    })
}

/**
 * @Description: 收支账户列表添加
 */
paymentRegistrationApi.accountAdd = (object) => {
  return http.$POST(`${clipApiPrefix}/incomeAccount/add`,
    {
      showLoading: true,
      params: object
    })
}

/**
 * @Description: 收支账户列表修改
 */
paymentRegistrationApi.accountModify = (object) => {
  return http.$POST(`${clipApiPrefix}/incomeAccount/modify`,
    {
      showLoading: true,
      params: object
    })
}

/**
 * @Description: 收支账户列表删除
 */
paymentRegistrationApi.accountRemove = (object) => {
  return http.$GET(`${clipApiPrefix}/incomeAccount/remove`,
    {
      showLoading: true,
      params: object
    })
}

/**
 * @Description: 收支登记明细列表查询
 */
paymentRegistrationApi.incomeDetailsQueryPage = (object) => {
  return http.$POST(`${clipApiPrefix}/income/incomeDetailsQueryPage`,
    {
      showLoading: false,
      params: object
    })
}

/**
 * @Description: 收支登记明细列表导出
 */
paymentRegistrationApi.exportIncomeDetails = (object) => {
  return http.$POST(`${clipApiPrefix}/income/exportIncomeDetails`,
    {
      meta: {
        responseType: 'blob',
        filterResponse: false
      },
      showLoading: true,
      params: object
    })
}

/**
 * @Description: 分页查询收支明细总数
 */
paymentRegistrationApi.incomeDetailsQueryPageSum = (object) => {
  return http.$POST(`${clipApiPrefix}/income/incomeDetailsQueryPageSum`,
    {
      showLoading: false,
      params: object
    })
}

/**
 * @Description: 分页查询收支登记表总数
 */
paymentRegistrationApi.queryPageSum = (object) => {
  return http.$POST(`${clipApiPrefix}/income/queryPageSum`,
    {
      showLoading: false,
      params: object
    })
}

/**
 * @Description: 代签收数据导出
 */
 paymentRegistrationApi.exportStockLessIndent = (object) => {
  return http.$POST(`${clipApiPrefix}/LessDeliver/exportStockLessIndent`,
    {
      showLoading: false,
      params: object
    })
}

/**
 * @Description: 已签收数据导出
 */
 paymentRegistrationApi.exportLessReceipt = (object) => {
  return http.$POST(`${clipApiPrefix}/lessReceipt/exportLessReceipt`,
    {
      showLoading: false,
      params: object
    })
}
export default paymentRegistrationApi
