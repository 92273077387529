var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "my-select",
    {
      attrs: {
        disabled: _vm.disabled,
        filterable: _vm.filterable,
        clearable: "",
        placeholder: _vm.placeholder
      },
      on: { change: _vm.handleChange },
      model: {
        value: _vm.value,
        callback: function($$v) {
          _vm.value = $$v
        },
        expression: "value"
      }
    },
    _vm._l(_vm.data, function(item) {
      return _c("my-option", {
        key: item.value,
        attrs: { label: item.label, value: item.value }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }