import http from '@/router/axios'
import { clipApiPrefix } from '@/config/env'

const informationApi = {}

/**
 * @Description: 查询企业详情
 */
informationApi.detailCompany = (object) => {
  return http.$GET(`${clipApiPrefix}/enterprise/detail`, {
    params: object
  })
}

/**
 * @Description: 企业认证
 */
informationApi.enterpriseAuth = (object) => {
  return http.$POST(`${clipApiPrefix}/enterprise/enterpriseAuth`, {
    params: object
  })
}

/**
 * @Description: 查询当前企业下的节点
 */
informationApi.listSubMechanism = (object) => {
  return http.$POST(`${clipApiPrefix}/enterprise/listSubMechanism`, {
    params: object
  })
}

/**
 * @Description: 查询企业详情
 */
informationApi.queryEnterpriseVo = (object) => {
  return http.$GET(`${clipApiPrefix}/enterprise/queryEnterpriseVo`, {
    showLoading: true,
    params: object
  })
}

/**
 * @Description: 获取企业的标签列表
 */
informationApi.getEnterpriseTagList = (object) => {
  return http.$POST(`${clipApiPrefix}/enterprise/getEnterpriseTagList`, {
    showLoading: true,
    params: object
  })
}

/**
 * @Description: 编辑企业详情
 */
informationApi.editEnterprise = (object) => {
  return http.$POST(`${clipApiPrefix}/enterprise/editChildrenEnterprise`, {
    showLoading: true,
    params: object
  })
}

/**
 * @Description: 查询企业下的子公司
 */
informationApi.enterpriseTree = (object) => {
  return http.$POST(`${clipApiPrefix}/organization/enterpriseTree`, {
    showLoading: true,
    params: object
  })
}

/**
 * @Description: 新增子公司
 */
informationApi.addChildrenEnterprise = (object) => {
  return http.$POST(`${clipApiPrefix}/enterprise/addChildrenEnterprise`, {
    showLoading: true,
    params: object
  })
}

/**
 * @Description: 删除子公司
 */
informationApi.delChildrenEnterprise = (object) => {
  return http.$POST(`${clipApiPrefix}/enterprise/delChildrenEnterprise`, {
    showLoading: true,
    isParams: true,
    params: object
  })
}

/**
 * @Description: 根据机构id获取当前机构的扩展信息
 */
informationApi.fetchOrganizationExtendByOrgId = (object) => {
  return http.$POST(`${clipApiPrefix}/organization/fetchOrganizationExtendByOrgId`, {
    showLoading: true,
    params: object
  })
}

/**
 * @Description: 根据当前集团id获取当前机构的扩展信息
 */
 informationApi.fetchOrganizationExtendByTopOrgId = (object) => {
  return http.$POST(`${clipApiPrefix}/organization/fetchOrganizationExtendByTopOrgId`, {
    showLoading: true,
    params: object
  })
}

/**
 * @Description: 修改定制信息
 */
informationApi.updateOrganizationExtend = (object) => {
  return http.$POST(`${clipApiPrefix}/organization/updateOrganizationExtend`, {
    showLoading: true,
    params: object
  })
}

/**
 * @Description: e签宝企业认证
 */
informationApi.eqbEnterpriseAuth = (object) => {
  return http.$POST(`${clipApiPrefix}/contractRegisterRecord/eqbEnterpriseAuth`, {
    showLoading: true,
    params: object
  })
}


/**
 * @Description: e签宝企业章授权
 */
informationApi.eqbEnterpriseSealAuth = (object) => {
  return http.$POST(`${clipApiPrefix}/contractRegisterRecord/eqbEnterpriseSealAuth`, {
    showLoading: true,
    params: object
  })
}


export default informationApi