export default {
  Column: {
    indexLabel: '序号',
    noData: '没有查询到任何数据'
  },
  // 系统，用于框架级的文字定义
  System: {
    title: '争渡物流供应链服务平台',
    noData: '暂无数据',
    copyright: 'Copyright © 2022 - All Right Reserved长沙争渡网络科技有限公司提供技术支持'
  },
  // 消息提示，用于主动操作后的反馈提示
  Message: {
    noData: '找不到任何数据！'
  },
  // 通知，用于系统级通知的被动提醒
  Notification: {
    title: '系统提示',
    successful: {
      title: '成功提示',
      update: '修改成功',
      delete: '删除成功',
      create: '创建成功',
      content: '操作成功',
      save: '保存成功',
      submit: '提交成功',
      push: '推送成功'
    },
    failure: {
      title: '失败提示',
      content: '操作失败，请稍候再试！'
    }
  },
  // 弹框，用于消息提示、确认消息和提交内容
  MessageBox: {
    title: '操作提示',
    delConfirm: '此操作将删除该数据, 是否继续？',
    disableConfirm: '此操作将禁用该数据, 是否继续？',
    enableConfirm: '此操作将启用该数据, 是否继续？',
    unIntroduceConfirm: '此操作将解除引入关系, 是否继续？'
  },
  // 按钮，用于页面的所有按钮名称
  Button: {
    saveAddressConfig: '保存至收发地配置',
    downLoadEleReceipt: '下载电子回单',
    changepassword: '修改密码',
    systemreset: '重置密码',
    operat: '操作',
    exit: '退出',
    back: '返回',
    cancel: '取消',
    confirm: '确认',
    success: '通过',
    reset: '重置',
    refresh: '刷新',
    add: '新增',
    newadd: '新增申请',
    delete: '删除',
    edit: '编辑',
    view: '查看',
    update: '修改',
    save: '保存',
    more: '更多',
    done: '完成',
    enable: '启用',
    disable: '禁用',
    detail: '详情',
    clear: '清空',
    export: '导出',
    import: '导入',
    expand: '展开',
    collapse: '收起',
    adjunction: '添加',
    inquire: '查询',
    search: '搜索',
    flow: '交易流水',
    next: '下一步',
    previous: '上一步',
    sign: '签收',
    audit: '审核',
    submit: '提交',
    copy: '复制',
    refuse: '拒绝',
    reject: '驳回',
    verify: '校验',
    reply: '回复',
    adSearch: '高级搜索',
    print: '打印',
    publish: '发布',
    undo: '撤销',
    push: '推送',
    preview: '预览',
    select: '选择',
    download: '下载',
    payment: '付款',
    auditNotPassed: '审核不通过',
    auditPassed: '审核通过',
    apply: '申请',
    powerAuth: '权限配置',
    resetPwd: '重置密码',
    updateUser: '更新用户',
    updateRole: '更新角色',
    importIndent: '订单导入',
    exportIndent: '订单导出',
    conversionIndent: '订单校验',
    importVehicle: '载具导入',
    conversionVehicle: '载具校验',
    importAddress: '地址导入',
    conversionAddress: '地址校验',
    selectFile: '选择上传文件',
    downloadTemp: '模板下载',
    pushLfp: '推送货运监测平台',
    offer: '报价',
    batchPrint: '标签打印',
    close: '关闭',
    saveAsCommonAddress: '保存至常用地址',
    introduce: '引入',
    unIntroduce: '解除引入',
    upload: '上传',
    exportDispatchOrde: '调度单导出',
    qeacquire: '重新获取'
  },
  // 登陆，用于登陆页面
  Login: {
    title: '争渡物流供应链服务平台',
    login: '登录',
    logging: '登录中',
    error: {
      username: '请输入登录账号',
      password: '请输入登录密码',
      code: '请输入验证码'
    },
    userLogin: {
      title: '账号密码',
      username: '登录账号/手机号/邮箱',
      password: '登录密码',
      passwordLen: '密码长度最少为6位',
      code: '请输入验证码',
      codeLen: '验证码长度必须是4位',
      codeError: '请输入正确的验证码',
      refreshCode: '刷新验证码',
      keepPwd: '记住密码'
    }
  },
  // 退出系统，用于退出登陆
  LoginOut: {
    text: '退出系统，是否继续？'
  },
  // 错误异常，用于Axios错误提示
  Error: {
    timeout: '网络连接失败，请稍后再试！',
    unknown: '未知错误',
    loginInvalid: '登录已失效，请重新登录！',
    500: '服务器内部错误',
    404: '请求地址不存在',
    405: '该请求拒绝访问'
  },
  // 枚举，用于系统枚举
  Enums: {
    chooseAll: '全部'
  },
  // 提示文字，用于文字系统文字提示
  Prompt: {
    mobileOrPhoneNull: '手机号码或电话必填一个',
    upload: '请上传',
    input: '请输入',
    nameOrMobile: '姓名/手机号',
    select: '请选择',
    split: '以$key分割',
    noData: '没有找到相关的数据~',
    inputSelect: '请输入选择',
    searchInput: '请输入关键词搜索',
    generateBySystem: '系统自动生成',
    notSelection: '请选择要操作的数据',
    selectionOne: '有且只能选择一条数据',
    separator: '至',
    datePicker: {
      separator: '/',
      startPlaceholder: '开始日期',
      endPlaceholder: '结束日期'
    }
  },
  //
  NavBar: {
    menuTip: '没有发现菜单',
    reload: '重新加载',
    dashboard: '首页',
    signOut: '退出登录',
    resetPwd: '重置密码',
    bug: '没有错误日志',
    bugs: '条错误日志',
    screenfullF: '退出全屏',
    screenfull: '全屏',
    language: '中英文',
    notice: '消息通知',
    theme: '主题',
    color: '换色',
    helpCenter: '点击进入帮助文档中心'
  },
  //
  TagsView: {
    search: '搜索',
    menu: '更多',
    closeOthers: '关闭其它页面',
    closeAll: '关闭所有页面'
  },
  // 路由标题，用于路由名称
  Route: {
    dashboard: '首页'
  },
  File: {
    exceed2M: '文件大小不能超过2M',
    exceed: '文件大小不能超过$sizeM',
    fileExts: '只能上传$exts格式',
    imgSuffix: '只能上传jpg/png/jpeg格式',
    imgTips: '不超过$sizeM的$exts图片'
  },
  ThreeInvoicing: {
    button: {
      apply: '申请开票',
      modify: '批量修改开票状态',
      downloadFile: '下载业务附件'
    },
    search: {
      invoiceApplyNo: '申请编号',
      chauffeurName: '司机名称',
      chauffeurMobile: '司机手机',
      payerName: '托运人',
      enterpriseName: '申请企业',
      paidEndTime: '付款时间',
      taxInvoiceState: '开票状态',
      hasOnlineData: '数据来源',
      capitalFlowNo: '资金单号'
    },
    column: {
      invoiceApplyNo: '申请编号',
      enterpriseName: '申请企业',
      payeeName: '司机姓名',
      payeeMobile: '司机手机号',
      payeeIdNo: '司机身份证',
      invoiceNo: '发票号',
      invoiceImageUrl: '发票附件',
      capitalFlowNo: '资金流水（付款单号）',
      capitalFlowFileUrl: '是否有资金流水单',
      amount: '结算金额（元）',
      paidEndTime: '资金流水单生成时间',
      taxInvoiceLastUpdateTime: '最后开票状态更新时间',
      invoiceMemo: '备注',
      shipperName: '托运人',
      payDetail: '付款详情',
      dispatchDetail: '调度详情',
      waybillDetail: '运单详情',
      hasOnlineData: '数据来源',
      applyTime: '申请时间',
      taxAmount: '代收税金',
      taxInvoiceState: '开票状态',
      taxInvoiceErrorReason: '不符合原因',
      payerName: '合同托运人',
      remark: '备注',
      waybill: '运单列表',
      verifyState: '验证状态',
      verifyFailReason: '验证失败原因'
    },

    waybill: {
      waybillNo: '运单号',
      contractNo: '合同编号',
      consignerName: '发货人',
      consignerTelephone: '发货人联系方式',
      consignerAddress: '发货地址',
      consigneeName: '收货人',
      consigneeTelephone: '收货人联系方式',
      consigneeAddress: '收货地址',
      plateNo: '车辆牌照号',
      vehicleLicenseNo: '车辆行驶证编号',
      plateColor: '车牌颜色',
      pickupTime: '揽件时间',
      consigneeSignTime: '签收时间',
      cargoName: '货物名称',
      cargoTotalWeight: '货物总重量',
      cargos: '货物列表'
    },

    cargo: {
      cargoName: '货物名称',
      cargoType: '货物类型',
      cargoWeight: '货物重量'
    }
  },
  // 电子回单
  EleReceipt: {
    column: {
      receiptNo: '回单编号',
      orderNo: '网商订单号',
      createTime: '创建时间',
      createName: '申请人',
      startDate: '开始日期',
      endDate: '结束日期',
      electronicreceiptState: '状态'
    }
  }
}
