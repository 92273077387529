var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "border-box-11" }, [
    _c(
      "div",
      {
        staticClass: "border-box-11__title",
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.toggleUp.apply(null, arguments)
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "title-color" },
          [
            _c("i", {
              staticClass: "toggle-icon mr10",
              class: _vm.isUp ? "el-icon-arrow-down" : "el-icon-arrow-up"
            }),
            _vm._v(_vm._s(_vm.title) + "\n      "),
            (_vm.toggleWithLeftMenu
            ? _vm.isUp
            : true)
              ? _vm._t("headerLeftMenu")
              : _vm._e()
          ],
          2
        ),
        _vm.$slots.headerMenu
          ? _c("section", [_vm._t("headerMenu")], 2)
          : _vm._e()
      ]
    ),
    _vm.isNeedLine
      ? _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isUp,
              expression: "!isUp"
            }
          ],
          staticClass: "line"
        })
      : _vm._e(),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isUp,
            expression: "!isUp"
          }
        ],
        staticClass: "border-box-11__context"
      },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }