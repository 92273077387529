var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "avue-contail",
      class: { "avue--collapse": _vm.isCollapse }
    },
    [
      _c(
        "div",
        { staticClass: "avue-header" },
        [_c("top", { ref: "top" }), _c("upgrade-browser")],
        1
      ),
      _c("div", { staticClass: "avue-layout" }, [
        _c(
          "div",
          { staticClass: "avue-left" },
          [_c("sidebar", { on: { tagsMore: _vm.tagsMore } })],
          1
        ),
        _c(
          "div",
          {
            staticClass: "avue-main",
            class: { "avue-main--fullscreen": !_vm.isMenu }
          },
          [
            _c("tags", { attrs: { isTagsMore: _vm.isTagsMore } }),
            _c(
              "div",
              {
                staticClass: "avue-view-container",
                attrs: { id: "avue-view" }
              },
              [
                _c(
                  "keep-alive",
                  { attrs: { exclude: _vm.keepAliveExclude } },
                  [
                    _vm.$route.meta.$keepAlive
                      ? _c("router-view", {
                          key: _vm.$route.fullPath,
                          staticClass: "avue-view"
                        })
                      : _vm._e()
                  ],
                  1
                ),
                !_vm.$route.meta.$keepAlive
                  ? _c("router-view", {
                      key: _vm.$route.fullPath,
                      staticClass: "avue-view"
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "avue-shade", on: { click: _vm.showCollapse } }),
      _c("my-pdf", {
        attrs: { pdfUrl: _vm.pdfUrl, isShowPdf: _vm.isShowPdf },
        on: { close: _vm.closePdf }
      }),
      _vm.isShowImgViewer
        ? _c("img-viewer", {
            attrs: { imgList: _vm.imgViewerList, "on-close": _vm.closeViewer }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }