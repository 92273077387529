<template>
  <el-autocomplete
    ref="autocompleteRef"
    :valueKey="valueKey"
    :popperClass="popperClass"
    :popperOptions="popperOptions"
    :placeholder="placeholderNew"
    :size="size"
    :maxlength="maxlength"
    :minlength="minlength"
    :fetchSuggestions="fetchSuggestions"
    :triggerOnFocus="triggerOnFocus"
    :customItem="customItem"
    :selectWhenUnmatched="selectWhenUnmatched"
    :debounce="debounce"
    :placement="placement"
    :hideLoading="hideLoading"
    :resize="resize"
    :popperAppendToBody="popperAppendToBody"
    :highlightFirstItem="highlightFirstItem"
    v-model="setValue"
    :form="form"
    :disabled="disabled"
    :readonly="readonly"
    :type="type"
    :autosize="autosize"
    :autofocus="autofocus"
    :max="max"
    :min="min"
    :autocomplete="autocomplete"
    :validateEvent="validateEvent"
    :suffixIcon="suffixIcon"
    :prefixIcon="prefixIcon"
    :label="label"
    :clearable="clearable"
    :showPassword="showPassword"
    :showWordLimit="showWordLimit"
    :tabindex="tabindex"
    @select="handleSelect"
    @focus="handleFocus"
    @change="handleChange"
    @inpput="handleInput"
    @clear="handleClear"
  >
    <template #default="{ item }">
      <slot :item="item"></slot>
    </template>
    <template v-for="slotName in slotNames" :slot="slotName">
      <slot :name="slotName"></slot>
      <!-- 渲染原本的插槽内容 -->
    </template>
  </el-autocomplete>
</template>

<script>
import { mapGetters } from 'vuex'
import Focus from 'element-ui/src/mixins/focus';
export default {
  name: 'my-autocomplete',
  mixins: [Focus('input')],
  props: {
    valueKey: {
      type: String,
      default: 'value'
    },
    popperClass: String,
    popperOptions: Object,
    placeholder: String,
    clearable: {
      type: Boolean,
      default: false
    },
    disabled: Boolean,
    name: String,
    size: String,
    value: String,
    maxlength: Number,
    minlength: Number,
    autofocus: Boolean,
    fetchSuggestions: Function,
    triggerOnFocus: {
      type: Boolean,
      default: true
    },
    customItem: String,
    selectWhenUnmatched: {
      type: Boolean,
      default: false
    },
    prefixIcon: String,
    suffixIcon: String,
    label: String,
    debounce: {
      type: Number,
      default: 300
    },
    placement: {
      type: String,
      default: 'bottom-start'
    },
    hideLoading: Boolean,
    popperAppendToBody: {
      type: Boolean,
      default: true
    },
    highlightFirstItem: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    setValue(val) {
      if(!this.validatenull(val)) {
        this.$emit('input', val)
      }
    },
    value(val) {
      this.setValue = val
    }
  },
  data() {
    return {
      setValue: '',
      // activated: false,
      // suggestions: [],
      // loading: false,
      // highlightedIndex: -1,
      // suggestionDisabled: false
    }
  },
  computed: {
    ...mapGetters(['globalReplaceTextList']),
    placeholderNew() {
      let placeholderNew = this.placeholder
      if(placeholderNew) {
        this.globalReplaceTextList.forEach((element) => {
          if (placeholderNew.indexOf(element.name) > -1) {
            placeholderNew = placeholderNew.replaceAll(
              element.name,
              element.value
            )
          }
        })
      }
      return placeholderNew
    },
    slotNames() {
      // 这里列出了 el-select 原本的插槽名称
      return ['prefix', 'suffix', 'append', 'prePend']
    }
  },
  methods: {
    focus() {
      this.$refs['autocompleteRef'].focus()
    },
    handleInput(value) {
      this.$emit('input', value)
    },
    handleSelect(value) {
      this.$emit('select', value)
    },
    handleFocus(event) {
      this.$emit('focus', event)
    },
    handleChange(value) {
      this.$nextTick(() => {
        this.$emit('change', value)
      })
    },
    handleClear() {
      this.$emit('input', '')
      this.$emit('clear')
    }
  }
}
</script>
