/**
 * 全站http配置
 *
 * axios参数说明
 * isToken是否需要token
 */
import { vaildData, validatenull } from '@util/validate'
import { diffDate } from '@util/date'

import debounce from 'lodash/debounce'
import Vue from 'vue'
import axios from 'axios'
import store from '@/store/'
import i18n from '@/lang'
import router, { resetRouter } from '@/router/router'
import { getToken } from '@/util/auth'
import { Message, Loading, MessageBox } from 'element-ui'
import website from '@/config/website'
import vPackage from '../../package'

axios.defaults = Object.assign(axios.defaults, {
  timeout: 20000,
  withCredentials: false,
  validateStatus: (status) => {
    return status >= 200 && status <= 500 // 默认的
  }
})
const CancelToken = axios.CancelToken
let showMessageBox = false

// http request
axios.interceptors.request.use(
  (config) => {
    const { meta = {} } = config
    const isToken = vaildData(meta.isToken, true)
    const responseType = meta.responseType || ''

    if (responseType) config['responseType'] = responseType
    config.headers['deviceId'] = 'PC'
    config.headers['deviceType'] = 'PC'
    config.headers['osVersion'] = navigator.appVersion
    config.headers['osType'] = 'WEB'
    config.headers['loginChannel'] = 'BUSINESS_WEB'
    config.headers['loginPlatform'] = 'LOGISTICS'
    config.headers['appVersion'] = vPackage.version
    config.headers['orgId'] = `${
      validatenull(store.getters.platformConfig.orgId)
        ? ''
        : store.getters.platformConfig.orgId
    }`
    if (isToken) {
      config.headers['token'] = `${getToken()}`
      config.headers['uid'] = `${store.getters.userInfo.uid || ''}`
      config.headers['orgId'] = `${store.getters.userInfo.orgId || ''}`
    }

    config.cancelToken = new CancelToken((cancel) => {
      store.commit('SET_AXIOSCANCEL', cancel)
    })

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// http response
axios.interceptors.response.use(
  (response) => {
    if (axios.isCancel(response)) {
      return new Promise(() => {})
    }

    // const {meta = {}} = response.config
    // const catchError = vaildData(meta.catchError, true)
    // const filterResponse = vaildData(meta.filterResponse, true)

    const { status = 200, data: retData } = response
    let message =
      retData.message || retData.error_msg || `${i18n.t('Error.unknown')}`
    // 如果请求为非200否者默认统一处理
    if (status !== 200) {
      switch (status) {
        case 401: {
          Message({
            message: `${retData.message}`,
            type: 'error',
            duration: website.duration
          })

          return store
            .dispatch('FedLogOut')
            .then(() => {
              resetRouter()
              router.push({ path: '/login' }).catch(() => {})
            })
            .catch(() => {})
        }
        case 404: {
          message = `${i18n.t('Error.404')}`
          break
        }
        case 405: {
          message = `${i18n.t('Error.405')}`
          break
        }
        case 500: {
          message = `${i18n.t('Error.500')}`
          break
        }
        default: {
          if (retData.msg) {
            message = retData.message
          }
        }
      }
      Message({
        message: message,
        type: 'error',
        duration: website.duration
      })

      return Promise.reject(new Error(message))
    } else {
      if (
        retData.code === 200 ||
        retData.access_token ||
        retData.type === 'application/vnd.ms-excel' || // 兼容下载excel
        retData.type === 'application/octet-stream' ||
        retData.type === 'application/json' ||
        retData.type === 'application/pdf' ||
        retData.words_result
      ) {
        // 支持百度OCR API鉴权接口
        return retData
      } else if (retData.code === 401) {
        const doms = document.getElementsByClassName('el-message')[0]
        if (doms === undefined) {
          Message({
            message: retData.message,
            type: 'error',
            duration: website.duration
          })
        }
        store
          .dispatch('FedLogOut')
          .then(() => {
            resetRouter()
            router.push({ path: '/login' }).catch(() => {})
            closeLoadingInstance()
          })
          .catch(() => {})
          .finally(() => {
            return new Promise(() => {})
          })
      } else if (retData.code === 666) {
        if (!showMessageBox) {
          store.dispatch('LoadSetEnterpriseUnvali')
          showMessageBox = true
          MessageBox.confirm('企业未认证通过，请完善或修改企业资料', '提示', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              router
                .push({ path: '/company/enterpriseVali' })
                .catch(() => {})
            })
            .catch(() => {
              router
                .push({ path: '/company/enterpriseVali' })
                .catch(() => {})
            })
            .finally(() => {
              setTimeout(() => {
                showMessageBox = false
              }, 100)
              return new Promise(() => {})
            })
        }
      } else if (retData.code === 406) {
        Message({
          message: retData.message,
          type: 'error',
          duration: website.duration
        })
        store
          .dispatch('FedLogOut')
          .then(() => {
            resetRouter()
            router.push({ path: '/login' }).catch(() => {})
            closeLoadingInstance()
          })
          .catch(() => {})
          .finally(() => {
            return new Promise(() => {})
          })
      } else if (retData.code === 506) {
        Message({
          message: retData.message,
          type: 'error',
          duration: website.duration
        })
      } else if (retData.error_code) {
        Message({
          message: message,
          type: 'error',
          duration: website.duration
        })
      } else {
        Message({
          message: retData.message,
          type: 'error',
          duration: website.duration
        })
      }
      return Promise.reject(retData)
    }
  },
  (error) => {
    if (axios.isCancel(error)) {
      return new Promise(() => {})
    }

    // 开发环境打印响应结果
    if (process.env.NODE_ENV === 'development') {
      console.group('>>>>>> Response onRejected >>>>>>')
      console.log(error)
      console.groupEnd()
    }

    Message({
      message: `${i18n.t('Error.timeout')}(请求时间：${diffDate(
        -20,
        'yyyy-MM-dd hh:mm:ss',
        'seconds'
      )})`,
      type: 'error',
      duration: website.duration
    })

    return Promise.reject(new Error(error))
  }
)

const http = {
  axios
}

const httpHandler = (url, method, options) => {
  const {
    headers = {},
    params = {},
    showLoading = false,
    timeout = 0,
    meta = {},
    isParams = false
  } = options

  const requestParams = Object.assign({}, params)
  showLoadingInstance(showLoading)

  const config = {
    url,
    headers,
    method,
    meta
  }

  if (isParams) config['params'] = requestParams
  else config['data'] = requestParams

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      axios(config)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
        .finally(() => {
          closeLoadingInstance()
        })
    }, timeout)
  })
}

/**
 * @author cc@zdu56.com
 * @date 2019/07/25 09:29:14
 * @Description: GET 请求
 * @Param: URL：请求地址
 * @Param: OPTIONS：参数，参数说明如下：
 * {
 *  headers：headers参数，
 *  params：请求参数，
 *  showLoading：是否显示loading，
 *  timeout：延迟请求的时间，默认立即执行，
 *  isParams：传参方式，比如POST请求，但却要以URL方式传值，
 *  meta：额外配置项
 *  }
 */
http.$GET = (url, options) => {
  return new Promise((resolve, reject) => {
    options = Object.assign(
      {
        isParams: true
      },
      options
    )

    httpHandler(url, 'get', options)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * @author cc@zdu56.com
 * @date 2019/07/25 09:34:33
 * @Description:  PATCH 请求
 * @Param:
 */
http.$PATCH = (url, options) => {
  return new Promise((resolve, reject) => {
    options = Object.assign(
      {
        isParams: false
      },
      options
    )

    httpHandler(url, 'patch', options)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * @author cc@zdu56.com
 * @date 2019/07/25 10:37:35
 * @Description: POST 请求
 * @Param:
 */
http.$POST = (url, options) => {
  return new Promise((resolve, reject) => {
    options = Object.assign(
      {
        isParams: false
      },
      options
    )

    httpHandler(url, 'post', options)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * @author cc@zdu56.com
 * @date 2019/07/25 10:37:18
 * @Description: PUT 请求
 * @Param:
 */
http.$PUT = (url, options) => {
  return new Promise((resolve, reject) => {
    options = Object.assign(
      {
        isParams: false
      },
      options
    )

    httpHandler(url, 'put', options)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * @author cc@zdu56.com
 * @date 2019/07/25 10:37:06
 * @Description: DELETE 请求
 * @Param:
 */
http.$REMOVE = (url, options) => {
  return new Promise((resolve, reject) => {
    options = Object.assign(
      {
        isParams: false
      },
      options
    )

    httpHandler(url, 'delete', options)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const loadingConfig = {
  fullscreen: true,
  lock: true,
  text: `请稍候`,
  background: 'rgba(0, 0, 0, 0.2)'
}

let loadingInstance // loading 实例
let needLoadingRequestCount = 0 // 当前正在请求的数量

// 显示Loading
function showLoadingInstance(flag) {
  if (!flag) return false

  if (needLoadingRequestCount === 0 && !loadingInstance) {
    loadingInstance = Loading.service(Object.assign({}, loadingConfig))
  }
  needLoadingRequestCount++
}

const hideLoading = debounce(() => {
  loadingInstance.close()
  loadingInstance = null
}, 100)

// 关闭Loading
function closeLoadingInstance(loading) {
  Vue.nextTick(() => {
    // 以服务的方式调用的 Loading 需要异步关闭
    needLoadingRequestCount--
    needLoadingRequestCount = Math.max(needLoadingRequestCount, 0) // 保证大于等于0

    if (needLoadingRequestCount === 0 && loadingInstance) hideLoading()
  })
}

export default http
