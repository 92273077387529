<template>
  <my-select v-model="value"
             @change="handleChange"
             :disabled="disabled"
             :filterable="filterable"
             clearable
             :placeholder="placeholder">
    <my-option v-for="item in data"
               :key="item.value"
               :label="item.label"
               :value="item.value">
    </my-option>
  </my-select>
</template>

<script>
import { updateMixin } from "@/mixins/global"
export default {
  mixins: [updateMixin],
    name: 'index',
    model: {
      prop: 'value', // props接受的变量名称
      event: 'change' // 定义一个方法
    },
    props: {
      value: {
        type: String / Number / Boolean / Array
      },
      placeholder: {
        type: String,
        default: '请选择'
      },
      data: {
        type: Array,
        required: true,
        default: () => {
          return []
        }
      },
      disabled: {
        type: Boolean,
        default: false
      },
      filterable: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      handleChange(evt) {
        this.$emit('change', evt)
      }
    }
  }
</script>

<style scoped>

</style>