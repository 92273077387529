import { setStore, getStore } from '@/util/store'
import store from './../../store'
import { validatenull } from '@util/validate'
import website from '@/config/website'
import commonApi from '@/api/commonApi'
import dictionaryApi from '@/api/system/dictionary'
import notVerifiedApi from '@api/notVerifiedApi'
import informationApi from '@/api/company/informationApi'
import messageApi from '../../api/message/messageApi'
import userApi from '../../api/system/userApi'
import ssoApi from '@api/ssoApi'
import subjectApi from '@api/subject/subjectApi'
import partnerApi from '@/api/partner/partnerApi'
import employeeApi from '@/api/company/employeeApi'
import platformDriverApi from '@api/platform/platformDriver'
import paymentRegistrationApi from '@/api/paymentRegistration/paymentRegistrationApi'
import configSettingApi from "@/api/configSetting/configSettingApi"

const common = {
  state: {
    globalReplaceTextList: getStore({ name: 'GLOBALREPLACETEXTLISTKEY' }) || [],
    vehicleLocationAndTrajectoryPlateNo: '',
    voucherPrintDataList: getStore({ name: 'VOUCHERPRINTKEY' }) || [],
    subjectList: [], // 科目列表
    auxiliaryObj: {
      // 辅助核算项
      startNodeList: [],
      endNodeList: [],
      carrierPartnerList: [],
      shipperPartnerList: [],
      driverList: [],
      employeeList: []
    },
    fontConfig: getStore({ name: 'FONTCONFIGKEY' }),
    recentlyVisitedList: getStore({ name: 'RECENTLYVISITEDKEY' }) || [],
    quickMenuList: getStore({ name: 'QUICKMENUKEY' }) || [],
    heatmapVehicleNo: getStore({ name: 'heatmapVehicleNo' }) || '',
    heatmapProvince: getStore({ name: 'heatmapProvince' }) || '',
    freshDispatch: 0, // 用于调度列表刷新
    payOrReceive: 'pay',
    // isUpgrade: getStore({ name: 'isUpgrade' }) || '', // 版本升级，这个目前没用上，后端来控制升级
    language: getStore({ name: 'language' }) || 'en', // 多语言
    isCollapse: getStore({ name: 'isCollapse' }) || false, // 展开与收缩
    isFullScren: false, // 全屏
    isMenu: true,
    screen: -1,
    messageDetail: {
      type: 'notice',
      id: null
    }, // 消息id
    showTag: true, // 展示tag栏
    showCollapse: true, // 展示伸缩
    showLock: false, // 展示锁定
    showFullScren: false, // 展示全屏
    showTheme: true, // 展示主题
    showMenu: false, // 是否显示菜单
    showLanguage: false, // 展示语言切换按钮
    showNotice: false, // 展示提示
    themeName: getStore({ name: 'themeName' }) || 'theme-blue', // 主题名称
    website: website, //
    dictionary: getStore({ name: 'DICTIONARYKEY' }) || [], // 数据字典
    unReadData: {
      messageNumber: 0,
      noticeNumber: 0,
      totalNumber: 0
    },
    pdfUrl: '',
    imgViewerList: [],
    platformConfig: getStore({ name: 'PLATFORMCONFIGKEY' }) || {},
    axiosCancel: [],
    noVerifyPageRouterList: [],
    keepAliveExclude: [] // 不缓存keepalive的列表
  },
  mutations: {
    // 设置为系统升级中..
    // SET_ISUPGRADE: (state, flag) => {
    //   state.isUpgrade = flag

    //   setStore({
    //     name: 'isUpgrade',
    //     content: state.isUpgrade
    //   })
    // },
    SET_GLOBAL_REPLACE_TEXT_LIST(state, globalReplaceTextList) {
      state.globalReplaceTextList = globalReplaceTextList
      setStore({
        name: 'GLOBALREPLACETEXTLISTKEY',
        content: globalReplaceTextList
      })
    },
    SET_FONT_CONFIG(state, fontConfig) {
      state.fontConfig = fontConfig
      setStore({ name: 'FONTCONFIGKEY', content: fontConfig })
    },
    SET_VehicleLocationAndTrajectoryPlateNo: (
      state,
      vehicleLocationAndTrajectoryPlateNo
    ) => {
      state.vehicleLocationAndTrajectoryPlateNo =
        vehicleLocationAndTrajectoryPlateNo
    },
    SET_PDFURL: (state, pdfUrl) => {
      state.pdfUrl = pdfUrl
    },
    SET_IMG_VIEWER_LIST: (state, imgViewerList) => {
      state.imgViewerList = imgViewerList
    },
    SET_VOUCHER_PRINT_DATA_LIST: (state, voucherPrintDataList) => {
      state.voucherPrintDataList = voucherPrintDataList
      setStore({ name: 'VOUCHERPRINTKEY', content: state.voucherPrintDataList })
    },
    SET_SUBJECT: (state, subjectList) => {
      state.subjectList = subjectList
    },
    SET_AUXILIARY_STARTNODE: (state, startNodeList) => {
      state.auxiliaryObj.startNodeList = startNodeList
    },
    SET_AUXILIARY_ENDNODE: (state, endNodeList) => {
      state.auxiliaryObj.endNodeList = endNodeList
    },
    SET_AUXILIARY_SHIPPERPARTNER: (state, shipperPartnerList) => {
      state.auxiliaryObj.shipperPartnerList = shipperPartnerList
    },
    SET_AUXILIARY_CARRIERPARTNER: (state, carrierPartnerList) => {
      state.auxiliaryObj.carrierPartnerList = carrierPartnerList
    },
    SET_AUXILIARY_DRIVER: (state, driverList) => {
      state.auxiliaryObj.driverList = driverList
    },
    SET_AUXILIARY_EMPLOYEE: (state, employeeList) => {
      state.auxiliaryObj.employeeList = employeeList
    },
    SET_RECENTLY_VISITED: (state, recentlyVisited) => {
      if (recentlyVisited.path === '/main/index') {
        return
      }
      const existIndex = state.recentlyVisitedList.findIndex((item) => {
        return item.path === recentlyVisited.path
      })
      if (existIndex > -1) {
        state.recentlyVisitedList.splice(existIndex)
        state.recentlyVisitedList.unshift(recentlyVisited)
        return
      }
      state.recentlyVisitedList.unshift(recentlyVisited)
      if (state.recentlyVisitedList.length >= 20) {
        state.recentlyVisitedList.pop()
      }

      setStore({
        name: 'RECENTLYVISITEDKEY',
        content: state.recentlyVisitedList
      })
    },
    SET_QUICK_MENU_LIST: (state, quickMenuList) => {
      state.quickMenuList = quickMenuList
      setStore({ name: 'QUICKMENUKEY', content: state.quickMenuList })
    },
    SET_HEATMAP_PROVINCE: (state, heatmapProvince) => {
      state.heatmapProvince = heatmapProvince
      setStore({
        name: 'heatmapProvince',
        content: state.heatmapProvince
      })
    },
    SET_HEATMAP_VEHICLE_NO: (state, heatmapVehicleNo) => {
      state.heatmapVehicleNo = heatmapVehicleNo
      setStore({
        name: 'heatmapVehicleNo',
        content: state.heatmapVehicleNo
      })
    },
    SET_AXIOSCANCEL: (state, cancel) => {
      state.axiosCancel.push(cancel)
    },
    SET_CLEARCANCEL: (state) => {
      state.axiosCancel = []
    },
    SET_noVerifyPageRouterList: (state, noVerifyPageRouterList) => {
      state.noVerifyPageRouterList = noVerifyPageRouterList
    },
    SET_keepAliveExclude: (state, keepAliveExclude) => {
      state.keepAliveExclude = keepAliveExclude
    },
    SET_LANGUAGE: (state, language) => {
      state.language = language
      setStore({
        name: 'language',
        content: state.language
      })
    },
    SET_COLLAPSE: (state) => {
      state.isCollapse = !state.isCollapse
      setStore({
        name: 'isCollapse',
        content: state.isCollapse,
        type: 'session'
      })
    },
    SET_IS_MENU: (state, menu) => {
      state.isMenu = menu
    },
    SET_FULLSCREN: (state) => {
      state.isFullScren = !state.isFullScren
    },
    SET_SCREEN: (state, screen) => {
      state.screen = screen
    },
    SET_THEME_NAME: (state, themeName) => {
      state.themeName = themeName
      setStore({
        name: 'themeName',
        content: state.themeName
      })
    },
    SET_DICTIONARY(state, dic) {
      state.dictionary = dic

      setStore({
        name: 'DICTIONARYKEY',
        content: state.dictionary
      })
    },
    SET_UNREAD(state, unReadData) {
      state.unReadData = unReadData
    },
    SET_PLATFORM_CONFIG(state, config) {
      state.platformConfig = config

      setStore({
        name: 'PLATFORMCONFIGKEY',
        content: state.platformConfig
      })
    },
    SET_FRESH_DISPATCH(state) {
      state.freshDispatch += 1
      setStore({
        name: 'FRESHDISPATCHKEY',
        content: state.freshDispatch
      })
    },
    SET_MESSAGE_DETAIL(state, messageDetail) {
      state.messageDetail = messageDetail
    },
    SET_PAY_RECEIVE(state, config) {
      state.payOrReceive = config
      setStore({
        name: 'PAYORRECEIVEKEY',
        content: state.payOrReceive
      })
    }
  },
  actions: {
    // 查询企业自定义字符串
    LoadSysTextReplaceConfig({ commit }) {
      return new Promise((resolve, reject) => {
        let replaceTextList = []
        const params = {
          size: 1000,
          page: 1
        }
        configSettingApi.querySysTextReplaceConfig(params).then((ret) => {
          if (
            !validatenull(ret.data.content) &&
            ret.data.content[0].enable
          ) {
            replaceTextList = ret.data.content.map((item) => {
              return {
                name: item.name,
                value: item.value
              }
            })
            commit('SET_GLOBAL_REPLACE_TEXT_LIST', replaceTextList)
          }
        })
      })
    },
    // 查询平台个性化配置
    LoadPlatformConfig({ commit }) {
      return new Promise((resolve, reject) => {
        notVerifiedApi
          .queryEnterpriseByDomainInfo2(document.location.host)
          .then((ret) => {
            const retData = validatenull(ret.data) ? {} : ret.data
            localStorage.setItem('themeStyle', '')
            if(retData.themeStyle){
              localStorage.setItem('themeStyle', retData.themeStyle)
              commit("SET_THEME_NAME", retData.themeStyle)
              document.body.classList.remove('theme-blue')
              document.body.classList.remove('theme-red')
              document.body.classList.add(retData.themeStyle)
            }
            commit('SET_PLATFORM_CONFIG', retData)
            resolve(retData)
          })
          .catch(() => {
            commit('SET_PLATFORM_CONFIG', {})
            reject()
          })
      })
    },
    LoadSubjectList({ commit }) {
      return new Promise((resolve, reject) => {
        const params = {
          status: 0,
          page: 1,
          size: 9999
        }
        subjectApi
          .queryFinanceSuject(params)
          .then((ret) => {
            commit('SET_SUBJECT', ret.data)
            resolve()
          })
          .catch(() => {
            commit('SET_SUBJECT', [])
            reject()
          })
      })
    },
    LoadAuxiliaryObj({ commit }) {
      return new Promise((resolve, reject) => {
        paymentRegistrationApi
          .listTopSubOrganization({ dataPermission: false })
          .then((ret) => {
            ret.data.forEach((item) => {
              item.auxiliaryType = '1'
              item.code = item.orgCode
            })
            const startNodeList = ret.data
            commit('SET_AUXILIARY_STARTNODE', startNodeList)
            ret.data.forEach((item) => {
              item.auxiliaryType = '2'
              item.code = item.orgCode
            })
            const endNodeList = ret.data
            commit('SET_AUXILIARY_ENDNODE', endNodeList)
          })
        const params = {
          page: 1,
          size: 9999
        }
        partnerApi.pagePartner(params).then((ret) => {
          ret.data.content.forEach((item) => {
            item.auxiliaryType = '3'
            item.name = item.inviteeName
            item.code = item.id
          })
          const shipperPartnerList = ret.data.content.filter((item) => {
            return item.partnerGroup === 1
          })
          commit('SET_AUXILIARY_SHIPPERPARTNER', shipperPartnerList)

          ret.data.content.forEach((item) => {
            item.auxiliaryType = '4'
            item.name = item.inviteeName
            item.code = item.id
          })
          const carrierPartnerList = ret.data.content.filter((item) => {
            return item.partnerGroup === 2
          })
          commit('SET_AUXILIARY_CARRIERPARTNER', carrierPartnerList)
        })
        const params2 = {
          page: 1,
          size: 9999
        }
        employeeApi.pageEmployee(params2).then((ret) => {
          ret.data.content.forEach((item) => {
            item.auxiliaryType = '6'
            item.name = item.name
            item.code = item.userId
          })
          const employeeList = ret.data.content
          commit('SET_AUXILIARY_EMPLOYEE', employeeList)
        })
        const params3 = {
          orgId: store.getters.userInfo.orgId,
          page: 1,
          size: 9999
        }
        platformDriverApi.pagePlatformDriver(params3).then((ret) => {
          ret.data.content.forEach((item) => {
            item.auxiliaryType = '5'
            item.name = item.realName
            item.code = item.userId
          })
          const driverList = ret.data.content
          commit('SET_AUXILIARY_DRIVER', driverList)
        })
      })
    },
    LoadFaceUrl({ commit }) {
      return new Promise((resolve, reject) => {
        informationApi.fetchOrganizationExtendByTopOrgId().then((ret) => {
          const retData = validatenull(ret.data) ? {} : ret.data
          commit('SET_PLATFORM_CONFIG', retData)
          resolve()
        })
      })
    },
    LoadDictionary({ commit }) {
      return new Promise((resolve, reject) => {
        dictionaryApi.getAllDict().then((ret) => {
          const retData = ret.data
          commit('SET_DICTIONARY', retData)
          resolve()
        })
      })
    },
    LoadIsNeedUserGuide({ commit }, params) {
      return new Promise((resolve, reject) => {
        userApi.isVersionNoviceGuide(params).then((ret) => {
          const retData = ret.data
          resolve(retData)
        })
      })
    },
    LoadUnReadMsgCount({ commit }, params = {unOpen:false}) {
      return new Promise((resolve, reject) => {
        messageApi.getUnReadMsgCount().then((ret) => {
          const retData = ret.data
          if(params.unOpen){
            retData['unOpen'] = params.unOpen
          }
          commit('SET_UNREAD', retData)
          resolve()
        })
      })
    },
    LoadOrgAlliance({ commit, dispatch, state }, params) {
      // 获取是否是物流公社联盟成员
      setTimeout(() => {
        ssoApi.getEnterpriseAuditVo().then((ret) => {
          commit(
            'SET_USERIFNO',
            Object.assign(store.getters.userInfo, {
              isEnterpriseUnvali: ret.data.enterpriseVo.auditState === 1,
              comboVersion: ret.data.enterpriseVo.comboVersion,
              orgName: ret.data.enterpriseVo.name,
              alliance: ret.data.enterpriseVo.alliance
            })
          )
        })
      }, 1000)
    },
    LoadSetEnterpriseUnvali({ commit }) {
      commit(
        'SET_USERIFNO',
        Object.assign(store.getters.userInfo, {
          isEnterpriseUnvali: false
        })
      )
    },
    LoadResourceTree({ commit, dispatch, state }, params) {
      return new Promise((resolve, reject) => {
        commonApi.getResourceTreeByEmployeeId(params).then((ret) => {
          dispatch('LoadOrgAlliance')
          const menu = ret.data
          commit('SET_MENU', menu)
          const pathList = []
          const menuIconList = []
          const permissionList = []
          for (let index = 0; index < menu.length; index++) {
            const element = menu[index]
            pathList.push(element.path)
            menuIconList.push({
              name: element.name,
              path: element.path,
              icon: element.icon
            })
            if (element.children !== null && element.children.length !== 0) {
              for (let j = 0; j < element.children.length; j++) {
                const item = element.children[j]
                if (item.type === 'button') {
                  permissionList.push(item.flagCode)
                }
                pathList.push(item.path)
                menuIconList.push({
                  name: item.name,
                  path: item.path,
                  icon: item.icon
                })
                if (item.children !== null && item.children.length !== 0) {
                  for (let k = 0; k < item.children.length; k++) {
                    const item1 = item.children[k]
                    if (item1.type === 'button') {
                      permissionList.push(item1.flagCode)
                    }
                  }
                }
              }
            }
          }
          commit('SET_PERMISSION', permissionList)
          commit('SET_MENU_PATH_LIST', pathList)
          // if (localStorage.getItem('quickmenu')) {
          //   const quickmenuList = JSON.parse(localStorage.getItem('quickmenu'))
          //   const perQuickmenuList = quickmenuList.filter(item => {
          //     const some = pathList.some(item2 => {
          //       return item2 === item.path
          //     })
          //     return some
          //   })
          //   localStorage.setItem('quickmenu', JSON.stringify(perQuickmenuList))
          // }
          // const recentlyList = state.recentlyVisitedList
          // const perRecentlyList = recentlyList.filter(item => {
          //   const some = pathList.some(item2 => {
          //     return item2 === item.path
          //   })
          //   return some
          // })
          // commit('SET_RECENTLY_VISITED_LIST', perRecentlyList)
          commit('SET_MENU_LIST', menuIconList)
          resolve(pathList)
        })
      })
    }
  }
}

export default common
