import { deepClone } from "@util/util"

/**
 * 判断是不是数组
 * @param o
 * @returns {boolean}
 */
export function isArray(o) {
  return Object.prototype.toString.call(o) === '[object Array]'
}

/**
 * 电话号码
 * @param {*} s
 */
export function isMobile(s) {
  return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s)
}

export function isPhone(phone) {
  return /^1(3|4|5|6|7|8|9)\d{9}$/.test(phone)
}

/**
 * 重置密码 (8-20位密码,包含字母数字特殊字符)
 * @param {*} s
 */
export function resetPass(s) {
  return /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9])(?=.*[!@#$%^&]).{8,20}$/.test(s)
}

// 去掉中文及空格
export function verifyCnAndSpace(val) {
  let v = val.replace(/[\u4e00-\u9fa5\s]+/g, "")
  v = v.replace(/(^\s*)|(\s*$)/g, "")
  return v
}

// 去掉英文及空格
export function verifyEnAndSpace(val) {
  let v = val.replace(/[a-zA-Z]+/g, "")
  v = v.replace(/(^\s*)|(\s*$)/g, "")
  return v
}

// 禁止输入空格
export function verifyAndSpace(val) {
  const v = val.replace(/(^\s*)|(\s*$)/g, "")
  return v
}

/**
 * URL地址
 * @param {*} s
 */
export function isURL(s) {
  return /^http[s]?:\/\/.*/.test(s)
}

/**
 * 判断邮箱地址
 * @param email
 * @returns {{msg: string, flag: boolean}}
 */
export function validateEmail(email) {
  let ret = {flag: true, msg: '校验成功！'}
  const re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!re.test(email)) {
    ret = {flag: false, msg: '请输入正确的邮箱'}
  }
  return ret
}

/**
 * 判断手机号码是否正确
 * @param phone
 * @returns {{msg: string, flag: boolean}}
 */
export function validatePhone(phone) {
  let ret = {flag: true, msg: '校验成功！'}

  // 增加134 减少|1349[0-9]{7}，增加181,增加145，增加17[678]
  if (!validatenull(phone)) {
    if (phone.length === 11) {
      if (!/^1(1|3|4|5|6|7|8|9)\d{9}$/.test(phone)) { // 运营要求支持110开头的手机号
        ret = {flag: false, msg: '手机号码格式不正确'}

        return ret
      } else {
        return ret
      }
    } else {
      ret = {flag: false, msg: '手机号码长度错误'}

      return ret
    }
  } else {
    ret = {flag: false, msg: '手机号码不能为空'}

    return ret
  }
}

/**
 * 判断手机号码和座机是否正确
 * @param phone
 * @returns {{msg: string, flag: boolean}}
 */
 export function validateMobile(mobile) {
  let ret = {flag: true, msg: '校验成功！'}
  if (!validatenull(mobile)) {
    const reg = /^\d+([+-]\d+)*$/
    if (!reg.test(mobile)) {
      ret = {flag: false, msg: '请输入正确的电话号码'}
      return ret
    } else {
      return ret
    }
  } else {
    ret = {flag: false, msg: '电话号码不能为空'}
    return ret
  }
}

export function validateNumberPlate(plate) {
  let ret = {flag: true, msg: '校验成功！'}

  const xreg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF]$)|([DF][A-HJ-NP-Z0-9][0-9]{4}$))/
  const creg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1}$/

  if (!validatenull(plate)) {
    if (plate.length === 7) {
      if (!creg.test(plate)) {
        ret = {flag: false, msg: '牌照号输入错误'}

        return ret
      } else {
        return ret
      }
    } else if (plate.length === 8) {
      if (!xreg.test(plate)) {
        ret = {flag: false, msg: '牌照号输入错误'}

        return ret
      } else {
        return ret
      }
    } else {
      ret = {flag: false, msg: '牌照号长度错误'}

      return ret
    }
  } else {
    ret = {flag: false, msg: '牌照号不能为空'}

    return ret
  }
}

/**
 * 判断姓名是否正确
 * @param name
 * @returns {boolean}
 */
export function validateName(name) {
  const regName = /^[\u4e00-\u9fa5]{2,4}$/
  if (!regName.test(name)) return false
  return true
}

/**
 * 判断是否为空，空:true,反之:false
 * @param val
 * @returns {boolean}
 */
export function validatenull(val) {
  if (val && parseInt(val) === 0) return false // 特殊判断

  const list = ['$parent']
  if (typeof val === 'boolean') {
    return false
  }
  if (typeof val === 'number') {
    return false
  }
  if (val instanceof Array) {
    if (val.length === 0) return true
  } else if (val instanceof Object) {
    val = deepClone(val)
    list.forEach(ele => {
      delete val[ele]
    })
    if (JSON.stringify(val) === '{}') return true
  } else {
    if (
      val === 'null' ||
      val == null ||
      val === 'undefined' ||
      typeof (val) === 'undefined' ||
      val === ''
    ) {
      return true
    }
    return false
  }
  return false
}

/**
 * 判断是否有值，没有则返回默认值
 * @param val
 * @param def
 * @returns {boolean|*}
 */
export const vaildData = (val, def) => {
  if (typeof val === 'boolean') {
    return val
  }
  return !validatenull(val) ? val : def
}