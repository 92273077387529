<template>
  <el-option :key="key" :label="labelNew" v-model="value" :disabled="disabled"></el-option>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'my-option',

  props: {
    key: String || Number,
    label: String || Number,
    value: String || Number,
    disabled: Boolean
  },
  computed: {
    ...mapGetters(['globalReplaceTextList']),
    labelNew() {
      let labelNew = this.label
      this.globalReplaceTextList.forEach((element) => {
        if (labelNew.indexOf(element.name) > -1) {
          labelNew = labelNew.replaceAll(element.name, element.value)
        }
      })
      return labelNew
    }
  }
}
</script>
