import { setToken, removeToken, getToken } from '@/util/auth'
import { setStore, getStore, clearStore } from '@/util/store'
import { isURL, validatenull } from '@/util/validate'
import { deepClone, formatMenu } from '@/util/util'
import webiste from '@/config/website'
import ssoApi from '@/api/ssoApi'
import commonApi from '@/api/commonApi'
import messageApi from '@/api/message/messageApi'

function addPath(ele, first) {
  const menu = webiste.menu
  const propsConfig = menu.props
  const propsDefault = {
    label: propsConfig.label || 'label',
    path: propsConfig.path || 'path',
    icon: propsConfig.icon || 'icon',
    children: propsConfig.children || 'children'
  }
  const icon = ele[propsDefault.icon]
  ele[propsDefault.icon] = validatenull(icon) ? menu.iconDefault : icon
  const isChild =
    ele[propsDefault.children] && ele[propsDefault.children].length !== 0
  if (!isChild && first && !isURL(ele[propsDefault.path])) {
    ele[propsDefault.path] = ele[propsDefault.path] + '/index'
  } else {
    ele[propsDefault.children].forEach((child) => {
      if (!isURL(child[propsDefault.path])) {
        child[propsDefault.path] = `${ele[propsDefault.path]}/${
          child[propsDefault.path] ? child[propsDefault.path] : 'index'
        }`
      }
      addPath(child)
    })
  }
}

const user = {
  state: {
    userInfo: getStore({ name: 'USERINFOKEY' }) || {},
    userMqtt: getStore({ name: 'USERMQTTKEY'}) || {},
    permission: getStore({ name: 'PERMISSIONKEY' }) || [],
    menuId: getStore({ name: 'MENUIDKEY' }) || [],
    menu: getStore({ name: 'MENUKEY' }) || [],
    menuPathList: getStore({ name: 'MENUPATHLISTKEY' }) || [],
    menuIconList: getStore({ name: 'MENULISTKEY' }) || [],
    menuAll: getStore({ name: 'MENUALLKEY' }) || [],
    token: getToken() || ''
  },
  actions: {
    // 登录后获取用户详情
    GetUserDetail({ commit, dispatch }, params) {
      return new Promise((resolve, reject) => {
        commonApi
          .getUserDetail(params.uid)
          .then((ret) => {
            const retData = ret.data
            commit('SET_USERIFNO', Object.assign(params, retData))
            resolve()
          })
          .catch(() => {})
      })
    },
    // 获取用户mqtt信息
    GetMqttUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        messageApi
          .getMqttUserInfo()
          .then((ret) => {
            const retData = ret.data
            commit('SET_USERMQTT', retData)
            resolve()
          })
          .catch(() => {})
      })
    },
    // 根据手机号发验证码登录
    LoginByUsername({ commit, dispatch }, loginForm) {
      return new Promise((resolve, reject) => {
        ssoApi
          .localLogin(loginForm)
          .then((ret) => {
            const retData = ret.data
            commit('SET_TOKEN', {
              token: retData.token,
              expires: new Date(retData.expireAt.replace(/-/g, '/')).getTime()
            })
            commit('SET_USERIFNO', retData)
            commit('DEL_ALL_TAG')
            commit('CLEAR_LOCK')
            dispatch('GetUserDetail', retData)
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    // 根据手机号发验证码登录,双重监测
    loginByUsernameDoubleCheck({ commit, dispatch }, loginForm) {
      return new Promise((resolve, reject) => {
        ssoApi
          .localLoginDoubleCheck(loginForm)
          .then((ret) => {
            const retData = ret.data
            commit('SET_TOKEN', {
              token: retData.token,
              expires: new Date(retData.expireAt.replace(/-/g, '/')).getTime()
            })
            commit('SET_USERIFNO', retData)
            commit('DEL_ALL_TAG')
            commit('CLEAR_LOCK')
            dispatch('GetUserDetail', retData)
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    // 根据账号密码登录
    LoginByPassword({ commit, dispatch }, loginForm) {
      return new Promise((resolve, reject) => {
        ssoApi
          .doPasswordLogin(loginForm)
          .then((ret) => {
            const retData = ret.data
            commit('SET_TOKEN', {
              token: retData.token,
              expires: new Date(retData.expireAt.replace(/-/g, '/')).getTime()
            })
            commit('SET_USERIFNO', retData)
            commit('DEL_ALL_TAG')
            commit('CLEAR_LOCK')
            dispatch('GetUserDetail', retData)
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    // 登出
    LogOut({ state, commit, dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('FedLogOut')
          .then(() => {
            resolve()
          })
          .catch(() => {
            resolve()
          })
      })
    },
    // 注销session
    FedLogOut({ state, commit }) {
      return new Promise((resolve) => {
        commit('SET_USERMQTT', {})
        commit('SET_TOKEN', {})
        commit('SET_MENUID', {})
        commit('SET_MENUALL', [])
        commit('SET_MENU', [])
        commit('SET_USERIFNO', {})
        commit('SET_PERMISSION', [])
        commit('DEL_ALL_TAG')
        commit('CLEAR_LOCK')
        commit('CLEAR_TAG')
        commit('SET_PLATFORM_CONFIG', {})
        commit('SET_ISUPGRADE', '')

        removeToken()
        clearStore({ type: 'session' })
        // clearStore()

        resolve()
      })
    },
    // 获取系统菜单
    GetMenu({ state, commit }) {
      return new Promise((resolve) => {
        const formatMenus = formatMenu(state.userInfo.menuVOList)
        const menu = deepClone(
          formatMenus.length === 1 ? formatMenus[0].children : formatMenus
        )

        menu.forEach((ele) => {
          addPath(ele, true)
        })
        commit('SET_MENU', menu)
        resolve(menu)
      })
    }
  },
  mutations: {
    SET_TOKEN: (state, { token, expires }) => {
      state.token = token
      setToken(token, expires)
    },
    SET_USERIFNO: (state, userInfo) => {
      if(!userInfo.comboVersion) {
        userInfo.comboVersion = 0
      }
      if(userInfo.isEnterpriseUnvali === true) {
        userInfo.isEnterpriseUnvali = true
      }
      state.userInfo = userInfo
      setStore({ name: 'USERINFOKEY', content: state.userInfo })
    },
    SET_USERMQTT: (state, userMqtt) => {
      state.userMqtt = userMqtt
      setStore({ name: 'USERMQTTKEY', content: state.userMqtt })
    },
    SET_MENUID(state, menuId) {
      state.menuId = menuId
      setStore({ name: 'MENUIDKEY', content: state.menuId, type: 'session' })
    },
    SET_MENUALL: (state, menuAll) => {
      state.menuAll = menuAll
      setStore({ name: 'MENUALLKEY', content: state.menuAll, type: 'session' })
    },
    SET_MENU_PATH_LIST: (state, menuPathList) => {
      state.menuPathList = menuPathList
      setStore({
        name: 'MENUPATHLISTKEY',
        content: state.menuPathList,
        type: 'session'
      })
    },
    SET_MENU_LIST: (state, menuIconList) => {
      state.menuIconList = menuIconList
      setStore({
        name: 'MENULISTKEY',
        content: state.menuIconList,
        type: 'session'
      })
    },
    SET_MENU: (state, menu) => {
      state.menu = menu
      setStore({ name: 'MENUKEY', content: state.menu, type: 'session' })
    },
    SET_PERMISSION: (state, permission) => {
      state.permission = permission
      setStore({ name: 'PERMISSIONKEY', content: state.permission })
    }
  }
}
export default user
