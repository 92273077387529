import store from '@/store'
import { validatenull } from '@/util/validate'

/**
 * @date 2019-11-25 09:22:20
 * @Description: 按钮权限判断
 * @Param:
 */
export const hasPermission = (key) => {
  if (validatenull(key)) return false
  const permissions = store.getters.permission

  if (validatenull(permissions)) return false

  return permissions.includes(key)
}

/**
 * @date 2019-11-25 09:22:20
 * @Description: 菜单权限判断
 * @Param:
 */
export const hasMenuPermission = (key) => {
  if (validatenull(key)) return false
  const menuPermissions = store.getters.menuPathList

  if (validatenull(menuPermissions)) return false

  return menuPermissions.includes(key)
}
