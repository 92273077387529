/**
 * 创建、配置路由
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import PageRouter from './page/'
import ViewsRouter from './views/'
import AvueRouter from './avue-router'
import i18n from '@/lang' // Internationalization
import Store from '../store/'

Vue.use(VueRouter)
// 创建路由
export const createRouter = () => new VueRouter({
  scrollBehavior(to, from, savedPosition) { // 实现路由跳转之后滚动条滚到顶部
    if (savedPosition) {
      return savedPosition
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop
      }
      return {
        x: 0,
        y: to.meta.savedPosition || 0
      }
    }
  },
  mode: 'history',
  routes: [...PageRouter, ...ViewsRouter]
})

const Router = createRouter()

AvueRouter.install(Vue, Router, Store, i18n) // 路由插件，本身和路由并无关系
Router.addRoutes([...PageRouter, ...ViewsRouter])

export function resetRouter() {
  AvueRouter.install(Vue, Router, Store, i18n)
}

export default Router
