export default {
  install: function(Vue, options) {
    options = options || {}
    /**
     * 格式化绑定到dom上的数据
     * @param {*} binding
     */
    function formatBinding(binding) {
      var trackdata = ''
      var eventmode = 'click'
      if (typeof binding.value === 'object') {
        if ('event' in binding.value) {
          eventmode = binding.value.event
        }
        if ('data' in binding.value) {
          trackdata = binding.value.data
        } else {
          trackdata = binding.value
        }
      } else {
        trackdata = binding.value
      }
      return {
        eventmode: eventmode,
        trackdata: trackdata
      }
    }
    // 初始化
    if ('init' in options && options.init instanceof Function) {
      try {
        options.init()
      } catch (error) {
        if (options.debug) {
          console.log(error)
        }
      }
    }
    Vue.directive('trackevent', {
      bind: function(el, binding) {
        var format = formatBinding(binding)
        el.trackdata = format.trackdata
        el.addEventListener(format.eventmode, function(e) {
          try {
            if ('callback' in options && options.callback instanceof Function) {
              options.callback(el.trackdata, e)
            }
            if (options.debug) {
              console.log(el.trackdata)
            }
          } catch (error) {
            if (options.debug) {
              console.log(error)
            }
          }
        })
      },
      update: function(el, binding) {
        var format = formatBinding(binding)
        el.trackdata = format.trackdata
      }
    })
  }
}
