var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "border-box-3" }, [
    _c(
      "section",
      { staticClass: "b3-header" },
      [
        _vm.title
          ? _c("span", {
              staticClass: "title",
              domProps: { innerHTML: _vm._s(_vm.title) }
            })
          : _vm._e(),
        _vm._t("menu")
      ],
      2
    ),
    _c(
      "section",
      { staticClass: "b3-content", class: [_vm.padding ? "" : "p0"] },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }