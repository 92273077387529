<template>
  <section class="border-box-1">
    <section class="border-box__header" v-if="title" :style="{background: headerBgc}">
    
      <section class="border-box__menu" v-if="$slots.headerMenu">
        <slot name="headerMenu"></slot>
      </section>
    </section>

    <section class="border-box-1__content"
             :class="[
      border ? 'border-box-1__b' : '',
      padding ? 'border-box-1__p' : ''
    ]">
      <span class="border-box__title">{{title}}</span>
      <section class="border-box__title-right" v-if="$slots.menuRight">
        <slot name="menuRight"></slot>
      </section>
      <div class="border-box__context">
      <slot></slot>
      </div>
    </section>
  </section>
</template>

<script>
import { updateMixin } from "@/mixins/global"
export default {
  mixins: [updateMixin],
    name: "border-box-1",
    props: {
      headerBgc: {
        type: String
      },
      title: {
        type: String
      },
      padding: {
        type: Boolean,
        default: true
      },
      border: {
        type: Boolean,
        default: true
      }
    }
  }
</script>

<style scoped lang="scss">
.border-box__title-right {
  float: right;
}
</style>