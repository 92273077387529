/**
 * 全局配置文件
 */
export default {
  isSystemUpgrade: false, // 是否系统升级
  key: 'BUSINESS', // 配置主键，用于存储
  tokenTime: 6000,
  duration: 2000,
  // 配置首页不可关闭
  isFirstPage: false,
  firstPage: {
    label: '首页',
    value: '/main/index',
    params: {},
    query: {},
    meta: {
      i18n: 'dashboard'
    },
    group: [],
    close: false
  },
  // 配置菜单的属性
  menu: {
    iconDefault: 'icon-menu-default',
    props: {
      label: 'name',
      path: 'path',
      icon: 'icon',
      children: 'children'
    }
  },
  // 全局的计量单位配置
  measureUnit: {
    yuan: '元',
    ton: '吨',
    millimeter: 'mm',
    kilogram: 'kg',
    vol: 'm³', // 体积
    weight: 'kg', // 重量,
    meter: 'm',
    centimeter: 'cm', // 厘米
    thousandth: '‰', // 千分之
    cent: '%' // 百分之
  },
  // 全局time picker
  timePicker: {
    start: '00:00',
    step: '00:30',
    end: '23:30'
  },
  inputNumberConfig: {
    default: {
      min: 0,
      max: 99999999,
      precision: 2
    },
    length: {
      min: 0,
      max: 99999999,
      precision: 0
    },
    width: {
      min: 0,
      max: 99999999,
      precision: 0
    },
    height: {
      min: 0,
      max: 99999999,
      precision: 0
    },
    weight: {
      min: 0,
      max: 99999999,
      precision: 3
    },
    vol: {
      min: 0,
      max: 99999999,
      precision: 2
    },
    piece: {
      min: 0,
      max: 99999999,
      precision: 0
    }
  },
  navigateUri: {
    createTaxFsource: '/fSource/tax/create',
    login: '/login'
  },
  crudOption: {
    emptyText: '没有查询到任何数据',
    index: false,
    addBtn: false,
    delBtn: false,
    editBtn: false,
    size: 'mini',
    tableType: 'normal', // grid
    indexWidth: 44,
    selectionWidth: 44,
    // headerAlign: 'center',
    menuWidth: 90,
    stripe: true,
    tip: false,
    border: true,
    highlightCurrentRow: true,
    height: 'auto',
    excelBtn: false,
    // filterBtn: false,
    // sortable: true,
    columnBtn: true,
    columnSort: true, // 拖拽列
    // rowSort: true,
    calcHeight: 50,
    menuType: 'menu',
    refreshBtn: false
  }
}
