<template>
  <div class="basic-container">
    <el-card
      :class="{ 'basic-container__menu': $slots.menu }"
      shadow="always"
      class="basic-container--height"
    >
      <section
        class="title-container d-flex flex-justify-content-between"
        v-if="!validatenull(title)"
      >
        <span class="title pt5 pb5 pl10 text-size-14">{{ title }}</span>
        <slot name="headerMenu"></slot>
      </section>
      <slot></slot>
    </el-card>
    <section class="basic-container--menu" v-if="$slots.menu">
      <slot name="menu"></slot>
    </section>
  </div>
</template>

<script>
import { updateMixin } from "@/mixins/global"
export default {
  mixins: [updateMixin],
  name: 'basicContainer',
  props: {
    title: {
      type: String
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/variables.scss';

.basic-container {
  // padding: 0px 10px 0px 0px;
  border-radius: 4px;
  box-sizing: border-box;

  .title-container {
    border-bottom: 1px solid $tipsTitleColor;

    .title {
      color: #fff;
      background: $tipsTitleColor;
      background: linear-gradient(-135deg, transparent 30px, $tipsTitleColor 0);
      background-repeat: no-repeat;
      padding-right: 50px !important;
      border-top-left-radius: 3px;
    }
  }

  .el-card {
    width: 100%;
    background: rgba(0, 0, 0, 0) !important;
  }

  &:first-child {
    /*padding-top: 0;*/
  }

  &--height {
    overflow-x: auto;
    overflow-y: auto;
    // border-radius: 2px;
    height: calc(100vh - 69px);
  }

  &--card {
    /*height: calc(100vh - 124px);*/
    overflow-y: auto;
  }

  &--menu {
    position: relative;
    // bottom: -1px;
    width: 100%;
    /*padding: 18px 20px;*/
    background: #fff;
    height: 70px;
    line-height: 70px;
    border-bottom-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-top: 1px solid #ebeef5;
    z-index: 999;
  }

  &__menu {
    height: calc(100vh - (114px + 70px));
  }

  &--block {
    height: 100%;

    .el-card {
      height: 100%;
    }
  }
}
</style>
