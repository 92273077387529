<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import configSettingApi from '@/api/configSetting/configSettingApi'
// import { updateMixin } from "@/mixins/global"
export default {
  name: 'app',
  // mixins: [updateMixin],
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  computed: {
    ...mapGetters(['fontConfig']),
  },
  // created() {
  //   this.querySysTextReplaceConfig()
  // },
  mounted() {
    // 友盟统计添加
    const script = document.createElement('script')
    script.src =
      'https://s4.cnzz.com/z_stat.php?id=1281173155&web_id=1281173155' // 该路径为点击统计代码后，页面中第一个 文字统计 script代码
    script.language = 'JavaScript'
    document.body.appendChild(script)
  },
  watch: {
    $route(route, oldRoute) {
      if (window._czc) {
        const contentUrl = route.name
        const refererUrl = oldRoute.name
        window._czc.push(['_trackPageview', contentUrl, refererUrl])
      }
    },
    fontConfig: {
      handler(val) {
        const body = document.body
        if (!this.validatenull(val)) {
          const configJson = JSON.parse(val)
          if (configJson.labelBackground) {
            body.classList.add('labelBg')
          } else {
            body.classList.remove('labelBg')
          }
          if (configJson.fontSize === 0) {
            body.classList.add('fontSZ0')
          } else {
            body.classList.remove('fontSZ0')
          }
          if (configJson.fontSize === 1) {
            body.classList.add('fontSZ1')
          } else {
            body.classList.remove('fontSZ1')
          }
          if (configJson.fontSize === 2) {
            body.classList.add('fontSZ2')
          } else {
            body.classList.remove('fontSZ2')
          }
          if (configJson.fontWeight === 1) {
            body.classList.add('fontWT1')
          } else {
            body.classList.remove('fontWT1')
          }
          this.$nextTick(() => {
            try {
              var ev = document.createEvent('Event')
              ev.initEvent('resize', true, true)
              window.dispatchEvent(ev)
            } catch (e) {}
          })
        } else {
          body.classList.add('fontSZ1')
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // querySysTextReplaceConfig() {
    //   const params = {
    //     size: 1000,
    //     page: 1
    //   }
    //   configSettingApi.querySysTextReplaceConfig(params).then((ret) => {
    //     if (
    //       !this.validatenull(ret.data.content) &&
    //       ret.data.content[0].enable
    //     ) {
    //       this.replaceTextList = ret.data.content.map((item) => {
    //         return {
    //           name: item.name,
    //           value: item.value
    //         }
    //       })
    //       this.$store.commit('SET_GLOBAL_REPLACE_TEXT_LIST', this.replaceTextList)
    //     }
    //   })
    // },
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    }
  }
}
</script>
<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  font-family: 'Noto Sans SC', 'Helvetica Neue', Helvetica, 'PingFang SC',
    'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
  font-style: normal;
}
</style>
