import http from '@/router/axios'
import { clipApiPrefix } from '@/config/env'

const employeeApi = {}

/**
 * @Description: 查询员工列表
 */
employeeApi.pageEmployee = (object) => {
  return http.$POST(`${clipApiPrefix}/employee/pageEmployee`, {
    showLoading: false,
    params: object
  })
}

/**
 * @Description: 查询合作伙伴列表
 */
employeeApi.pagePartner = (object) => {
  return http.$POST(`${clipApiPrefix}/api/console/basic/partner/queryPartnerPage`,
    {
      showLoading: true,
      params: object
    })
}

/**
 * @Description: 添加数据权限
 */
employeeApi.setDataPermission = (object) => {
  return http.$POST(`${clipApiPrefix}/dataPermission/setDataPermission`,
    {
      showLoading: true,
      params: object
    })
}

/**
 * @Description: 查询员工历史列表
 */
employeeApi.pageEmployeeHistory = (object) => {
  return http.$POST(`${clipApiPrefix}/employee/pageEmployeeHistory`, {
    showLoading: true,
    params: object
  })
}

/**
 * @Description: 查询员工
 */
employeeApi.queryEmployee = (object) => {
  return http.$GET(`${clipApiPrefix}/employee/detail`, {
    params: object
  })
}

/**
 * @Description: 新增员工
 */
employeeApi.addEmployee = (object) => {
  return http.$POST(`${clipApiPrefix}/employee/addEmployee`, {
    showLoading: true,
    params: object
  })
}

/**
 * @Description: 修改员工
 */
employeeApi.editEmployee = (object) => {
  return http.$POST(`${clipApiPrefix}/employee/editEmployee`, {
    params: object
  })
}

/**
 * @Description: 删除员工
 */
employeeApi.deleteEmployee = (object) => {
  return http.$GET(`${clipApiPrefix}/employee/deleteEmployee`, {
    params: object,
    isParams: true
  })
}

/**
 * @Description: 批量修改角色
 */
employeeApi.batchEditEmployeeRole = (object) => {
  return http.$POST(`${clipApiPrefix}/employee/batchEditEmployeeRole`, {
    params: object
  })
}

/**
 * @Description: 批量删除员工
 */
employeeApi.batchDeleteEmployee = (object) => {
  return http.$GET(`${clipApiPrefix}/employee/batchDeleteEmployee`, {
    params: object,
    isParams: true
  })
}

/**
 * @Description: 查询部门列表
 */
employeeApi.listDepart = (object) => {
  return http.$GET(`${clipApiPrefix}/department/list`, {
    params: object,
    showLoading: false
  })
}

/**
 * @Description: 为角色移除员工
 */
employeeApi.roleRemoveEmployees = (object) => {
  return http.$POST(`${clipApiPrefix}/employee/roleRemoveEmployees`, {
    params: object,
    showLoading: true
  })
}

/**
 * @Description: 为角色移添加员工
 */
employeeApi.roleAddEmployees = (object) => {
  return http.$POST(`${clipApiPrefix}/employee/roleAddEmployees`, {
    params: object,
    showLoading: true
  })
}

/**
 * @Description: 查询当前企业的所有菜单列表(用于角色编辑)
 */
employeeApi.pageResourceByOrg = (object) => {
  return http.$POST(`${clipApiPrefix}/resource/pageResourceByOrg`, {
    params: object,
    showLoading: true
  })
}

/**
 * @Description: 机构新增角色
 */
employeeApi.addRoleAndResource = (object) => {
  return http.$POST(`${clipApiPrefix}/role/addRoleAndResource`, {
    params: object,
    showLoading: true
  })
}

/**
 * @Description: 机构查询角色详情
 */
employeeApi.getRoleAndResource = (object) => {
  return http.$GET(`${clipApiPrefix}/role/getRoleAndResource`, {
    params: object,
    showLoading: true,
    isParams: true
  })
}

/**
 * @Description: 机构修改角色
 */
employeeApi.editRoleAndResource = (object) => {
  return http.$POST(`${clipApiPrefix}/role/editRoleAndResource`, {
    params: object,
    showLoading: true
  })
}

/**
 * @Description: 机构删除角色
 */
employeeApi.delRoleAndResource = (object) => {
  return http.$POST(`${clipApiPrefix}/role/delRoleAndResource`, {
    params: object,
    showLoading: true,
    isParams: true
  })
}

/**
 * @Description: 显示已有权限
 */

employeeApi.queryDataPermissionByEmployeeId = (object) => {
  return http.$GET(`${clipApiPrefix}/dataPermission/queryDataPermissionByEmployeeId`, {
    params: object
  })
}

/** 
 * @Description: 查询员工机构权限
*/
employeeApi.getOrgPermissionListByEmployeeId = (object) => {
  return http.$GET(`${clipApiPrefix}/dataPermission/getOrgPermissionListByEmployeeId`, {
    params: object
  })
}

/** 
 * @Description: 设置员工机构权限
*/
employeeApi.setOrgPermission = (object) => {
  return http.$POST(`${clipApiPrefix}/dataPermission/setOrgPermission`, {
    params: object,
    showLoading: true
  })
}

/** 
 * @Description: 生成员工二维码
*/
employeeApi.lessIndentCreateQrcode = (object) => {
  return http.$POST(`${clipApiPrefix}/lessIndent/lessIndentCreateQrcode`, {
    params: object,
    showLoading: true
  })
}

/** 
 * @Description: 生成货主小程序二维码
*/
employeeApi.genXcxQrcodeByBase64 = (object) => {
  return http.$GET(`${clipApiPrefix}/lessIndent/genXcxQrcodeByBase64`, {
    params: object,
    showLoading: true
  })
}

/** 
 * @Description: 生成货主小程序二维码（带信息）
*/
employeeApi.getXcxQrCode = (object) => {
  return http.$POST(`${clipApiPrefix}/lessIndent/getXcxQrCode`, {
    params: object,
    showLoading: true
  })
}

/** 
 * @Description: 设置物流商小程序数据权限
*/
employeeApi.setLogisticsXcxPermission = (object) => {
  return http.$POST(`${clipApiPrefix}/dataPermission/setLogisticsXcxPermission`, {
    params: object,
    showLoading: true
  })
}
/** 
 * @Description: 生成司机APP二维码（带信息）
*/
employeeApi.getAppQrCode = (object) => {
  return http.$GET(`${clipApiPrefix}/biz/dispatchPlan/app/Qrcode/generate`, {
    params: object,
    showLoading: true
  })
}
export default employeeApi
