var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShowUpgrade
    ? _c("div", { staticClass: "upgrade-browser" }, [
        _c("div", { staticClass: "left" }, [
          _c("div", [_vm._v("为保证平台运营和使用的稳定性，建议")]),
          _c(
            "div",
            { staticClass: "upload-box", on: { click: _vm.uploadBrowser } },
            [
              _c(
                "el-link",
                {
                  staticStyle: { "font-size": "16px !important" },
                  attrs: { type: "primary" }
                },
                [_vm._v("下载安装新版谷歌浏览器")]
              ),
              _c("i", {
                staticClass: "el-icon-arrow-right",
                staticStyle: {
                  transform: "translateY(1px)",
                  "font-size": "18px !important",
                  "font-weight": "bold"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { on: { click: _vm.closeUpgrade } }, [
          _c("i", { staticClass: "el-icon-close close-icon" })
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }