<template>
  <div class="summaryBox" v-show="showSum" ref="tableSummaryBox">
    <section class="summary-box-container">
      <div class="title">
        <div class="pageCount" style="line-height: 30px;">页计：</div>
        <div class="pageAmount" style="line-height: 30px;">合计：</div>
      </div>
      <div class="contain">
        <el-tabs style="width: 100%" type="card">
          <el-tab-pane v-for="(item, index) in sumData" :key="index">
            <div slot="label" class="data-item">
              <div>
                <span class="item-name">{{ item.name }}: </span>
                {{ validatenull(item.pageValue) ? '-' : item.pageValue}}
              </div>
              <div>
                <span class="item-name">{{ item.name }}: </span>
                {{ validatenull(item.totalValue) ? '-' : item.totalValue }}
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </section>
  </div>
</template>

<script>
import { updateMixin } from '@/mixins/global'
export default {
  mixins: [updateMixin],
  name: "table-sum",
  props: {
    showSum: {
      type: Boolean,
      default: () => false
    },
    sumData: {
      type: Array,
      default: () => {

      }
    }
  }
}
</script>

<style lang="scss" scoped>
.summary-box-container {
  display: flex;
}
.title {
  font-weight: bold;
  margin-left: 5px;
}
.contain {
  display: flex;
  width: calc(100% - 42px);

  .data-item {
    // border-right: 1px solid #eaeaea;
    font-weight: bold;
    padding: 0 16px;
  }
  .data-item::after {
    content: '';
    top: 10px;
    left: 0px;
    width: 1px;
    background: #eaeaea;
    position: absolute;
    height: 40px;
  }

  .item-name {
    color: #1890ff;
  }
}
.el-tabs__item:first-child {
  .data-item {
    padding-left: 0 !important;
  }
  .data-item::after {
    content: '';
    top: 10px;
    left: 0px;
    width: 0px;
    background: #eaeaea;
    position: absolute;
    height: 40px;
  }
}
/deep/.el-tabs__item {
  font-size: 12px;
  line-height: 30px;
  height: 30px;
  padding: 0 !important;
}
/deep/.el-tabs__item.is-active {
  color: #333333;
}
/deep/.el-tabs__item:hover {
  color: #333333;
}
/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item {
  border: none;
  padding-left: 0 !important;
}
/deep/.el-tabs__nav-next {
  line-height: 70px;
  padding: 0 8px;
  font-size: 18px;
  box-shadow: -3px 4px 10px #ccc;
  color: #333;

  .el-icon-arrow-right {
    font-weight: 900;
  }
}
/deep/.el-tabs__nav-prev {
  line-height: 60px;
  padding: 0 8px;
  font-size: 18px;
  box-shadow: 1px 2px 10px #ccc;
  color: #333;

  .el-icon-arrow-left {
    font-weight: 900;
  }
}
/deep/.el-tabs__nav-next:hover {
  color: #1890ff !important;
}
/deep/.el-tabs__nav-prev:hover {
  color: #1890ff !important;
}
/deep/.el-tabs--card > .el-tabs__header {
  border: none;
}
/deep/ .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none;
}
/deep/.el-tabs__header {
  margin: 0 !important;
  padding: 0 !important;
}
/deep/ .el-tabs__nav-scroll {
  padding: 0px !important;
}
/deep/ .el-tabs__nav-wrap.is-scrollable {
    padding: 0 35px;
}
</style>
