<template>
  <section class="scroll-container--height"
           :class="{'scroll-container__menu': $slots.menu}" shadow="always">
    <slot></slot>

    <section class="scroll-container--menu" v-if="$slots.menu">
      <slot name="menu"></slot>
    </section>
  </section>
</template>

<script>
  import { updateMixin } from "@/mixins/global"
export default {
  mixins: [updateMixin],
    name: "scroll-container"
  }
</script>

<style scoped lang="scss">
  .scroll-container {
    &--height {
      // overflow-y: auto;
      // overflow: hidden;
      height: calc(100vh - 116px);
    }

    &__menu {
      height: calc(100vh - (104px + 73px));
    }

    &--menu {
      position: fixed;
      width: calc(100% - 260px);
      padding: 18px 10px;
      background: #fff;
      border-bottom-right-radius: 2px;
      border-bottom-right-radius: 2px;
      border-top: 1px solid #EBEEF5;
      z-index: 999;
    }
  }
</style>
