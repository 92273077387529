<template>
  <el-date-picker
    :size="size"
    :format="format"
    :valueFormat="valueFormat"
    :readonly="readonly"
    :placeholder="placeholderNew"
    :type="type"
    :startPlaceholder="startPlaceholderNew"
    :endPlaceholder="endPlaceholderNew"
    :prefixIcon="prefixIcon"
    :clearIcon="clearIcon"
    :name="name"
    :disabled="disabled"
    :clearable="clearable"
    :popperClass="popperClass"
    :id="id"
    :editable="editable"
    :align="align"
    :appendToBody="appendToBody"
    v-model="setValue"
    :defaultValue="defaultValue"
    :defaultTime="defaultTime"
    :rangeSeparator="rangeSeparator"
    :pickerOptions="pickerOptions"
    :unlinkPanels="unlinkPanels"
    :validateEvent="validateEvent"
    @blur="handleBlur"
    @focus="handleFocus"
    @change="handleChange"
  >
    <slot></slot>
    <template v-for="slotName in slotNames" :slot="slotName">
      <slot :name="slotName"></slot>
      <!-- 渲染原本的插槽内容 -->
    </template>
  </el-date-picker>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'my-date-picker',

  props: {
    appendToBody: true,
    size: String,
    format: String,
    valueFormat: String,
    readonly: Boolean,
    type: String,
    placeholder: String,
    startPlaceholder: String,
    endPlaceholder: String,
    prefixIcon: String,
    clearIcon: {
      type: String,
      default: 'el-icon-circle-close'
    },
    name: String,
    disabled: Boolean,
    clearable: {
      type: Boolean,
      default: true
    },
    id: [String, Number],
    popperClass: String,
    editable: {
      type: Boolean,
      default: true
    },
    align: {
      type: String,
      default: 'left'
    },
    value: {},
    defaultValue: {},
    defaultTime: {},
    rangeSeparator: {
      default: '-'
    },
    pickerOptions: {},
    unlinkPanels: Boolean,
    validateEvent: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      setValue: this.value
    }
  },
  watch: {
    setValue(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.setValue = val
    }
  },
  computed: {
    ...mapGetters(['globalReplaceTextList']),
    placeholderNew() {
      let placeholderNew = this.placeholder
      if (placeholderNew) {
        this.globalReplaceTextList.forEach((element) => {
          if (placeholderNew.indexOf(element.name) > -1) {
            placeholderNew = placeholderNew.replaceAll(
              element.name,
              element.value
            )
          }
        })
      }
      return placeholderNew
    },
    startPlaceholderNew() {
      let startPlaceholderNew = this.startPlaceholder
      if (startPlaceholderNew) {
        this.globalReplaceTextList.forEach((element) => {
          if (startPlaceholderNew.indexOf(element.name) > -1) {
            startPlaceholderNew = startPlaceholderNew.replaceAll(
              element.name,
              element.value
            )
          }
        })
      }
      return startPlaceholderNew
    },
    endPlaceholderNew() {
      let endPlaceholderNew = this.endPlaceholder
      if (endPlaceholderNew) {
        this.globalReplaceTextList.forEach((element) => {
          if (endPlaceholderNew.indexOf(element.name) > -1) {
            endPlaceholderNew = endPlaceholderNew.replaceAll(
              element.name,
              element.value
            )
          }
        })
      }
      return endPlaceholderNew
    },
    slotNames() {
      // 这里列出了 el-select 原本的插槽名称
      return ['prefix', 'suffix', 'range-separator']
    }
  },
  methods: {
    handleBlur(event) {
      this.$emit('blur', event)
    },
    handleFocus(event) {
      this.$emit('focus', event)
    },
    handleChange(value) {
      this.$nextTick(() => {
        this.$emit('change', value)
      })
    },
  }
}
</script>
