import http from '@/router/axios'
import { clipApiPrefix } from '@/config/env'

// 消息中心
const messageApi = {}

/**
 * @Description: 获取用户mqtt信息
 * @Param:
 */
messageApi.getMqttUserInfo = (object) => {
  return http.$GET(`${clipApiPrefix}/user/getMqttUserInfo`, {
    params: object
  })
}

/**
 * @Description: 获取用户未读消息条数
 * @Param:
 */
messageApi.getUnReadMsgCount = (object) => {
  return http.$POST(`${clipApiPrefix}/api/orgMessage/unReadNumber`, {
    params: object
  })
}

// 本机构消息分页查询
messageApi.pageOrgMessageList = (object) => {
  return http.$POST(`${clipApiPrefix}/api/orgMessage/pageOrgMessageList`, {
    showLoading: false,
    params: object
  })
}

// 公告消息分页查询
messageApi.pageNoticeByUser = (object) => {
  return http.$POST(`${clipApiPrefix}/api/notice/pageNoticeByUser`, {
    showLoading: false,
    params: object
  })
}

// 公告设置为已读
messageApi.readNotice = (object) => {
  return http.$POST(`${clipApiPrefix}/api/notice/readNotice`, {
    showLoading: true,
    params: object
  })
}

// 查公告详情
messageApi.getNoticeDetail = (object) => {
  return http.$POST(`${clipApiPrefix}/api/notice/detailById`, {
    showLoading: true,
    isParams: true,
    params: object
  })
}

// 公告删除用户
messageApi.deleteNoticeByUser = (object) => {
  return http.$POST(`${clipApiPrefix}/api/notice/deleteByUser`, {
    showLoading: true,
    params: object
  })
}

// 消息设置为已读
messageApi.readOrgMessage = (object) => {
  return http.$POST(`${clipApiPrefix}/api/orgMessage/readOrgMessage`, {
    showLoading: true,
    params: object
  })
}

// 查消息详情
messageApi.getMessageDetail = (object) => {
  return http.$POST(`${clipApiPrefix}/api/orgMessage/detailById`, {
    showLoading: true,
    isParams: true,
    params: object
  })
}

// 消息删除，支持多个
messageApi.deleteOrgMessage = (object) => {
  return http.$POST(`${clipApiPrefix}/api/orgMessage/deleteOrgMessage`, {
    showLoading: true,
    params: object
  })
}

// 设置已读所有消息
messageApi.readAllMessage = (object) => {
  return http.$POST(`${clipApiPrefix}/api/orgMessage/readAll`, {
    showLoading: true,
    params: object
  })
}

// 设置已读所有公告
messageApi.readAllNotice = (object) => {
  return http.$POST(`${clipApiPrefix}/api/notice/readAll`, {
    showLoading: true,
    params: object
  })
}

export default messageApi
