import store from '@/store/index'
import { validatenull } from "@util/validate"

export const buildLabelWidth = (widthStr) => {
  const width2 = widthStr.split('px')[0]
  let lableWidth = Number(width2)
  let fontSize = 1
  const fontConfig = store.getters.fontConfig
  if(!validatenull(fontConfig)) {
    fontSize = JSON.parse(fontConfig).fontSize
  }
  if(fontSize === 2) {
    lableWidth += 15
  }
  return lableWidth + 'px'
}
