import http from '@/router/axios'
import { clipApiPrefix } from '@/config/env'

const addressApi = {}

// 新增企业常用地址
addressApi.addCommonAddress = (object) => {
  return http.$POST(`${clipApiPrefix}/enterpriseAddressConfig/addEnterpriseAddressConfig`,
    {
      showLoading: true,
      params: object
    })
}

// 编辑企业常用地址
addressApi.editCommonAddress = (object) => {
  return http.$POST(`${clipApiPrefix}/enterpriseAddressConfig/modifyEnterpriseAddressBook`,
    {
      showLoading: true,
      params: object
    })
}

// 查国家列表
addressApi.queryAllCountry = (object) => {
  return http.$GET(`${clipApiPrefix}/enterpriseAddressConfig/queryAllCountry `,
    {
      showLoading: true,
      params: object
    })
}

// 分页查询企业常用地址
addressApi.pageAddress = (object) => {
  return http.$POST(`${clipApiPrefix}/enterpriseAddressConfig/queryPage`,
    {
      showLoading: false,
      params: object
    })
}

// 删除企业常用地址
addressApi.deleteCommonAddress = (object) => {
  return http.$POST(`${clipApiPrefix}/enterpriseAddressConfig/removeEnterpriseAddressBook`,
    {
      showLoading: true,
      params: object,
      isParams: true
    })
}

// 获取合作伙伴列表(不分页)
addressApi.queryPartnerList = (object) => {
  return http.$POST(`${clipApiPrefix}/api/console/basic/partner/queryPartnerList`,
    {
      showLoading: false,
      params: object
    })
}

export default addressApi