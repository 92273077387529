var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-cascader",
    {
      ref: "cascaderRef",
      attrs: {
        placeholder: _vm.placeholderNew,
        size: _vm.size,
        options: _vm.options,
        props: _vm.props,
        showAllLevels: _vm.showAllLevels,
        disabled: _vm.disabled,
        clearable: _vm.clearable
      },
      on: {
        blur: _vm.handleBlur,
        focus: _vm.handleFocus,
        change: _vm.handleChange,
        visibleChange: _vm.handleVisibleChange,
        removeTag: _vm.handleRemoveTag,
        clear: _vm.handleClear,
        expandChange: _vm.handleExpandChange
      },
      model: {
        value: _vm.selectedValue,
        callback: function($$v) {
          _vm.selectedValue = $$v
        },
        expression: "selectedValue"
      }
    },
    [
      _vm._t("default"),
      _vm._l(_vm.slotNames, function(slotName) {
        return _c("template", { slot: slotName }, [_vm._t(slotName)], 2)
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }