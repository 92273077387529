import http from '@/router/axios'
import { clipApiPrefix } from '@/config/env'

const dictionaryApi = {}

/**
 * @Description: 查询字典列表
 */
dictionaryApi.pageDict = (object) => {
  return http.$POST(`${clipApiPrefix}/dictCategory/page`,
    {
      showLoading: true,
      params: object
    })
}

/**
 * @Description: 新增字典
 */
 dictionaryApi.addDict = (object) => {
  return http.$POST(`${clipApiPrefix}/dictCategory/add`,
    {
      params: object
    })
}

/**
 * @Description: 修改字典
 */
 dictionaryApi.editDict = (object) => {
  return http.$POST(`${clipApiPrefix}/dictCategory/edit`,
    {
      params: object
    })
}

/**
 * @Description: 删除字典
 */
 dictionaryApi.deleteDict = (object) => {
  return http.$GET(`${clipApiPrefix}/dictCategory/delete`,
    {
      params: object
    })
}

/**
 * @Description: 根据字典ID查字典
 */
 dictionaryApi.getDictById = (object) => {
  return http.$GET(`${clipApiPrefix}/dictCategory/get`,
    {
      params: object
    })
}

/**
 * @Description: 查询字典项列表
 */
 dictionaryApi.pageDictItem = (object) => {
  return http.$POST(`${clipApiPrefix}/dict/page`,
    {
      showLoading: true,
      params: object
    })
}

/**
 * @Description: 新增字典项
 */
 dictionaryApi.addDictItem = (object) => {
  return http.$POST(`${clipApiPrefix}/dict/add`,
    {
      params: object
    })
}

/**
 * @Description: 修改字典项
 */
 dictionaryApi.editDictItem = (object) => {
  return http.$POST(`${clipApiPrefix}/dict/edit`,
    {
      params: object
    })
}

/**
 * @Description: 删除字典项
 */
 dictionaryApi.deleteDictItem = (object) => {
  return http.$GET(`${clipApiPrefix}/dict/delete`,
    {
      params: object
    })
}

/**
 * @Description: 根据字典项ID查字典项
 */
 dictionaryApi.getDictItemById = (object) => {
  return http.$GET(`${clipApiPrefix}/dict/get`,
    {
      params: object
    })
}

/**
 * @Description: 查询所有字典
 */
 dictionaryApi.getAllDict = (object) => {
  return http.$GET(`${clipApiPrefix}/dict/getAll`,
    {
      showLoading: true,
      params: object
    })
}

// 根据code查询字典
dictionaryApi.getDictByCode = (object) => {
  return http.$GET(`${clipApiPrefix}/dict/getByCategoryCode`,
    {
      showLoading: false,
      params: object
    })
}
export default dictionaryApi
