import Vue from 'vue'
import http from './router/axios'
import VueAxios from 'vue-axios'
import App from './App'
import router from './router/router'
import './router/permission' // 权限
import './cache' // 页面缓冲
import store from './store'
import * as urls from '@/config/env'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import tableSum from './views/components/tableSum.vue'
Vue.component('tableSum', tableSum)

import VPCJingPlugin from 'vpcjing-plugins'
import 'vpcjing-plugins/lib/index.css'
Vue.config.devtools = true

import Avue from '@smallwei/avue' // 引入avue
import '@smallwei/avue/lib/index.css'
Vue.use(Avue)
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

window.Vue= Vue
// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)
import Print from './util/print'
Vue.use(Print) // 注册

import website from '@/config/website'
import i18n from './lang' // Internationalization
import './styles/common.scss'

import '@/components/index'

import VTrackevent from '@/directive/umeng'
Vue.use(VTrackevent, {
  init() {
    window._czc = window._czc || [] // 挂载到window上
    const cz = document.createElement('script')
    cz.src = `https://s4.cnzz.com/z_stat.php?id=1281173155&web_id=1281173155` // 友盟统计应用id
    const s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(cz, s)
  },
  callback(data) {
    if (data instanceof Array && '_czc' in window) {
      window._czc.push(['_trackEvent'].concat(data)) // 上报信息
    }
  }
  // ,
  // debug: false
})

const vPackage = require('../package')

import { hasPermission, hasMenuPermission } from '@/config/permissionKey'
import {
  getEnumOnKey,
  getEnumLabelByVal,
  getEnumValByLabel,
  replaceEnumLabel
} from '@/config/enums'
import { validatenull, vaildData } from '@util/validate'
import { buildLabelWidth } from '@util/labelWidthUtil'
import { dateFormat } from '@util/date'
import {
  numberFormat,
  toFixed2,
  syncConfirm,
  transWeight,
  transVol,
  transVolumeSave,
  transWeightSave
} from './util/util'

require('promise.prototype.finally').shim()

Vue.use(router)
// Vue.use(VueAxios, http.axios)

Vue.use(Element, {
  size: 'small',
  zIndex: 3000,
  i18n: (key, value) => i18n.t(key, value)
})

Vue.use(VPCJingPlugin, {
  size: 'small',
  i18n: (key, value) => i18n.t(key, value)
})

import clearTabIndex from '@util/clearTabIndex.js'
clearTabIndex.install(Vue)

Object.keys(urls).forEach((key) => {
  Vue.prototype[key] = urls[key]
})

import { Message, MessageBox  } from 'element-ui'
// 定义一个新的Message方法
let messageInstance = null
const $message = (options) => {
  if (messageInstance) {
    // 判断是否有提示框，有则关闭
    messageInstance.close()
  }
  const replaceTextList = store.getters.globalReplaceTextList
  replaceTextList.forEach(element => {
    if (options.message.indexOf(element.name) > -1) {
      options.message = options.message.replaceAll(
        element.name,
        element.value
      )
    }
  })
  messageInstance = Message({
    ...options,
    showClose: true
  })
  return messageInstance
}

// 重写方法
['success', 'warning', 'info', 'error'].forEach((type) => {
  $message[type] = (options) => {
    if (typeof options === 'string') {
      options = {
        message: options
      }
    }
    options.type = type
    return Message(options)
  }
})

export function MyConfirm(msg, title='提示', next) {
  const replaceTextList = store.getters.globalReplaceTextList
  replaceTextList.forEach(element => {
    if (msg.indexOf(element.name) > -1) {
      msg = msg.replaceAll(
        element.name,
        element.value
      )
    }
  })
  return MessageBox.confirm(msg, title, next)
}

// 自定义指令-判断是选中文字还是点击
// Vue.directive('tclick', (el, binding) => {
//   let downTime = null
//   let upTime = null
//   debugger
//   el.addEventListener('mousedown', () => {
//     downTime = new Date().getTime()
//   })
//   el.addEventListener('mouseup', () => {
//     upTime = new Date().getTime()
//     const selectionTxt = window.getSelection().toString().trim()
//     if (upTime - downTime < 200 || selectionTxt.length === 0) {
//       el.setAttribute('data-isClick', true) // 点击事件
//     } else {
//       el.setAttribute('data-isClick', false) // 文本选中
//     }
//   })
// })

// 将$message挂载到this上
Vue.prototype.$message = $message
Vue.prototype.$syncConfirm = syncConfirm
Vue.prototype.$confirm = MyConfirm
Vue.prototype.$message.closeAll = Message.closeAll

Vue.prototype.$GET = http.$GET
Vue.prototype.$PATCH = http.$PATCH
Vue.prototype.$POST = http.$POST
Vue.prototype.$PUT = http.$PUT
Vue.prototype.$DELETE = http.$REMOVE
Vue.prototype.$Axios = http.axios
Vue.prototype.$HasPermission = hasPermission
Vue.prototype.$HasMenuPermission = hasMenuPermission
Vue.prototype.$BuildLabelWidth = buildLabelWidth
Vue.prototype.$GetEnumOnKey = getEnumOnKey
Vue.prototype.$GetEnumLabelByVal = getEnumLabelByVal
Vue.prototype.$GetEnumValByLabel = getEnumValByLabel
Vue.prototype.$ReplaceEnumLabel = replaceEnumLabel
Vue.prototype.$duration = website.duration
Vue.prototype.$InputNumberConfig = website.inputNumberConfig
Vue.prototype.$NavigateUri = website.navigateUri
Vue.prototype.validatenull = validatenull
Vue.prototype.$VaildData = vaildData
Vue.prototype.$DateFormat = dateFormat
Vue.prototype.$NumberFormat = numberFormat
Vue.prototype.$toFixed2 = toFixed2
Vue.prototype.$transWeight = transWeight
Vue.prototype.$transVol = transVol
Vue.prototype.$transVolumeSave = transVolumeSave
Vue.prototype.$transWeightSave = transWeightSave

Vue.config.productionTip = true

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  created() {
    this.$nextTick(() => {
      console.info(
        `%c ${vPackage.name} %c V.${vPackage.version} %c`,
        'background:#35495E; padding: 1px; border-radius: 3px 0 0 3px; color: #fff;',
        `background:#3488ff; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff;`,
        'background:transparent'
      )
    })
  }
}).$mount('#app')
