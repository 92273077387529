import http from '@/router/axios'
import { clipApiPrefix } from '@/config/env'

const partnerApi = {}

/**
 * @Description: 查询合作伙伴列表
 */
partnerApi.pagePartner = (object) => {
  return http.$POST(`${clipApiPrefix}/api/console/basic/partner/queryPartnerPage`,
    {
      showLoading: false,
      params: object
    })
}

/**
 * @Description: 新增合作伙伴
 */
partnerApi.addPartner = (object) => {
  return http.$POST(`${clipApiPrefix}/api/console/basic/partner/addPartner`,
    {
      showLoading: true,
      params: object
    })
}

/**
 * @Description: 修改合作伙伴
 */
partnerApi.editPartner = (object) => {
  return http.$POST(`${clipApiPrefix}/api/console/basic/partner/editPartner`,
    {
      showLoading: true,
      params: object
    })
}

/**
 * @Description: 用户列表(精确匹配)
 */
partnerApi.pageUserPrecise = (object) => {
  return http.$POST(`${clipApiPrefix}/user/pageByPrecise`,
    {
      params: object
    })
}

/**
 * @Description: 设置司机默认收款人信息
 */
partnerApi.chauffeurSetDefaultPayee = (object) => {
  return http.$POST(`${clipApiPrefix}/chauffeur/v2/setDefaultPayee`,
    {
      params: object
    })
}

/**
 * @Description: 用户列表
 */
partnerApi.pageUser = (object) => {
  return http.$POST(`${clipApiPrefix}/user/page`,
    {
      params: object
    })
}

/**
 * @Description: 企业列表(精确匹配)
 */
partnerApi.pageEnterprisePrecise = (object) => {
  return http.$POST(`${clipApiPrefix}/enterprise/pageEnterprisePrecise`,
    {
      params: object
    })
}

/**
 * @Description: 企业列表
 */
partnerApi.pageEnterprise = (object) => {
  return http.$POST(`${clipApiPrefix}/enterprise/pageEnterprise`,
    {
      params: object
    })
}

/**
 * @Description: 确认合作关系
 */
partnerApi.confirmPartner = (object) => {
  return http.$POST(`${clipApiPrefix}/api/console/basic/partner/confirmPartner`,
    {
      showLoading: true,
      params: object
    })
}

/**
 * @Description: 修改绑定关系
 */
partnerApi.modifyBindRelation = (object) => {
  return http.$POST(`${clipApiPrefix}/api/console/basic/partner/modifyBindRelation`,
    {
      showLoading: true,
      params: object
    })
}

/**
 * @Description: 获取合作伙伴邀请信息
 */
partnerApi.getPartnerInviteInfo = (object) => {
  return http.$POST(`${clipApiPrefix}/api/console/basic/partner/getPartnerInviteInfo`,
    {
      params: object
    })
}

/**
 * @Description: 删除合作伙伴
 */
partnerApi.deletePartner = (object) => {
  return http.$POST(`${clipApiPrefix}/api/console/basic/partner/deletePartner`,
    {
      isParams: true,
      params: object
    })
}

/**
 * @Description: 查询合作伙伴列表
 */
partnerApi.queryPartnerList = (object) => {
  return http.$POST(`${clipApiPrefix}/api/console/basic/partner/queryPartnerList`,
    {
      params: object
    })
}

/**
 * @Description: 分页查询物流公社联盟企业
 */
partnerApi.queryEnterpriseAlliancePage = (object) => {
  return http.$POST(`${clipApiPrefix}/enterprise/queryEnterpriseAlliancePage`,
    {
      showLoading: true,
      params: object
    })
}

/**
 * @Description: 查询司机列表
 */
partnerApi.queryChauffeurBelongPage = (object) => {
  return http.$POST(`${clipApiPrefix}/chauffeur/queryChauffeurBelongPage`,
    {
      params: object
    })
}

/**
 * @Description: 查询企业审核列表
 */
partnerApi.enterpriseAuditList = (object) => {
  return http.$POST(`${clipApiPrefix}/enterprise/pageIninviterEnterprise`,
    {
      showLoading: false,
      params: object
    })
}

/**
 * @Description: 企业审核
 */
partnerApi.vehicleBelongAudit = (object) => {
  return http.$POST(`${clipApiPrefix}/enterprise/enterpriseAuditState`,
    {
      params: object,
      isParams: true
    })
}

/**
 * @Description: 修改合作伙伴冻结状态
 */
partnerApi.editPartnerProperties = (object) => {
  return http.$POST(`${clipApiPrefix}/api/console/basic/partner/editPartnerProperties`,
    {
      params: object
    })
}

/**
 * @Description: 查询计划货物批次号数据
 */
partnerApi.queryCountByBatchNumber = (object) => {
  return http.$GET(`${clipApiPrefix}/indentPlan/queryCountByBatchNumber`,
    {
      params: object,
      isParams: true
    })
}
 
export default partnerApi
