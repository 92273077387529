<template>
  <section class="border-box-3">
    <section class="b3-header">
      <span class="title" v-if="title" v-html="title"></span>
      <slot name="menu"></slot>
    </section>

    <section class="b3-content"
             :class="[
              padding ? '' : 'p0'
             ]">
      <slot></slot>
    </section>
  </section>
</template>

<script>
import { updateMixin } from "@/mixins/global"
export default {
  mixins: [updateMixin],
    name: 'border-box-3',
    props: {
      title: {
        type: String
      },
      padding: {
        type: Boolean,
        default: true
      }
    }
  }
</script>

<style scoped lang="scss">
  .border-box-3 {
    .b3-header {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #F2F6FC;
      padding-bottom: 3px;

      .title {
        color: #303133;
        font-size: 17px !important;
        font-weight: 700;
        margin-bottom: 20px;
      }
    }

    .b3-content {
      margin-top: 20px;
    }
  }
</style>