<template>
  <section class="border-box-11">
    <div class="border-box-11__title" @click.prevent="toggleUp">
      <div class="title-color">
        <i
          :class="isUp ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
          class="toggle-icon mr10"
        ></i
        >{{ title }}
        <slot name="headerLeftMenu" v-if="toggleWithLeftMenu ? isUp : true"></slot>
      </div>
      <section v-if="$slots.headerMenu">
        <slot name="headerMenu"></slot>
      </section>
    </div>
    <div class="line" v-show="!isUp" v-if="isNeedLine"></div>
    <div class="border-box-11__context" v-show="!isUp">
      <slot></slot>
    </div>
  </section>
</template>

<script>
import { updateMixin } from "@/mixins/global"
export default {
  mixins: [updateMixin],
  name: 'border-box-11',
  props: {
    title: {
      type: String
    },
    toggleWithLeftMenu:{
      type: Boolean,
      default: false,
    },
    isNeedLine: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },
  data() {
    return {
      isUp: false // 是否收起内容
    }
  },
  methods: {
    toggleClose() {
      this.isUp = true
    },
    toggleOpen() {
      this.isUp = false
    },
    toggleUp() {
      this.isUp = !this.isUp
    }
  }
}
</script>

<style scoped lang="scss">
.border-box-11 {
  background: #fff;
  border-radius: 8px;
}
.title-color {
  color: #1890FF;
}
.toggle-icon {
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  margin-right: 4px;
}
.border-box-11__title {
  border-radius: 4px 4px 0 0;
  background: #DEEBF7;
  display: flex;
  cursor: pointer;
  padding: 6px 12px;
  align-items: center;
  justify-content: space-between;
}
.line {
  border-top: 1px solid #f4f4f4;
  padding-bottom: 8px;
}
.border-box-11__context {
  overflow: hidden;
  padding: 0px 12px;
}
</style>
