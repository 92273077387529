import {
  validatenull,
  validatePhone,
  validateMobile,
  validateEmail
} from './validate'
import Cookies from 'js-cookie'
import http from '@/router/axios'
import { MessageBox } from 'element-ui'


export const syncConfirm = async (message, title = "提示") => {
  try {
    await new Promise((resolve, reject) => {
      MessageBox.confirm(message, title, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
        closeOnPressEscape: false,
        showClose: false,
        callback: (action, instance) => {
          if (action === "confirm") {
            resolve();
          } else {
            reject();
          }
        },
      });
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const isShowElement = (element) => {
  const listStorage = localStorage.getItem('webElementIsShow')
  if(listStorage) {
    const list = JSON.parse(listStorage)
    const isShow = list[element]
    // console.log(element, isShow);
    return isShow === 'YES'
  } else {
    return false
  }
}

/**
 * 天行健系统登录
 * @param uri
 */
export const txjLogin = () => {
  if (validatenull(localStorage.getItem('TXJACCOUNT')) || validatenull(localStorage.getItem('TXJPWD'))) {
    MessageBox.alert('请先前往运营平台设置车联网账号密码', '提示', {
      confirmButtonText: '确定',
      type: 'warning',
      callback: () => {

      }
    })
    return
  }
  const params = {
    username: localStorage.getItem('TXJACCOUNT'),
    password: localStorage.getItem('TXJPWD')
  }
  return new Promise((resolve) => {
    http
      .$POST('https://bc.sqtxj.com/stage-api/fleet-setting/loginNoCode', {
        showLoading: true,
        params
      })
      .then(function (response) {
        Cookies.set('Admin-Token', response.token)
        localStorage.setItem('token', response.txj_token)
        resolve()
      })
  })
}

/**
 * 判断浏览器
 */
export const isNeedUpgradeBrowser = () => {
  const ua = navigator.userAgent.toLocaleLowerCase()
  let isNeed = false
  let type = 'chrome'
  // if (ua.match(/msie/) != null || ua.match(/trident/) != null) {
  //     browserType = "IE"
  // const browserVersion = ua.match(/msie ([\d.]+)/) != null ? ua.match(/msie ([\d.]+)/)[1] : ua.match(/rv:([\d.]+)/)[1]
  // }
  if (ua.match(/chrome/) != null) {
    const browserVersion = ua.match(/chrome\/([\d.]+)/)[1]
    const bigVer = browserVersion.split('.')[0]
    if (Number(bigVer) < 95) {
      isNeed = true
    }
  } else if (ua.match(/firefox/) != null) {
    isNeed = true
  } else if (ua.match(/safari/) != null) {
    type = 'safari'
    isNeed = true
  } else if (ua.match(/msie/) != null || ua.match(/trident/) != null) {
    isNeed = true
  }
  return {
    isNeed,
    type
  }
}

export const chauffeurWarning = (vue, name = '', callback, type = 'select') => {
  const tVue = vue
  const h = tVue.$createElement

  tVue.$msgbox({
    title: '警告提醒',
    type: 'warning',
    showCancelButton: true,
    showClose: false,
    closeOnPressEscape: false,
    closeOnClickModal: false,
    confirmButtonText: tVue.$t('Button.confirm'),
    cancelButtonText: tVue.$t('Button.cancel'),
    message: h(
      'div',
      {
        style: {
          color: '#F56C6C'
        }
      },
      [
        h('span', null, `您${type === 'select' ? '选择' : '引入'}的驾驶员`),
        validatenull(name) ? '' : h('span', null, `【${name}】`),
        h(
          'span',
          null,
          `信息未通过平台审核，由此带来的业务风险请自行承担，与平台无关。是否确定${type === 'select' ? '选择' : '引入'
          }该驾驶员？`
        )
      ]
    ),
    callback: (action) => {
      callback(action)
    }
  })
}

export const chauffeurWarning2 = (
  vue,
  name = '',
  callback,
  type = 'select'
) => {
  const tVue = vue
  const h = tVue.$createElement

  tVue.$msgbox({
    title: '警告提醒',
    type: 'warning',
    showCancelButton: true,
    showClose: false,
    closeOnPressEscape: false,
    closeOnClickModal: false,
    confirmButtonText: tVue.$t('Button.confirm'),
    cancelButtonText: tVue.$t('Button.cancel'),
    message: h(
      'div',
      {
        style: {
          color: '#F56C6C'
        }
      },
      [
        h('span', null, `您${type === 'select' ? '选择' : '引入'}的驾驶员`),
        validatenull(name) ? '' : h('span', null, `【${name}】`),
        h(
          'span',
          null,
          `未通过审核，是否确定${type === 'select' ? '选择' : '引入'}该驾驶员？`
        )
      ]
    ),
    callback: (action) => {
      callback(action)
    }
  })
}

export const vehicleWarning1 = (vue, name = '', callback, type = 'select') => {
  const tVue = vue
  const h = tVue.$createElement

  tVue.$msgbox({
    title: '警告提醒',
    type: 'warning',
    showCancelButton: true,
    showClose: false,
    closeOnPressEscape: false,
    closeOnClickModal: false,
    confirmButtonText: tVue.$t('Button.confirm'),
    cancelButtonText: tVue.$t('Button.cancel'),
    message: h(
      'div',
      {
        style: {
          color: '#F56C6C'
        }
      },
      [
        h('span', null, `您${type === 'select' ? '选择' : '引入'}的载具`),
        validatenull(name)
          ? ''
          : h(
            'span',
            {
              style: {
                color: '#F56C6C'
              }
            },
            `【${name}】`
          ),
        h(
          'span',
          null,
          `未通过审核，由此带来的业务风险请自行承担，与平台无关。是否确定${type === 'select' ? '选择' : '引入'
          }该载具？`
        )
      ]
    ),
    callback: (action) => {
      callback(action)
    }
  })
}

export const vehicleWarning2 = (vue, name = '', callback, type = 'select') => {
  const tVue = vue
  const h = tVue.$createElement

  tVue.$msgbox({
    title: '警告提醒',
    type: 'warning',
    showCancelButton: true,
    showClose: false,
    closeOnPressEscape: false,
    closeOnClickModal: false,
    confirmButtonText: tVue.$t('Button.confirm'),
    cancelButtonText: tVue.$t('Button.cancel'),
    message: h(
      'div',
      {
        style: {
          color: '#F56C6C'
        }
      },
      [
        h('span', null, `您${type === 'select' ? '选择' : '引入'}的载具`),
        validatenull(name)
          ? ''
          : h(
            'span',
            {
              style: {
                color: '#F56C6C'
              }
            },
            `【${name}】`
          ),
        h(
          'span',
          null,
          `未通过审核，是否确定${type === 'select' ? '选择' : '引入'}该载具？`
        )
      ]
    ),
    callback: (action) => {
      callback(action)
    }
  })
}

export const formatName = (name) => {
  let newStr
  if (name.length === 2) {
    newStr = name.substr(0, 1) + '*'
  } else if (name.length > 2) {
    let char = ''
    for (let i = 0, len = name.length - 2; i < len; i++) {
      char += '*'
    }
    newStr = name.substr(0, 1) + char + name.substr(-1, 1)
  } else {
    newStr = name
  }

  return newStr
}

export const checkUserName = (rule, value, callback) => {
  if (validatenull(value)) {
    return callback(new Error(`请输入用户名`))
  }

  if (!/^\w+$/.test(value)) {
    return callback(new Error('只能输入字母、数字和下划线'))
  }

  return callback()
}

export const checkMobile = (rule, value, callback) => {
  const ret = validatePhone(value)
  if (!ret.flag) return callback(new Error(ret.msg))

  return callback()
}


export const checkPhoneNotReqiured = (rule, value, callback) => {
  if (value) {
    const ret = validateMobile(value)
    if (!ret.flag) return callback(new Error(ret.msg))
  }
  return callback()
}

export const checkEmail = (rule, value, callback) => {
  const ret = validateEmail(value)
  if (!ret.flag) return callback(new Error(ret.msg))

  return callback()
}

export const submitRequest = (request) => {
  return new Promise((resolve, reject) => {
    request
      .then((ret) => {
        resolve(ret)
      })
      .catch(() => {
        reject()
      })
  })
}

export const formatFloat = (num, n) => {
  let f = parseFloat(num)
  if (isNaN(f)) return false

  f = Math.round(num * Math.pow(10, n)) / Math.pow(10, n) // n 幂

  let s = f.toString()
  let rs = s.indexOf('.')

  // 判定如果是整数，增加小数点再补0
  if (rs < 0) {
    rs = s.length
    s += '.'
  }

  while (s.length <= rs + n) {
    s += '0'
  }

  return s
}

// 不四舍五入
export const formatDecimal = (num, decimal) => {
  num = num.toString()
  const index = num.indexOf('.')

  if (index !== -1) {
    num = num.substring(0, decimal + index + 1)
  } else {
    num = num.substring(0)
  }
  return parseFloat(num).toFixed(decimal)
}

export const moneyConversion = (value, type = 'yConvertFen') => {
  if (validatenull(value)) return ''

  if (type === 'yConvertFen') {
    return Number(formatFloat(Number(value) * 100, 0))
  } else if (type === 'fenConvertY') {
    return Number(formatFloat(Number(value) / 100, 2))
  }
}

// 重量单位换算
export const weightConversion = (value, type = 'tConvertKg') => {
  if (validatenull(value)) return ''

  if (type === 'tConvertKg') {
    return Number(formatFloat(Number(value) * 1000, 2))
  } else if (type === 'kgConvertT') {
    return Number(formatFloat(Number(value) / 1000, 2))
  }
}

// 价格换算
export const priceConversion = (value, type = 'tConvertKg') => {
  if (validatenull(value)) return ''

  if (type === 'tConvertKg') {
    return Number(formatFloat(Number(value) / 1000, 2))
  } else if (type === 'kgConvertT') {
    return Number(formatFloat(Number(value) * 1000, 2))
  }
}

/**
 * 线性数组转树形
 * @param root
 * @param pKey
 * @param pId
 * @param id
 * @returns {[]|*[]}
 */
export const listConvertTree = (
  root,
  pKey = 'parentId',
  pId = -1,
  id = 'id'
) => {
  const retArray = []

  if (validatenull(root)) return []

  if (root) {
    root.forEach((item) => {
      if (item[pKey] === pId) {
        const o = {}

        for (const key in item) {
          o[key] = item[key]
        }

        o.children = validatenull(o.children)
          ? listConvertTree(root, pKey, item[id])
          : o.children.concat(listConvertTree(root, pKey, item[id]))

        retArray.push(o)
      }
    })
  }

  return retArray
}

export const uuid = () => {
  const s = []
  const hexDigits = '0123456789abcdef'

  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-'

  const uuid = s.join('')

  return uuid
}

export const guid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8

    return v.toString(16)
  })
}

/**
 * @Description: 格式化菜单
 */
export const formatMenu = (list, parent = -1) => {
  const temp = []
  if (validatenull(list)) return []

  list.forEach((item) => {
    if (item.parentId === parent) {
      const tempRouter = {
        label: item.menuName,
        path: `${item.menuUrl}`,
        icon: item.icon,
        component:
          item.component === 'Layout' || validatenull(item.component)
            ? ''
            : `${item.component}`,
        meta: {
          keepAlive: item.keepAlive,
          isAuth: item.isAuth,
          id: item.id,
          isShowMain: validatenull(item.isShowMain) ? true : item.isShowMain,
          parentId: item.parentId
        },
        children: formatMenu(list, item.id)
      }

      temp.push(tempRouter)
    }
  })

  return temp
}

export const getViewDom = () => {
  return window.document
    .getElementById('avue-view')
    .getElementsByClassName('el-scrollbar__wrap')[0]
}

const getObjType = (obj) => {
  const toString = Object.prototype.toString
  const map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  }
  if (obj instanceof Element) {
    return 'element'
  }
  return map[toString.call(obj)]
}
/**
 * 对象深拷贝
 */
export const deepClone = (data) => {
  const type = getObjType(data)
  let obj
  if (type === 'array') {
    obj = []
  } else if (type === 'object') {
    obj = {}
  } else {
    // 不再具有下一层次
    return data
  }
  if (type === 'array') {
    for (let i = 0, len = data.length; i < len; i++) {
      obj.push(deepClone(data[i]))
    }
  } else if (type === 'object') {
    for (const key in data) {
      obj[key] = deepClone(data[key])
    }
  }
  return obj
}

/**
 * 设置灰度模式
 */
export const toggleGrayMode = (status) => {
  if (status) {
    document.body.className = document.body.className + ' grayMode'
  } else {
    document.body.className = document.body.className.replace(' grayMode', '')
  }
}
/**
 * 设置主题
 */
export const setTheme = (name) => {
  if (name) {
    document.body.classList.remove('theme-blue')
    document.body.classList.remove('theme-red')
    document.body.classList.add(name)
  } else {
    document.body.classList.remove(name)
  }
}

/**
 * 加密处理
 */
export const encryption = (params) => {
  const { data, type, param, key } = params
  const result = JSON.parse(JSON.stringify(data))
  if (type === 'Base64') {
    param.forEach((ele) => {
      result[ele] = btoa(result[ele])
    })
  } else if (type === 'Aes') {
    param.forEach((ele) => {
      result[ele] = window.CryptoJS.AES.encrypt(result[ele], key).toString()
    })
  }
  return result
}

/**
 * 浏览器判断是否全屏
 */
export const fullscreenToggel = () => {
  if (fullscreenEnable()) {
    exitFullScreen()
  } else {
    reqFullScreen()
  }
}
/**
 * esc监听全屏
 */
export const listenfullscreen = (callback) => {
  function listen() {
    callback()
  }

  document.addEventListener('fullscreenchange', function() {
    listen()
  })
  document.addEventListener('mozfullscreenchange', function() {
    listen()
  })
  document.addEventListener('webkitfullscreenchange', function() {
    listen()
  })
  document.addEventListener('msfullscreenchange', function() {
    listen()
  })
}
/**
 * 浏览器判断是否全屏
 */
export const fullscreenEnable = () => {
  const isFullscreen =
    document.isFullScreen ||
    document.mozIsFullScreen ||
    document.webkitIsFullScreen
  return isFullscreen
}

/**
 * 浏览器全屏
 */
export const reqFullScreen = () => {
  if (document.documentElement.requestFullScreen) {
    document.documentElement.requestFullScreen()
  } else if (document.documentElement.webkitRequestFullScreen) {
    document.documentElement.webkitRequestFullScreen()
  } else if (document.documentElement.mozRequestFullScreen) {
    document.documentElement.mozRequestFullScreen()
  }
}
/**
 * 浏览器退出全屏
 */
export const exitFullScreen = () => {
  if (document.documentElement.requestFullScreen) {
    document.exitFullScreen()
  } else if (document.documentElement.webkitRequestFullScreen) {
    document.webkitCancelFullScreen()
  } else if (document.documentElement.mozRequestFullScreen) {
    document.mozCancelFullScreen()
  }
}

/**
 * 动态插入css
 */
export const loadStyle = (url) => {
  const link = document.createElement('link')
  link.type = 'text/css'
  link.rel = 'stylesheet'
  link.href = url
  const head = document.getElementsByTagName('head')[0]

  head.appendChild(link)
}

/**
 * 判断路由是否相等
 */
export const diff = (obj1, obj2) => {
  delete obj1.close

  const o1 = obj1 instanceof Object
  const o2 = obj2 instanceof Object
  if (!o1 || !o2) {
    /*  判断不是对象  */
    return obj1 === obj2
  }

  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false
    // Object.keys() 返回一个由对象的自身可枚举属性(key值)组成的数组,例如：数组返回下表：let arr = ["a", "b", "c"];console.log(Object.keys(arr))->0,1,2;
  }

  for (const attr in obj1) {
    const t1 = obj1[attr] instanceof Object
    const t2 = obj2[attr] instanceof Object
    if (t1 && t2) {
      return diff(obj1[attr], obj2[attr])
    } else if (obj1[attr] !== obj2[attr]) {
      return false
    }
  }
  return true
}

/**
 * 生成随机len位数字
 */
export const randomLenNum = (len, date) => {
  let random = ''
  random = Math.ceil(Math.random() * 10000000000000000)
    .toString()
    .substr(0, len ? len : 4)
  if (date) random = random + Date.now()
  return random
}
/**
 * 打开小窗口
 */
export const openWindow = (url, title, w, h) => {
  // Fixes dual-screen position                            Most browsers       Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : screen.left
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : screen.top

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height

  const left = width / 2 - w / 2 + dualScreenLeft
  const top = height / 2 - h / 2 + dualScreenTop
  const newWindow = window.open(
    url,
    title,
    'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=' +
    w +
    ', height=' +
    h +
    ', top=' +
    top +
    ', left=' +
    left
  )

  // Puts focus on the newWindow
  if (window.focus) {
    newWindow.focus()
  }
}

/**
 * 字符替换
 * @param input
 * @param frontLen
 * @param endLen
 * @param flagLen
 * @param flag
 * @returns {string}
 */
export const characterReplace = (
  input,
  frontLen,
  endLen,
  flagLen = 0,
  flag = '*'
) => {
  if (validatenull(input)) return ''

  const len = flagLen === 0 ? input.length - frontLen - endLen : flagLen
  let temp = ''

  for (let i = 0; i < len; i++) {
    temp += flag
  }

  return (
    input.substring(0, frontLen) + temp + input.substring(input.length - endLen)
  )
}

/**
 * 数组追加字段
 * @param list
 * @param field
 * @param value
 * @returns {*}
 */
export const arrayAppendField = (list, field, value) => {
  if (validatenull(list)) return list
  if (validatenull(field)) return list
  if (validatenull(value)) return list

  list.forEach((ele) => {
    ele[field] = value

    if (!validatenull(ele.children)) {
      arrayAppendField(ele.children, field, value)
    }
  })

  return list
}

/**
 * 格式化数据
 * @param number
 * @param decimals
 * @param dec_point
 * @param thousands_sep
 * @returns {string}
 */
export const numberFormat = (
  number,
  decimals = 2,
  dec_point,
  thousands_sep
) => {
  /*
   * 参数说明：
   * number：要格式化的数字
   * decimals：保留几位小数
   * dec_point：小数点符号
   * thousands_sep：千分位符号
   * */
  number = (number + '').replace(/[^0-9+-Ee.]/g, '')

  const n = !isFinite(+number) ? 0 : +number
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
  const sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep
  const dec = typeof dec_point === 'undefined' ? '.' : dec_point

  const toFixedFix = function(n, prec) {
    const k = Math.pow(10, prec)

    return '' + Math.round(n * k) / k
  }

  let s = ''
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
  const re = /(-?\d+)(\d{3})/

  while (re.test(s[0])) {
    s[0] = s[0].replace(re, '$1' + sep + '$2')
  }

  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }

  return s.join(dec)
}

/**
 * 四舍五入 保留2位小数
 * @param number
 * @param decimals
 * @returns {number}
 */
export const numberRound = (number, decimals = 2) => {
  if (validatenull(number)) return 0

  switch (decimals) {
    case 2: {
      return Number((Math.round(Number(number) * 100) / 100).toFixed(decimals))
    }
    case 3: {
      return Number(
        (Math.round(Number(number) * 1000) / 1000).toFixed(decimals)
      )
    }
  }
}

/**
 *  table合并行通用 */
export const mergeTableRow = (data, merge) => {
  if (!merge || merge.length === 0) {
    return data
  }

  merge.forEach((m) => {
    const mList = {}
    data = data.map((v, index) => {
      const rowVal = v[m]
      if (mList[rowVal]) {
        mList[rowVal]++
        data[index - (mList[rowVal] - 1)][m + '-span'].rowspan++
        v[m + '-span'] = {
          rowspan: 0,
          colspan: 0
        }
      } else {
        mList[rowVal] = 1
        v[m + '-span'] = {
          rowspan: 1,
          colspan: 1
        }
      }
      return v
    })
  })

  return data
}

/**
 *  table合并行通用 */
export const mergeRowByKey = (data, merge = 'key') => {
  let keyPos = 0
  const keyArray = []

  for (let i = 0; i < data.length; i += 1) {
    if (i === 0) {
      // 第一行必须存在
      keyArray.push(1)
      keyPos = 0
    } else {
      // 判断当前元素与上一个元素是否相同,eg：this.typeNamePos 是 this.typeNameArr序号
      // 第一列
      // eslint-disable-next-line no-lonely-if
      if (data[i][merge] === data[i - 1][merge]) {
        keyArray[keyPos] += 1
        keyArray.push(0)
      } else {
        keyArray.push(1)
        keyPos = i
      }
    }
  }

  return keyArray
}

/**
 * 根据经纬度计算两点之间的角度
 * @param lngA
 * @param latA
 * @param lngB
 * @param latB
 * @returns {number}
 */
export const calcLngLatAngle = (lngA, latA, lngB, latB) => {
  const a = ((90 - latB) * Math.PI) / 180
  const b = ((90 - latA) * Math.PI) / 180
  const AOC_BOC = ((lngB - lngA) * Math.PI) / 180
  const cosc =
    Math.cos(a) * Math.cos(b) + Math.sin(a) * Math.sin(b) * Math.cos(AOC_BOC)
  const sinc = Math.sqrt(1 - cosc * cosc)
  const sinA = (Math.sin(a) * Math.sin(AOC_BOC)) / sinc
  const A = (Math.asin(sinA) * 180) / Math.PI
  let res = 0

  if (lngB > lngA && latB > latA) {
    res = A
  } else if (lngB > lngA && latB < latA) {
    res = 180 - A
  } else if (lngB < lngA && latB < latA) {
    res = 180 - A
  } else if (lngB < lngA && latB > latA) {
    res = 360 + A
  } else if (lngB > lngA && latB === latA) {
    res = 90
  } else if (lngB < lngA && latB === latA) {
    res = 270
  } else if (lngB === lngA && latB > latA) {
    res = 0
  } else if (lngB === lngA && latB < latA) res = 180

  return res
}

import { currentDate } from '@util/date'

/**
 * 下载文件流
 * @param content
 * @param fileName
 * @param suffix
 */
export const downloadFileStream = (result, fileName, suffix = 'xls') => {
  const blob = new Blob([result])
  fileName = `${fileName}-${currentDate('yyyyMMddhhmmss')}.${suffix}`
  // fileName = `${fileName}.${suffix}`

  // 判断浏览器
  let brower = ''
  if (navigator.userAgent.indexOf('Edge') > -1) {
    brower = 'Edge'
  }

  if ('download' in document.createElement('a')) {
    // 非IE下载
    if (brower === 'Edge') {
      navigator.msSaveBlob(blob, fileName)
      return
    }
    const elink = document.createElement('a')
    elink.download = fileName
    elink.style.display = 'none'
    elink.href = URL.createObjectURL(blob)
    document.body.appendChild(elink)
    elink.click()
    URL.revokeObjectURL(elink.href)
    // 释放URL 对象
    document.body.removeChild(elink)
  } else {
    // IE10+下载
    navigator.msSaveBlob(blob, fileName)
  }
}

/**
 * 下载文件
 * @param uri
 */
export const downloadFile = (uri, filename, suffix = 'xls') => {
  // const blob = new Blob([uri])
  filename = `${filename}.${suffix}`
  const elink = document.createElement('a')
  elink.download = filename
  elink.style.display = 'none'
  elink.href = URL.createObjectURL(uri)
  document.body.appendChild(elink)
  elink.click()

  // 释放URL 对象
  document.body.removeChild(elink)
  URL.revokeObjectURL(elink.href)
  // const _filename = filename || uri.substr(uri.lastIndexOf('/') + 1)
  // const element = document.createElement('a')
  // element.setAttribute('download', filename)
  // element.setAttribute('href', uri)
  // element.setAttribute('target', '_blank')
  // element.style.display = 'none'
  // document.body.appendChild(element)
  // element.click()
  // document.body.removeChild(element)
}

/**
 * 下载pdf文件
 * @param uri
 */
 export const downloadFilePdf = (uri, filename) => {
  http.axios({
    method: 'get',
    url: uri,
    responseType: 'blob'
  }).then(function(response) {
      const link = document.createElement('a')
      const blob = new Blob([response], {type: response.type})
      const url = URL.createObjectURL(blob)
      link.href = url
      link.download = filename + '.pdf'
      link.click()
  })
 }

export const getBlob = (url) => {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest()

    xhr.open('GET', url, true)
    xhr.responseType = 'blob'
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response)
      }
    }
    xhr.send()
  })
}

/**
 * 下载文件
 * @param uri
 */
export const downloadFile2 = (uri, filename) => {
  const _filename = filename || uri.substr(uri.lastIndexOf('/') + 1)
  const element = document.createElement('a')
  element.setAttribute('download', _filename)
  element.setAttribute('href', uri)
  element.setAttribute('target', '_blank')
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

/**
 * 显示转换体积
 */
export const transVol = (val, lengthUnitName = '2') => {
  let res = 0
  if (!validatenull(lengthUnitName)) {
    lengthUnitName = lengthUnitName.toString()
  } else {
    lengthUnitName = '2'
  }
  if (lengthUnitName === '0') {
    res = val / 1000
  } else if (lengthUnitName === '1') {
    res = val / 1000000
  } else if (lengthUnitName === '2') {
    res = val / 1000000000
  }
  if (res) {
    res = Number(res).toFixed(3)
  }
  return Number(res)
}

/**
 * 转换单位为最小单位保存
 */
export const transVolumeSave = (val, unit) => {
  val = Number(val)
  if (unit === '0') {
    return Number((val * 1000).toFixed(3))
  } else if (unit === '1') {
    return Number((val * 1000 * 1000).toFixed(3))
  } else if (unit === '2') {
    return Number((val * 1000 * 1000 * 1000).toFixed(3))
  }
}

/**
* 转换单位为最小单位保存
*/
export const transWeightSave = (val, unit) => {
  val = Number(val)
  if (unit === '0') {
    return Number(val.toFixed(3))
  } else if (unit === '1') {
    return Number((val * 1000).toFixed(3))
  } else if (unit === '2') {
    return Number((val * 1000 * 1000).toFixed(3))
  }
}

/**
 * 显示转换重量
 */
export const transWeight = (val, weightUnitName = '2') => {
  let res = 0
  if (!validatenull(weightUnitName)) {
    weightUnitName = weightUnitName.toString()
  } else {
    weightUnitName = '2'
  }
  if (weightUnitName === '0') {
    res = val
  } else if (weightUnitName === '1') {
    res = val / 1000
  } else if (weightUnitName === '2') {
    res = val / 1000000
  }
  if (res) {
    res = Number(res).toFixed(3)
  }
  return Number(res)
}
export const downloadFileToPath = (data, filename, path) => {
  var raw = window.atob(data);
  var uInt8Array = new Uint8Array(raw.length);
  for (var i = 0; i < raw.length; i++) {
    uInt8Array[i] = raw.charCodeAt(i);
  }
  var blob = new Blob([uInt8Array], { type: 'application/vnd.ms-excel' })
  var url = window.URL.createObjectURL(blob)
  var link = document.createElement('a')
  link.href = url;
  link.download = filename;

  // 创建一个<input type="file">元素，并设置其webkitdirectory属性为下载路径
  var fileInput = document.createElement('input')
  fileInput.type = 'file'
  fileInput.style.display = 'none'
  fileInput.webkitdirectory = path
  fileInput.directory = path
  document.body.appendChild(fileInput)
  link.click()
  setTimeout(() => {
    document.body.removeChild(fileInput)
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }, 100)
}

// 数组去重
export const unique = (arr) => {
  const newArr = []
  const len = arr.length
  for (let i = 0; i < len; i++) {
    if (newArr.indexOf(arr[i]) === -1) {
      newArr.push(arr[i])
    }
  }
  return newArr
}

// 去空格
export const clearSpace = (obj, val) => {
  for (const names in val) {
    if (typeof val[names] === 'string') {
      obj[names] = val[names].replace(/\s+/, '')
    }
    if (typeof val[names] === 'object') {
      clearSpace(obj[names], val[names])
    }
  }
}

// 去末尾空格
export const clearTailSpace = (obj, val) => {
  for (const names in val) {
    if (typeof val[names] === 'string') {
      obj[names] = val[names].trim()
    }
    if (typeof val[names] === 'object') {
      clearTailSpace(obj[names], val[names])
    }
  }
}

export const toFixed2 = (num, cal) => {
  num = Number(num)
  if (cal) {
    num = num / cal
  }
  return num.toFixed(2)
}

export const avueTableColumnHandler = (obj = {}, obj2, obj3) => {
  // obj3.columnSort = obj3.header ? true : false // 只有在表格可以自定义操作时，才允许拖拽
  obj3.column.forEach((item) => {
    item.show = validatenull(item.show) ? true : item.show
    item.cell = validatenull(item.cell) ? true : item.cell
    item.sortable = validatenull(item.sortable) ? false : item.sortable
    item.fixed = validatenull(item.fixed) ? false : item.fixed
    item.overHidden = validatenull(item.overHidden) ? true : item.overHidden
  })
  return Object.assign(obj, obj2, obj3)
}
