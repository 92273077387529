<template>
  <section class="border-box-2">
    <section class="b-header">
      <slot name="headerLeft"></slot>
      <slot name="headerRight"></slot>
    </section>

    <section class="b-content"
             :class="[
              padding ? '' : 'p0'
             ]">
      <slot></slot>
    </section>
  </section>
</template>

<script>
import { updateMixin } from "@/mixins/global"
export default {
  mixins: [updateMixin],
    name: 'border-box-2',
    props: {
      padding: {
        type: Boolean,
        default: true
      }
    }
  }
</script>

<style scoped lang="scss">
  .border-box-2 {
    .b-header {
      background: #EBEFF5;
      height: 40px;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 10px;

      .icon {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50%;
        width: 26px;
        height: 26px;

        i {
          font-size: 20px;
        }
      }

      .title {
        font-size: 14px;
        font-weight: 700;
        margin-left: 10px;
      }
    }

    .b-content {
      background: #F5F7FA;
    }
  }
</style>