<template>
  <el-cascader
    ref="cascaderRef"
    :placeholder="placeholderNew"
    :size="size"
    :options="options"
    :props="props"
    :showAllLevels="showAllLevels"
    v-model="selectedValue"
    :disabled="disabled"
    :clearable="clearable"
    @blur="handleBlur"
    @focus="handleFocus"
    @change="handleChange"
    @visibleChange="handleVisibleChange"
    @removeTag="handleRemoveTag"
    @clear="handleClear"
    @expandChange="handleExpandChange"
  >
    <slot></slot>
    <template v-for="slotName in slotNames" :slot="slotName">
      <slot :name="slotName"></slot>
      <!-- 渲染原本的插槽内容 -->
    </template>
  </el-cascader>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'my-input',

  props: {
    value: {},
    options: Array,
    props: Object,
    size: String,
    placeholder: {
      type: String,
      default: () => '请选择'
    },
    disabled: Boolean,
    clearable: Boolean,
    filterable: Boolean,
    filterMethod: Function,
    separator: {
      type: String,
      default: ' / '
    },
    showAllLevels: {
      type: Boolean,
      default: true
    },
    collapseTags: Boolean,
    debounce: {
      type: Number,
      default: 300
    },
    beforeFilter: {
      type: Function,
      default: () => (() => {})
    },
    popperClass: String
  },
  data() {
    return {
      selectedValue: this.value
    }
  },
  watch: {
    selectedValue(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.selectedValue = val
    }
  },
  computed: {
    ...mapGetters(['globalReplaceTextList']),
    placeholderNew() {
      let placeholderNew = this.placeholder
      if(placeholderNew) {
        this.globalReplaceTextList.forEach((element) => {
          if (placeholderNew.indexOf(element.name) > -1) {
            placeholderNew = placeholderNew.replaceAll(
              element.name,
              element.value
            )
          }
        })
      }
      return placeholderNew
    },
    slotNames() {
      // 这里列出了 el-select 原本的插槽名称
      return ['prefix', 'suffix', 'append', 'prePend']
    }
  },
  methods: {
    blur() {
      this.$refs['cascaderRef'].blur()
    },
    focus() {
      this.$refs['cascaderRef'].focus()
    },
    handleBlur(event) {
      this.$emit('blur', event)
    },
    handleFocus(event) {
      this.$emit('focus', event)
    },
    handleChange(value) {
      this.$nextTick(() => {
        this.$emit('change', value)
      })
    },
    handleVisibleChange(value) {
      this.$emit('visible-change', value)
    },
    handleRemoveTag(value) {
      this.$emit('remove-tag', value)
    },
    handleClear() {
      this.$emit('clear')
    },
    handleExpandChange() {
      this.$emit('expand-change')
    },
  }
}
</script>
