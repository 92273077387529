import http from '@/router/axios'
import { notVerifiedApiPrefix } from '@/config/env'

const notVerifiedApi = {}

/**

 * @Description: 根据运单号查询物流轨迹
 */
notVerifiedApi.queryTrackByWaybillNo = (object) => {
  return http.$POST(
    `${notVerifiedApiPrefix}/f/queryTrackByWaybillNo?waybillNo=${object}`,
    {
      meta: {
        isToken: false
      }
    }
  )
}

/**
 * @Description：
 */
notVerifiedApi.queryEnterpriseByDomainInfo = () => {
  return http.$POST(
    `${notVerifiedApiPrefix}/organization/fetchOrganizationExtendByNowDomain`,
    {
      meta: {
        catchError: false,
        isToken: false
      }
    }
  )
}

/**
 * @Description：
 */
notVerifiedApi.queryEnterpriseByDomainInfo2 = (object) => {
  return http.$POST(
    `${notVerifiedApiPrefix}/organization/fetchOrganizationExtendByDomain?domain=${object}`,
    {
      meta: {
        catchError: false,
        isToken: false
      }
    }
  )
}

/**
 * @Description：
 */
notVerifiedApi.queryEnterpriseByDomainInfo3 = (object) => {
  return http.$POST(
    `${notVerifiedApiPrefix}/organization/fetchOrganizationCustomValue`,
    {
      meta: {
        catchError: false,
        isToken: false
      },
      params: object
    }
  )
}

/**
 * @Description：baidu鉴权认证
 */
// notVerifiedApi.baiduOauth = (object) => {
//   return http.$GET(`${baiduApiPrefix}/oauth/2.0/token`, {
//     params: object
//   })
// }

/**
 * @Description：业务跟进登记(创始联盟申请)
 */
notVerifiedApi.businessFollowAdd = (object) => {
  return http.$POST(`${notVerifiedApiPrefix}/api/business/follow/add`, {
    meta: {
      catchError: false,
      isToken: false
    },
    params: object
  })
}

export default notVerifiedApi
