/**
 * @author
 * @date
 * @Description: 全局参数混入(Global Queryparams Mixins)
 * @Param:
 */
export const globalTableMixin = {
  data() {
    return {
      showSum: false,
      sumData: [],
      pickerOption: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const newData = new Date().toLocaleDateString()

              const end = new Date(new Date(newData).getTime() + 24 * 60 * 60 * 1000 - 1)
              const start = new Date(new Date(newData).getTime())

              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近7天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()

              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)

              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近15天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()

              start.setTime(start.getTime() - 3600 * 1000 * 24 * 15)

              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近30天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()

              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)

              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      boxFormVisible: {
        visible: false,
        type: 'add',
        form: {}
      },
      setAddressDisplayType: '',
      isShowSetAddressDisplay: false,
      isIndeterminate: false,
      isSelectTableAll: false,
      dataCollection: [],
      dataCollection1: [],
      treeCollection: [],
      multipleSelection: [],
      treeLoading: false,
      tableLoading: false,
      page1: {
        layout: 'total, sizes, prev, pager, next',
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20, // 每页显示多少条,
        noPadding: false // 页计合计展开样式控制
      },
      // 分页参数
      page: {
        layout: 'total, sizes, prev, pager, next',
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 30, // 每页显示多少条,
        noPadding: false // 页计合计展开样式控制
      }
    }
  },
  methods: {
    setAvueTableSize(key, size) {
      const tableSizeKeyJson = window.localStorage.getItem('tableSizeKey')
      let tableSizeKey = []
      if (!this.validatenull(tableSizeKeyJson)) {
        tableSizeKey = JSON.parse(tableSizeKeyJson)
      }
      const keyIndex = tableSizeKey.findIndex(item => {
        return key === item.key
      })
      if (keyIndex !== -1) {
        tableSizeKey[keyIndex] = {
          key,
          s: size
        }
      } else {
        tableSizeKey.push({
          key,
          s: size
        })
      }
      window.localStorage.setItem('tableSizeKey', JSON.stringify(tableSizeKey))
    },
    /**
     * @author cc@zdu56.com
     * @date 2019/09/07 17:23:09
     * @Description: 表格初次加载先查询每页数量
     * @Param:   key:表格key    isLoad: 是否直接获取数据
     */
    getAvueTableSize(key, isLoad = true) { 
      const tableSizeKeyJson = window.localStorage.getItem('tableSizeKey')
      let tableSizeKey = []
      if (!this.validatenull(tableSizeKeyJson)) {
        tableSizeKey = JSON.parse(tableSizeKeyJson)
      }
      const keyIndex = tableSizeKey.findIndex(item => {
        return key === item.key
      })
      if (keyIndex !== -1) {
        this.page.pageSize = tableSizeKey[keyIndex].s
      }
      if(isLoad) {
        this.handleLoadAndQuery()
      }
    },
    clickAddress(type) {
      this.setAddressDisplayType = type
      this.isShowSetAddressDisplay = true
    },
    closeSetAddressDisplay() {
      this.isShowSetAddressDisplay = false
      this.handleLoadAndQuery()
    },
    clickCrud() {
      const selectionTxt = window.getSelection().toString().trim()
      if (selectionTxt.length > 0) {
        return
        // 文本选中
      }
      this.$refs['searchRef'].closeMoreSearch()
    },
    showBtn() {
      this.page.noPadding = !this.page.noPadding
      this.showSum = !this.showSum
      if (this.showSum) {
        this.$nextTick(() => {
          this.$refs['tableRef'].getTableHeight()
        })
      } else {
        setTimeout(() => {
          this.calcTableHeight()
        }, 150)
      }
    },
    calcTableHeight() {
      if (this.$refs['tableRef']) {
        this.$refs['tableRef'].getTableHeight()
      }
    },
    changeMoreSearchVisible() {
      if (this.$refs['tableRef']) {
        this.$refs['tableRef'].getTableHeight()
      }
    },
    closeBoxFormVisible(evt) {
      this.boxFormVisible.visible = false
      if (evt) this.handleLoadAndQuery()
    },
    /**
     * 检查选择的数据
     * @param sOne：是否唯一选择
     * @returns {boolean}
     */
    checkSelection(sOne = false) {
      if (this.validatenull(this.multipleSelection) || this.multipleSelection.length <= 0) {
        this.$message({
          message: `${this.$t('Prompt.notSelection')}`,
          type: 'warning',
          duration: this.$duration
        })
        return false
      }
      if (sOne) {
        if (this.multipleSelection.length > 1) {
          this.$message({
            message: `${this.$t('Prompt.selectionOne')}`,
            type: 'warning',
            duration: this.$duration
          })
          return false
        }
        return true
      }
      return true
    },
    getSelectionKey(key = 'id') {
      if (this.validatenull(this.multipleSelection)) return []
      return this.multipleSelection.map((item) => {
        return item[key]
      })
    },
    rowDbclick(row) {
      const findIndex = this.multipleSelection.findIndex((item) => {
        return item.id === row.id
      })
      if (findIndex > -1) {
        this.$refs['tableRef'].toggleRowSelection(row, false)
      } else {
        this.$refs['tableRef'].toggleRowSelection(row, true)
      }
    },
    rowClick(row, column) {
      if (column && column.label === '操作') {
        return
      }
      const findIndex = this.multipleSelection.findIndex((item) => {
        return item.id === row.id
      })
      if (findIndex > -1) {
        this.$refs['tableRef'].toggleRowSelection(row, false)
      } else {
        this.$refs['tableRef'].toggleRowSelection(row, true)
      }
    },
    selectAll(selection) {
      if (selection.length > 0) {
        this.isIndeterminate = false
        this.isSelectTableAll = true
      } else {
        this.isIndeterminate = false
        this.isSelectTableAll = false
      }
    },
    toSelectTableAll(type) {
      if (!type) {
        this.handleClearTable()
      } else {
        this.dataCollection.forEach(row => {
          this.$refs['tableRef'].toggleRowSelection(row, true)
        })
      }
    },
    handleClearTable() {
      this.isIndeterminate = false
      this.isSelectTableAll = false
      this.$refs['tableRef'].selectClear()
    },
    rowClickRadio(row) { // 行点击 ，单选操作
      const findIndex = this.multipleSelection.findIndex((item) => {
        return item.id === row.id
      })
      if (findIndex > -1) {
        this.$refs['tableRef'].toggleRowSelection(row, false)
      } else {
        this.multipleSelection.forEach(item => {
          this.$refs['tableRef'].toggleRowSelection(item, false)
        })
        this.$refs['tableRef'].toggleRowSelection(row, true)
        this.multipleSelection = [row]
      }
    },
    checkboxTableSelect(rowList) { // 多选框点击 ，单选操作
      if (rowList.length === 0) {
        return
      }
      const lastRow = rowList[rowList.length - 1]
      const findIndex = this.multipleSelection.findIndex((item) => {
        return item.id === lastRow.id
      })
      if (findIndex > -1) {
        this.$refs['tableRef'].toggleRowSelection(lastRow, false)
      } else {
        this.$refs['tableRef'].selectClear()
        this.$nextTick(() => {
          this.$refs['tableRef'].toggleRowSelection(lastRow, true)
          this.multipleSelection = [lastRow]
        })
      }
    },
    /**
     * @author cc@zdu56.com
     * @date 2019/09/07 17:23:09
     * @Description: 表格勾选的回调事件
     * @Param:
     */
    handleSelectionChange(event) {
      this.isSelectTableAll = event.length === this.dataCollection.length
      this.isIndeterminate = event.length > 0 && event.length < this.dataCollection.length
      this.multipleSelection = event
    },
    /**
     * @author cc@zdu56.com
     * @date 2019/09/07 17:24:55
     * @Description: 表格当前页改变的回调方法
     * @Param:
     */
    handleCurrentChange(val) {
      this.page.currentPage = val
      this.handleLoadAndQuery()
    },
    /**
     * @author cc@zdu56.com
     * @date 2019/09/07 17:25:07
     * @Description: 表格页码改变的回调方法
     * @Param:
     */
    handleSizeChange(size) {
      this.page.pageSize = size
      this.handleLoadAndQuery()
      this.setAvueTableSize(this.$options.name, size)
    },
    handleSearchChange() {
      this.page.currentPage = 1
      this.handleLoadAndQuery()
    },
    myDebounce(fn, delay) {
      let timer
      return function() {
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          fn()
        }, delay)
      }
    }
  },
  // activated() {
  //   if (this.$refs['tableRef']) {
  //     this.$refs['tableRef'].getTableHeight()
  //     this.$refs['tableRef'].selectClear()
  //   }
  // },
  deactivated() {
    const mypopoverList = document.querySelectorAll('.el-popover')   // 获取页面所有popover的dom集合
    const mytooltipList = document.querySelectorAll('.el-tooltip__popper')  // 获取页面所有tooltip的dom集合
        // 分别遍历让所有的悬浮框隐藏
    mypopoverList.forEach((value)=>{    
        value.style.display = 'none'
    })
    mytooltipList.forEach((value)=>{
        value.style.display = 'none'
    })
  },
  beforeMount() {
    const getTableHeight = () => {
      if (this.$refs['tableRef']) {
        this.$refs['tableRef'].getTableHeight()
        this.$refs['tableRef'].selectClear()
      }
    }
    window.addEventListener('resize', this.myDebounce(getTableHeight, 500))
  }
  // ,
  // beforeDestroy () {
  //   window.removeEventListener('resize')
  // }
}

/**
 * @author
 * @date
 * @Description: 全局参数混入(Global Queryparams Mixins)
 * @Param:
 */
import { mapGetters } from 'vuex'
import _ from 'lodash'
export const updateMixin = {
  computed: {
    ...mapGetters(['globalReplaceTextList'])
  },
  updated() {
    if(this.globalReplaceTextList.length > 0) {
      this.globalReplaceText(this.$el)
    }
  },
  methods: {
    globalReplaceText(node) {
      if (node.nodeType === Node.TEXT_NODE) {
        this.globalReplaceTextList.forEach((element) => {
          if (node.nodeValue.indexOf(element.name) > -1) {
            node.nodeValue = node.nodeValue.replaceAll(
              element.name,
              element.value
            )
          }
        })
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        for (const childNode of node.childNodes) {
          this.globalReplaceText(childNode)
        }
      }
    }
  }
}

export const globalFormMixin = {
  props: {
    boxVisible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'add'
    },
    form: {
      type: Object
    }
  },
  computed: {
    isAdd() {
      return this.type && this.type === 'add'
    },
    isModify() {
      return this.type && this.type === 'modify'
    },
    dialogTitle() {
      return (suffix) => {
        return this.isAdd
          ? `新增${suffix}`
          : this.isModify ? `编辑${suffix}` : ''
      }
    }
  },
  methods: {
    dialogClose(flag = false) {
      this.$emit('close', flag)
    },
    dialogClosed(form) {
      this.$refs[form].resetFields()
    }
  }
}

import fileApi from '@api/fileApi'
import { downloadFile } from '@util/util'

export const fileMixin = {
  methods: {
    downloadTemplate(code) {
      fileApi.onDownloadTemplate(code)
        .then((ret) => {
          const retData = ret.data
          downloadFile(retData.url)
        })
        .catch(() => {
        })
    }
  }
}