import http from '@/router/axios'
import { clipApiPrefix } from '@/config/env'

// 自定义属性配置
const configSettingApi = {}

// *** 根据分组加载配置(企业) ***//
configSettingApi.getCustomGroupItemByOrg = (object) => {
  return http.$POST(`${clipApiPrefix}/custom/getCustomGroupItemByOrg`,
    {
      params: object
    })
}

// *** 修改配置(企业) ***//
configSettingApi.updateCustomItemByOrg = (object) => {
  return http.$POST(`${clipApiPrefix}/custom/updateCustomItemByOrg`,
    {
      params: object
    })
}

// *** 批量获取多个属性的值) ***//
configSettingApi.batchGetValue = (object) => {
  return http.$POST(`${clipApiPrefix}/custom/batchGetValue`,
    {
      showLoading: false,
      params: object
    })
}
// 获取单个值
configSettingApi.getValue = async(value) => {
  let codes = [value]
  codes = { codes }
  return await new Promise(resolve => {
    configSettingApi.batchGetValue(codes).then(res => {
      resolve(res.data[value])
    })
  })
}

// 分页查询系统前端展示字段自定义
configSettingApi.querySysTextReplaceConfig = (object) => {
  return http.$POST(`${clipApiPrefix}/sysTextReplaceConfig/queryPage`,
    {
      showLoading: false,
      params: object
    })
}

// 修改系统前端展示字段自定义
configSettingApi.modifySysTextReplaceConfig = (object) => {
  return http.$POST(`${clipApiPrefix}/sysTextReplaceConfig/modify`,
    {
      showLoading: true,
      params: object
    })
}

export default configSettingApi