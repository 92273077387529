import { setStore, getStore } from '@/util/store'
import { diff } from '@/util/util'
import website from '@/config/website'

const isFirstPage = website.isFirstPage
const tagWel = website.firstPage

const tagObj = {
  label: '', // 标题名称
  value: '', // 标题的路径
  params: '', // 标题的路径参数
  query: '', // 标题的参数
  meta: {}, // 额外参数
  group: [] // 分组
}

// 处理首个标签
function setFirstTag(list) {
  if (list.length === 1) {
    list[0].close = false
  } else {
    list.forEach(ele => {
      if (ele.value === tagWel.value && isFirstPage === false) {
        ele.close = false
      } else {
        ele.close = true
      }
    })
  }
}

const navs = {
  state: {
    tagList: getStore({name: 'TAGLISTKEY'}) || [],
    tag: getStore({name: 'TAGKEY'}) || tagObj,
    tagWel: tagWel,
    firstPageComponentKey: 0,
    defaultOpenSubMenu: null
  },
  actions: {},
  mutations: {
    ADD_TAG: (state, action) => {
      state.tag = action
      setStore({name: 'TAGKEY', content: state.tag, type: 'session'})
      if (state.tagList.some(ele => diff(ele, action))) return
      state.tagList.push(action)
      setFirstTag(state.tagList)
      setStore({name: 'TAGLISTKEY', content: state.tagList, type: 'session'})
    },
    DEL_TAG: (state, action) => {
      state.tagList = state.tagList.filter(item => {
        return !diff(item, action)
      })
      setFirstTag(state.tagList)
      setStore({name: 'TAGLISTKEY', content: state.tagList, type: 'session'})
    },
    DEL_ALL_TAG: (state) => {
      state.tagList = [state.tagWel]
      state.firstPageComponentKey += 1 // 关闭所有tag时，首页重新拉数据，可以放到切换企业再处理首页数据
      setStore({name: 'TAGLISTKEY', content: state.tagList, type: 'session'})
    },
    DEL_TAG_OTHER: (state) => {
      state.tagList = state.tagList.filter(item => {
        if (item.value === state.tag.value) {
          return true
        } else if (!website.isFirstPage && item.value === website.firstPage.value) {
          return true
        }
      })
      setFirstTag(state.tagList)
      setStore({name: 'TAGLISTKEY', content: state.tagList, type: 'session'})
    },
    CLEAR_TAG: (state) => {
      state.tagList = [state.tagWel]
      setStore({name: 'TAGLISTKEY', content: state.tagList, type: 'session'})

      state.tag = tagObj
      setStore({name: 'TAGKEY', content: state.tag, type: 'session'})
    },
    OPEN_SUBMENU: (state, defaultOpenSubMenu) => {
      state.defaultOpenSubMenu = defaultOpenSubMenu
    }
  }
}
export default navs
