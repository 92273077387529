import http from '@/router/axios'
import { clipApiPrefix } from "@/config/env"

const fileApi = {}

/**
 * @author：cc@zdu56.com
 * @Description：ali oss 获取上传配置
 * @Param：
 */
fileApi.getOssUploadConfig = (object) => {
  return http.$GET(`${clipApiPrefix}/api/sts/getOssRole`,
    {
      params: object,
      showLoading: true
    })
}

/**
 * @author：cc@zdu56.com
 * @Description：下载模板
 * @Param：
 */
fileApi.onDownloadTemplate = (object) => {
  return http.$GET(`${clipApiPrefix}/file/queryTemplate/${object}`,
    {
      showLoading: true
    })
}

export default fileApi
