// 配置编译环境和线上环境之间的切换

let baseUrl = ''
const fileApiPrefix = '/fileApi'
const notVerifiedApiPrefix = '/clip2Api'
const clipApiPrefix = '/clip2Api'
let versionSuffix = ''
let mqttApiPrefix = ''
// const iconfontVersion = ['1519429_1fxu3w9dxmq', '2032852_5rk7urv494p']
// const iconfontUrl = `//at.alicdn.com/t/font_$key.css`
const env = process.env

if (env.NODE_ENV === 'development') {
  baseUrl = `` // 开发环境地址
  mqttApiPrefix = 'ws://192.168.1.213:8083/mqtt'
  versionSuffix = 'dev'
} else if (env.NODE_ENV === 'test') {
  baseUrl = `` // 测试环境地址
  mqttApiPrefix = 'ws://192.168.1.213:8083/mqtt'
  versionSuffix = 'test'
} else if (env.NODE_ENV === 'production') {
  baseUrl = `` // 生产环境地址
  mqttApiPrefix = 'wss://scoket.zdu56.com'
  versionSuffix = ''
} else if (env.NODE_ENV === 'alpha') {
  baseUrl = `` // alpha环境地址
  mqttApiPrefix = 'ws://192.168.1.211:8083/mqtt'
  versionSuffix = 'alpha'
} else if (env.NODE_ENV === 'beta') {
  baseUrl = `` // alpha环境地址
  mqttApiPrefix = 'wss://beta-scoket.zdu56.com'
  versionSuffix = 'beta'
}

export {
  clipApiPrefix,
  mqttApiPrefix,
  notVerifiedApiPrefix,
  fileApiPrefix,
  baseUrl,
  versionSuffix,
  env
}
