var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "border-box-2" }, [
    _c(
      "section",
      { staticClass: "b-header" },
      [_vm._t("headerLeft"), _vm._t("headerRight")],
      2
    ),
    _c(
      "section",
      { staticClass: "b-content", class: [_vm.padding ? "" : "p0"] },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }