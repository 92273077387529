import http from '@/router/axios'
import { clipApiPrefix } from '@/config/env'

const subjectApi = {}

/**
 * @Description: 分页查询财务科目列表
 */
 subjectApi.queryFinanceSuject = (object) => {
  return http.$GET(`${clipApiPrefix}/financeSuject/query/filter/parent/list`,
    {
      showLoading: true,
      params: object
    })
}

export default subjectApi
