<template>
  <div>
    <el-link
      v-for='(item, index) in linkList'
      :key="index"
      :type="item.type"
      :class="item.className"
      :underline="false"
      v-if='item.vIf()'
      v-show='item.vShow(row)'
      @click="go(row, item)"
    >
      {{ item.name }}
    </el-link>
    <el-dropdown v-if="dropdownList.length > 0">
      <i class="el-icon-more menu-btn-more"></i>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for='(item, index) in dropdownList'
          :key="index"
          @click.native="go(row, item)"
          v-show='item.vShow(row)'
        >{{ item.name }}</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
  import { deepClone } from '@util/util'
  import { updateMixin } from '@/mixins/global'
import { mapGetters } from 'vuex'
  export default {
    mixins: [updateMixin],
    props: {
      row: {
        type: Array,
        default: []
      },
      operationWidth: Number,
      dataList: {
        type: Array,
        default: () => {
          return [{
            name: '',
            type: 'primary',
            className: 'mr10',
            clickFunction: () => { return true },
            vIf: () => {
              return true
            },
            vShow: () => {
              return true
            },
          }]
        }
      },
    },
    data() {
      return {
        linkList: [],
        dropdownList: [],
      }
    },
    computed: {
      ...mapGetters(['fontConfig'])
    },
    watch: {
      'fontConfig': {
        handler(val) {
          this.$nextTick(() => {
            console.log(this.fontConfig, 'this.fontConfig')
            this.linkList = []
            this.dropdownList = []
            this.into()
          })
        },
        deep: true
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.into()
      })
    },
    methods: {
      into() {
        let wordLength = 0
        this.dataList.forEach(item => {
          if(this.validatenull(item.name)) {
            item.name = ''
          }
          if(this.validatenull(item.type)) {
            item.type = 'primary'
          }
          if(this.validatenull(item.className)) {
            item.className = 'mr10'
          }
          if(this.validatenull(item.clickFunction)) {
            item.clickFunction = () => { return true }
          }
          if(this.validatenull(item.vIf)) {
            item.vIf = () => { return true }
          }
          if(this.validatenull(item.vShow)) {
            item.vShow = () => { return true }
          }
          if(item.vShow(this.row) && item.vIf()) {
            wordLength = wordLength + this.strLength(item.name) + 10
            if (wordLength < this.operationWidth - 26) {
              this.linkList.push(item)
            } else {
              this.dropdownList.push(item)
            }
          }
        });
      },
      go(row, item) {
        const clickFunction = deepClone(item.clickFunction)
        clickFunction(row)
      },
      strLength(word) {
        let fontSize = this.fontConfig ? JSON.parse(this.fontConfig).fontSize === 0 ? 12 : JSON.parse(this.fontConfig).fontSize === 1 ? 14 : JSON.parse(this.fontConfig).fontSize === 2 ? 16 : 14 : 14
        let length = 0
        if (typeof word === 'number') {
          length = word.toString().length
        } else if (word === null) {
          length = 3
        } else if (typeof word === 'undefined') {
          length = 0
        } else {
          Array.from(word).map(function (char) {
            if (char.charCodeAt(0) > 255) {
              //字符编码大于255，说明是双字节字符
              length += 2
            } else {
              length++
            }
          })
        }
        return Math.floor(length * (fontSize / 2))
      }
    }
  }
</script>