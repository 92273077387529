<template>
  <section style="width: 100%" class="view-form">
    <el-form :label-position="labelPosition"
             label-suffix="："
             :label-width="labelWidth">
      <template v-if="!hasGroup">
        <el-row :span="24" :gutter="20">
          <template v-for="(column,index) in columns">
            <el-col :xl="column.span || 8" :xs="24" :lg="column.span || 8" :key="index"
                    v-if="!column.hide">
              <form-item :column="column">
                <template v-if="column.slotLabel" slot-scope="scope"
                          :slot="`${column.prop}Label`">
                  <slot :name="`${column.prop}Label`"
                        v-bind="scope"></slot>
                </template>

                <template v-if="column.slot" slot-scope="scope"
                          :slot="column.prop">
                  <slot :name="column.prop"
                        v-bind="Object.assign(scope)"></slot>
                </template>
              </form-item>
            </el-col>
          </template>
        </el-row>
      </template>

      <template v-if="hasGroup">
        <section :key="group.prop" v-for="(group, gIndex) in columnGroup"
                 :class="[gIndex > 0 ? 'mt20' : '']">
          <border-box-3 :title="group.label">
            <el-row :span="24" :gutter="20" class="mt10">
              <template v-for="(column,index) in group.column">
                <el-col :xl="column.span || 8" :xs="24" :lg="column.span || 8" :key="index"
                        v-if="!column.hide">
                  <form-item :column="column">
                    <template v-if="column.slotLabel" slot-scope="scope"
                              :slot="`${column.prop}Label`">
                      <slot :name="`${column.prop}Label`"
                            v-bind="scope"></slot>
                    </template>

                    <template v-if="column.slot" slot-scope="scope"
                              :slot="column.prop">
                      <slot :name="column.prop"
                            v-bind="Object.assign(scope)"></slot>
                    </template>
                  </form-item>
                </el-col>
              </template>
            </el-row>
          </border-box-3>
        </section>
      </template>
    </el-form>
  </section>
</template>

<script>
  import { vaildData, validatenull } from '@util/validate'
  import { updateMixin } from "@/mixins/global"
export default {
  mixins: [updateMixin],
    name: 'index',
    provide() {
      return {
        vform: this
      }
    },
    components: {
      formItem: () => import('./form-item')
    },
    props: {
      formItemClass: {
        type: String
      },
      data: {
        type: Object
      },
      option: {
        type: Object
      }
    },
    computed: {
      labelPosition() {
        return vaildData(this.formOption.labelPosition, 'top')
      },
      labelWidth() {
        return vaildData(this.formOption.labelWidth, '85px')
      },
      hasGroup() {
        return !validatenull(this.formOption.group)
      },
      columnGroup() {
        return this.formOption.group || []
      },
      columns() {
        return this.formOption.column || []
      },
      formData() {
        return this.data
      },
      formOption() {
        return this.option
      }
    }
  }
</script>

<style scoped lang="scss">

  .view-form {
    /deep/ .el-form {
      .el-form-item {
        margin-bottom: 13px !important;

        &__label {
          color: rgba(0, 0, 0, .85) !important;
        }

        &__content {
          color: rgba(0, 0, 0, .65) !important;

          .el-image {
            width: 60%;
            height: 60px;
          }
        }
      }
    }

    /*/deep/ .el-form--label-top .el-form-item__label {*/
    /*  padding: 0px !important;*/
    /*  color: #909399;*/
    /*}*/

    /deep/ .el-form--label-top {
      .el-form-item {
        border-bottom: 1px solid #F2F6FC;
        margin-bottom: 8px !important;

        &__label {
          padding: 0px !important;
          color: rgba(0, 0, 0, .85) !important;
        }

        &__content {
          color: rgba(0, 0, 0, .65) !important;
        }
      }
    }
  }
</style>