var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-date-picker",
    {
      attrs: {
        size: _vm.size,
        format: _vm.format,
        valueFormat: _vm.valueFormat,
        readonly: _vm.readonly,
        placeholder: _vm.placeholderNew,
        type: _vm.type,
        startPlaceholder: _vm.startPlaceholderNew,
        endPlaceholder: _vm.endPlaceholderNew,
        prefixIcon: _vm.prefixIcon,
        clearIcon: _vm.clearIcon,
        name: _vm.name,
        disabled: _vm.disabled,
        clearable: _vm.clearable,
        popperClass: _vm.popperClass,
        id: _vm.id,
        editable: _vm.editable,
        align: _vm.align,
        appendToBody: _vm.appendToBody,
        defaultValue: _vm.defaultValue,
        defaultTime: _vm.defaultTime,
        rangeSeparator: _vm.rangeSeparator,
        pickerOptions: _vm.pickerOptions,
        unlinkPanels: _vm.unlinkPanels,
        validateEvent: _vm.validateEvent
      },
      on: {
        blur: _vm.handleBlur,
        focus: _vm.handleFocus,
        change: _vm.handleChange
      },
      model: {
        value: _vm.setValue,
        callback: function($$v) {
          _vm.setValue = $$v
        },
        expression: "setValue"
      }
    },
    [
      _vm._t("default"),
      _vm._l(_vm.slotNames, function(slotName) {
        return _c("template", { slot: slotName }, [_vm._t(slotName)], 2)
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }