import Cookies from 'js-cookie'
import { validatenull } from "@util/validate"

const tokenKey = 'business-x-access-token'
const inFifteenMinutes = new Date(new Date().getTime() + 48 * 60 * 60 * 1000)

export function getToken() {
  // console.log('tk:', Cookies.get(tokenKey));
  return Cookies.get(tokenKey)
}

export function setToken(token, expires) {
  expires = validatenull(expires) ? inFifteenMinutes : new Date(expires)

  return Cookies.set(tokenKey, token, {expires: expires})
}

export function removeToken() {
  return Cookies.remove(tokenKey)
}
