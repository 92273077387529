import http from '@/router/axios'
import { clipApiPrefix } from '@/config/env'

const sysConfigApi = {}

/**
 * @Description: 查询参数列表
 */
sysConfigApi.pageConfig = (object) => {
  return http.$POST(`${clipApiPrefix}/config/pageConfig`,
    {
      showLoading: true,
      params: object
    })
}

/**
 * @Description: 根据code查业务参数
 */
 sysConfigApi.getValueByCode = (object) => {
  return http.$GET(`${clipApiPrefix}/config/getValueByCode`,
    {
      showLoading: true,
      params: object
    })
}

/**
 * @Description: 新增参数
 */
 sysConfigApi.addConfig = (object) => {
  return http.$POST(`${clipApiPrefix}/config/add`,
    {
      params: object
    })
}

/**
 * @Description: 修改参数
 */
 sysConfigApi.editConfig = (object) => {
  return http.$POST(`${clipApiPrefix}/config/edit`,
    {
      params: object
    })
}

/**
 * @Description: 删除参数
 */
 sysConfigApi.deleteConfig = (object) => {
  return http.$GET(`${clipApiPrefix}/config/delete`,
    {
      params: object
    })
}

/**
 * @Description: 根据参数id查询参数
 */
 sysConfigApi.getConfigById = (object) => {
  return http.$POST(`${clipApiPrefix}/config/get`,
    {
      params: object
    })
}

export default sysConfigApi
