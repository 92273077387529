<template>
  <section class="cr-table-header__search">
    <el-form
      label-suffix="："
      label-position="right"
      ref="searchFormRef"
      :model="form"
      :class="formClass"
      :label-width="labelWidth"
      @submit.native.prevent
    >
      <section>
        <el-row :span="24" :gutter="20">
          <template v-for="(column, index) in columns">
            <el-col
              :xl="colxl(column)"
              :lg="collg(column)"
              :md="colmd(column)"
              :sm="colsm(column)"
              :xs="24"
              :key="index"
              v-if="$VaildData(column.hide, true)"
            >
              <el-form-item
                :label="column.label"
                :prop="column.prop"
                :label-width="columnLabelWidth(column)"
              >
                <slot v-if="column.slot" :name="column.prop"></slot>
                <template v-else>
                  <template v-if="columnType(column) === 'checkbox'">
                    <el-checkbox
                      @keyup.enter.native="handleSearch"
                      @clear="handleSearch"
                      v-model.trim="form[column.prop]"
                      >{{ column.placeholder }}</el-checkbox
                    >
                  </template>
                  <template v-if="columnType(column) === 'input'">
                    <my-input
                      :placeholder="validatenull(column.placeholder) ? '请输入' : column.placeholder"
                      :clearable="true"
                      @keyup.enter.native="handleSearch"
                      @clear="handleSearch"
                      v-model.trim="form[column.prop]"
                    >
                      <template slot="prepend" v-if="column.prepend">{{column.prependLabel}}</template>
                    </my-input>
                  </template>
                  <template v-if="columnType(column) === 'select'">
                    <my-select
                      v-model="form[column.prop]"
                      :filterable="columnfilterable(column)"
                      :placeholder="validatenull(column.placeholder) ? '请选择' : column.placeholder"
                      @clear="handleSearch"
                      :clearable="column.clearable === false ? false : true"
                    >
                      <my-option
                        v-for="item in column.dicData"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </my-option>
                    </my-select>
                  </template>
                  <template v-if="columnType(column) === 'multiple_select'">
                    <my-select
                      v-model="form[column.prop]"
                      :clearable="column.clearable === false ? false : true"
                      @clear="handleSearch"
                      multiple
                      allow-create
                      default-first-option
                      :filterable="columnfilterable(column)"
                      :placeholder="validatenull(column.placeholder) ? '请选择' : column.placeholder"
                    >
                      <my-option
                        v-for="item in column.dicData"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </my-option>
                    </my-select>
                  </template>

                  <template v-if="['daterange'].includes(columnType(column))">
                    <my-date-picker
                      :type="column.type"
                      :editable="false"
                      :clearable="true"
                      :default-time="['00:00:00', '23:59:59']"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      :range-separator="$t('Prompt.datePicker.separator')"
                      :start-placeholder="`${$t(
                        'Prompt.datePicker.startPlaceholder'
                      )}`"
                      :end-placeholder="`${$t(
                        'Prompt.datePicker.endPlaceholder'
                      )}`"
                      v-model="form[column.prop]"
                    >
                    </my-date-picker>
                  </template>
                </template>
              </el-form-item>
            </el-col>
          </template>

          <template v-if="adColumns.length > 0">
            <template v-for="(column, index) in adColumns">
              <el-col
                :xl="colxl(column)"
                :lg="collg(column)"
                :md="colmd(column)"
                :sm="colsm(column)"
                :xs="24"
                :key="index"
                v-show="searchVisible"
                v-if="$VaildData(column.hide, true)"
              >
                <el-form-item
                  :label="column.label"
                  :prop="column.prop"
                  :label-width="columnLabelWidth(column)"
                >
                  <slot v-if="column.slot" :name="column.prop"></slot>
                  <template v-else>
                    <template v-if="columnType(column) === 'input'">
                      <my-input
                        :placeholder="validatenull(column.placeholder) ? '请输入' : column.placeholder"
                        :clearable="true"
                        @keyup.enter.native="handleSearch"
                        @clear="handleSearch"
                        v-model.trim="form[column.prop]"
                      >
                      </my-input>
                    </template>
                    <template v-if="columnType(column) === 'checkbox'">
                      <el-checkbox
                        @keyup.enter.native="handleSearch"
                        @clear="handleSearch"
                        v-model.trim="form[column.prop]"
                        >{{ column.placeholder }}</el-checkbox
                      >
                    </template>
                    <template v-if="columnType(column) === 'select'">
                      <my-select
                        v-model="form[column.prop]"
                        :filterable="columnfilterable(column)"
                        :placeholder="validatenull(column.placeholder) ? '请选择' : column.placeholder"
                        :clearable="column.clearable === false ? false : true"
                      >
                        <my-option
                          v-for="item in column.dicData"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </my-option>
                      </my-select>
                    </template>
                    <template v-if="['daterange'].includes(columnType(column))">
                      <my-date-picker
                        :type="column.type"
                        :editable="false"
                        :clearable="true"
                        :default-time="['00:00:00', '23:59:59']"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :range-separator="$t('Prompt.datePicker.separator')"
                        :start-placeholder="`${$t('Prompt.select')}${$t(
                          'Prompt.datePicker.startPlaceholder'
                        )}`"
                        :end-placeholder="`${$t('Prompt.select')}${$t(
                          'Prompt.datePicker.endPlaceholder'
                        )}`"
                        @change="handleChangeSearchDate"
                        v-model="form[column.prop]"
                      >
                      </my-date-picker>
                    </template>
                  </template>
                </el-form-item>
              </el-col>
            </template>
          </template>

          <slot></slot>
          <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="24">
            <el-form-item label-width="0px">
              <el-button
                type="primary"
                icon="el-icon-search"
                class="search-btn"
                :class="{ 'search-btn2': fontSize === 2 }"
                @click.stop="handleSearch"
                >{{ $t("Button.search") }}
              </el-button>
              <el-button @click.stop="handleSearchReset" icon="el-icon-delete"
                class="search-btn"
                :class="{ 'search-btn2': fontSize === 2 }"
                >{{ $t("Button.clear") }}
              </el-button>

              <slot name="btnOther"></slot>

              <template v-if="adColumns.length > 0">
                <el-button
                  type="text"
                  class="adSearch-title"
                  @click.stop="changeSearchVisible"
                  >{{ searchVisible ? "收起" : "展开" }}
                  <i
                    :class="
                      searchVisible ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                    "
                    class="el-icon--right"
                  ></i>
                </el-button>
              </template>

              <template v-if="$slots.menu">
                <el-divider direction="vertical"></el-divider>
                <slot name="menu"></slot>
              </template>
            </el-form-item>
          </el-col>
          <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
            <slot name="aferSearch"></slot>
          </el-col>
        </el-row>
      </section>
    </el-form>
  </section>
</template>

<script>
import { vaildData } from '@util/validate'
import { mapGetters } from "vuex"
import { updateMixin } from "@/mixins/global"
export default {
  mixins: [updateMixin],
  name: 'tableHeaderSearch',
  model: {
    prop: 'value', // props接受的变量名称
    event: 'change' // 定义一个方法
  },
  watch: {
    value: {
      handler(val) {
        this.init(val)
      },
      deep: true
    }
    // form: {
    //   handler(val) {
    //     this.$emit('change', val)
    //   },
    //   deep: true
    // }
  },
  methods: {
    changeSearchVisible() {
      this.searchVisible = !this.searchVisible
      this.$emit('changeSearchVisible')
    },
    init(value) {
      this.form = Object.assign({}, value)
    },
    handleSearchReset() {
      this.$refs['searchFormRef'].resetFields()
      this.$emit('search-reset')
    },
    handleSearch() {
      this.$emit('change', this.form)
      this.$emit('search')
    },
    handleChangeSearchDate() {
      this.$emit('dateChange', this.form)
    }
  },
  props: {
    value: { // 高级搜索表单
      type: Object,
      default: () => {
        return {}
      }
    },
    options: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters(['fontConfig']),
    columnfilterable(v) {
      return (v) => {
        return vaildData(v.filterable, false)
      }
    },
    fontSize() {
      if (this.validatenull(this.fontConfig)) {
        return 1
      }
      return JSON.parse(this.fontConfig).fontSize
    },
    columnLabelWidth(v) {
      return (v) => {
        const width = vaildData(v.labelWidth, '75px')
        const width2 = width.split('px')[0]
        return Number(width2) + 15 + 'px'
      }
    },
    labelWidth() {
      const width = vaildData(this.options.labelWidth, '75px')
      const width2 = width.split('px')[0]
      return Number(width2) + 15 + 'px'
    },
    formClass() {
      return this.options.formClass || ''
    },
    columns() {
      return this.options.column || []
    },
    adColumns() {
      return this.options.adColumn || []
    },
    columnType(v) {
      return (v) => {
        return vaildData(v.type, 'input')
      }
    },
    colxl(v) {
      return (v) => {
        return vaildData(v.xl, vaildData(v.span, 6))
      }
    },
    collg(v) {
      return (v) => {
        return vaildData(v.lg, vaildData(v.span, 8))
      }
    },
    colmd(v) {
      return (v) => {
        return vaildData(v.md, vaildData(v.span, 8))
      }
    },
    colsm(v) {
      return (v) => {
        return vaildData(v.sm, vaildData(v.span, 12))
      }
    }
  },
  created() {
    this.$nextTick(() => {
      this.form = Object.assign({}, this.value)
    })
  },
  data() {
    return {
      form: {},
      searchVisible: false
    }
  }
}
</script>

<style scoped>
.search-btn {
  padding: 8px 10px;
  vertical-align: top;
}
.search-btn2 {
  padding: 6px 8px;
  vertical-align: top;
}
.el-button--text {
  color: #1890ff;
}
.adSearch-title {
  padding: 8px 0px;
}
</style>
