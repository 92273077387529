import html2canvas from "html2canvas"
import { Loading } from 'element-ui'
 
// 打印类属性、方法定义
/* eslint-disable */
const Print = function (dom, options) {
  let imgsList = []
  if (!(this instanceof Print)) return new Print(dom, options);
 
  // this.options = this.extend({
  //   'noPrint': '.no-print'
  // }, options);
  let loadingInstance = Loading.service({
    lock: true,
    fullscreen: true,
    text: `构建面单数据中，请稍候`,
    background: 'rgba(0, 0, 0, 0.5)'
  });
  for (let i = 0; i < options.size; i++) {
    if ((typeof dom[i]) === "string") {
      this.cloneDom = document.querySelector(dom[i]);
    } else {
      this.isDOM(dom[i])
      this.cloneDom = this.isDOM(dom[i]) ? dom[i] : dom[i].$el;
    }
    //主要修改：将打印的dom转换成图片
    html2canvas(this.cloneDom,  {
        scale: 1, //缩放比例,默认为1
        allowTaint: true,//是否允许跨域图像污染画布
        useCORS: true, 
        width: 760, //宽度
        height: 1296, //高度
        backgroundColor: '#fff', //画布的背景色，默认为透明
      }).then(canvas => {
        console.log('i', i);
        this.imgmap = canvas.toDataURL()
        const mapObj = {
          i, 
          map: this.imgmap
        }
        imgsList.push(mapObj)
        // const a = document.createElement('a')
        // a.href = this.imgmap
        // a.setAttribute('download', '图片名字')
        // a.click()
        if (i === options.size - 1) {
          imgsList.sort((a,b)=>{ return a.i-b.i})
          setTimeout(()=>{
            imgsList.forEach(imgmap => {
              this.dom = `<div style='width:100%;height:100%;'><img style='width:100%;height:auto;' src='${imgmap.map}'/></div>`
              this.init()
            })
          }, 50)
          loadingInstance.close()
        }
    })
  }
};
Print.prototype = {
  init: function () {
    var content = this.dom;
    this.writeIframe(content);
  },
  extend: function (obj, obj2) {
    for (var k in obj2) {
      obj[k] = obj2[k];
    }
    return obj;
  },
 
  writeIframe: function (content) {
    var w, doc, iframe = document.createElement('iframe'),
      f = document.body.appendChild(iframe);
    iframe.id = "myIframe";
    w = f.contentWindow || f.contentDocument;
    doc = f.contentDocument || f.contentWindow.document;
    doc.open();
    doc.write(content);
    doc.close();
    let iframeDocumentList = document.getElementsByTagName("iframe")
    for (let index = 0; index < iframeDocumentList.length; index++) {
      const element = iframeDocumentList[index].contentDocument
      element.body.style.margin = "0"
    }
    // let iframeDocument = document.getElementsByTagName("iframe")[0].contentDocument;
    // iframeDocument.body.style.margin = "0 2.9px";
    var _this = this
    iframe.onload = function(){
      _this.toPrint(w);
      setTimeout(function () {
        document.body.removeChild(iframe)
      }, 100)
    }
  },
 
  toPrint: function (frameWindow) {
    try {
      setTimeout(function () {
        frameWindow.focus();
        try {
          if (!frameWindow.document.execCommand('print', false, null)) {
            loadingInstance.close()
            frameWindow.print();
          }
        } catch (e) {
          loadingInstance.close()
          frameWindow.print();
        }
        frameWindow.close();
      }, 10);
    } catch (err) {
      console.log('err', err);
    }
  },
  isDOM: (typeof HTMLElement === 'object') ?
    function (obj) {
      return obj instanceof HTMLElement;
    } :
    function (obj) {
      return obj && typeof obj === 'object' && obj.nodeType === 1 && typeof obj.nodeName === 'string';
    }
};
const MyPlugin = {}
MyPlugin.install = function (Vue, options) {
  // 4. 添加实例方法
  Vue.prototype.$print = Print
}
export default MyPlugin