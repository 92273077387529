// 根据分辨率计算差值
export const getDiffByResolution = () => {
  const height = window.screen.height

  if (height >= 1080) {
    return {
      height: 80
    } // 大屏幕
  } else if (height >= 900) {
    return {
      height: 120
    } // 中屏幕
  } else if (height >= 768) {
    return {
      height: 210
    } // 小屏幕
  } else if (height >= 600) {
    return {
      height: 360
    } // 小屏幕
  } else {
    return 380 // 超小屏幕
  }
}

export default {
  getScreen: function() {
    const width = document.body.clientWidth

    if (width >= 1800) {
      return 4 // 超大屏幕
    } else if (width >= 1280) {
      return 3 // 大屏幕
    } else if (width >= 1024) {
      return 2 // 中屏幕
    } else if (width >= 768) {
      return 1 // 小屏幕
    } else {
      return 0 // 超小屏幕
    }
  }
}
