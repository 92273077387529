<template>
  <el-select
    ref="selectRef"
    :placeholder="placeholderNew"
    :name="name"
    :id="id"
    v-model="selectedValue"
    :disabled="disabled"
    :filterable="filterable"
    :allowCreate="allowCreate"
    :loading="loading"
    :popperClass="popperClass"
    :remote="remote"
    :loadingText="loadingText"
    :noMatchText="noMatchText"
    :noDataText="noDataText"
    :remoteMethod="remoteMethod"
    :filterMethod="filterMethod"
    :multiple="multiple"
    :defaultFirstOption="defaultFirstOption"
    :reserveKeyword="reserveKeyword"
    :valueKey="valueKey"
    :collapseTags="collapseTags"
    :popperAppendToBody="popperAppendToBody"
    :multipleLimit="multipleLimit"
    :automaticDropdown="automaticDropdown"
    :autocomplete="autocomplete"
    :label="label"
    :clearable="clearable"
    @blur="handleBlur"
    @focus="handleFocus"
    @change="handleChange"
    @visibleChange="handleVisibleChange"
    @handleRemoveTag="handleRemoveTag"
    @clear="handleClear"
  >
    <slot></slot>
    <template v-for="slotName in slotNames" :slot="slotName">
      <slot :name="slotName"></slot>
      <!-- 渲染原本的插槽内容 -->
    </template>
  </el-select>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'my-select',

  props: {
    name: String,
    id: String,
    value: {
      required: true
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    automaticDropdown: Boolean,
    size: String,
    disabled: Boolean,
    clearable: Boolean,
    filterable: Boolean,
    allowCreate: Boolean,
    loading: Boolean,
    popperClass: String,
    remote: Boolean,
    loadingText: String,
    noMatchText: String,
    noDataText: String,
    remoteMethod: Function,
    filterMethod: Function,
    multiple: Boolean,
    multipleLimit: {
      type: Number,
      default: 0
    },
    placeholder: {
      type: String,
      required: false
    },
    defaultFirstOption: Boolean,
    reserveKeyword: Boolean,
    valueKey: {
      type: String,
      default: 'value'
    },
    collapseTags: Boolean,
    popperAppendToBody: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectedValue: this.value
    }
  },
  watch: {
    selectedValue(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.selectedValue = val
    }
  },
  computed: {
    ...mapGetters(['globalReplaceTextList']),
    placeholderNew() {
      let placeholderNew = this.placeholder
      if(placeholderNew) {
        this.globalReplaceTextList.forEach((element) => {
          if (placeholderNew.indexOf(element.name) > -1) {
            placeholderNew = placeholderNew.replaceAll(
              element.name,
              element.value
            )
          }
        })
      }
      return placeholderNew
    },
    slotNames() {
      // 这里列出了 el-select 原本的插槽名称
      return ['prefix', 'suffix', 'clear', 'empty', 'dropdown', 'option']
    }
  },
  methods: {
    blur() {
      this.$refs['selectRef'].blur()
    },
    focus() {
      this.$refs['selectRef'].focus()
    },
    handleBlur(event) {
      this.$emit('blur', event)
    },
    handleFocus(event) {
      this.$emit('focus', event)
    },
    handleChange(value) {
      this.$nextTick(() => {
        this.$emit('change', value)
      })
    },
    handleVisibleChange(value) {
      this.$emit('visible-change', value)
    },
    handleRemoveTag(value) {
      this.$emit('remove-tag', value)
    },
    handleClear() {
      if(this.multiple) {
        this.selectedValue = []
      }
      this.$emit('input', this.selectedValue)
      this.$emit('clear')
    }
  }
}
</script>
