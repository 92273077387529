import Vue from 'vue'

import basicContainer from './basic-container/main'
import scrollContainer from './scroll-container/index'
import viewForm from './view-form'
import tableHeaderSearch from './table-search/tableHeaderSearch'
import tableHeaderSearch2 from './table-search/tableHeaderSearch2'
import tableHeaderSearch3 from './table-search/tableHeaderSearch3'
import tableHeaderSearch4 from './table-search/tableHeaderSearch4'
import tableHeaderSearch5 from './table-search/tableHeaderSearch5'
import BorderBox1 from './border-box/border-box-1'
import BorderBox11 from './border-box/border-box-11'
import BorderBox2 from './border-box/border-box-2'
import BorderBox3 from './border-box/border-box-3'
import EnumSelect from './enum-select/index'
import myImage from './my-image/index'
import imgViewer from './my-image/image-viewer'
import myOption from './my-option'
import myInput from './my-input'
import myInputNumber from './my-input-number'
import mySelect from './my-select'
import myCascader from './my-cascader'
import myEmpty from './my-empty'
import myDatePicker from './my-date-picker'
import myAutocomplete from './my-autocomplete'
import operationMenu from './operation-Menu/operationMenu'

Vue.component('basicContainer', basicContainer)
Vue.component('scrollContainer', scrollContainer)
Vue.component('viewForm', viewForm)
Vue.component('tableHeaderSearch', tableHeaderSearch)
Vue.component('tableHeaderSearch2', tableHeaderSearch2)
Vue.component('tableHeaderSearch3', tableHeaderSearch3)
Vue.component('tableHeaderSearch4', tableHeaderSearch4)
Vue.component('tableHeaderSearch5', tableHeaderSearch5)
Vue.component('BorderBox1', BorderBox1)
Vue.component('BorderBox11', BorderBox11)
Vue.component('BorderBox2', BorderBox2)
Vue.component('BorderBox3', BorderBox3)
Vue.component('EnumSelect', EnumSelect)
Vue.component('myImage', myImage)
Vue.component('myEmpty', myEmpty)
Vue.component('imgViewer', imgViewer)
Vue.component('myOption', myOption)
Vue.component('myInput', myInput)
Vue.component('myInputNumber', myInputNumber)
Vue.component('mySelect', mySelect)
Vue.component('myCascader', myCascader)
Vue.component('myDatePicker', myDatePicker)
Vue.component('myAutocomplete', myAutocomplete)
Vue.component('operationMenu', operationMenu)
