<template>
  <section
    class="cr-table-header__search"
    :class="moreSearchVisible ? 'search-abosulute' : ''"
    :style="{ height: searchAbosuluteHeight }"
  >
    <el-form
      label-suffix="："
      label-position="right"
      ref="searchFormRef"
      :class="moreSearchVisible ? 'bottom-shadow' : ''"
      style="background: #fff"
      :model="form"
      :label-width="labelWidth"
      @submit.native.prevent
    >
      <section>
        <slot name="beforeSearch"></slot>
      </section>
      <section class="flex-flow-box">
        <div v-for="(column, index) in columns" class="flex-item" :key="index">
          <el-form-item
            v-if="validatenull(column.kind) && column.show"
            :label="column.label"
            :prop="column.prop"
            :label-width="columnLabelWidth(column)"
          >
            <slot v-if="column.slot" :name="column.prop"></slot>
            <template v-else>
              <template v-if="columnType(column) === 'checkbox'">
                <el-checkbox
                  :ref="column.prop + 'checkbox'"
                  @keyup.enter.native="handleSearch"
                  @clear="handleSearch"
                  v-model.trim="form[column.prop]"
                  >{{ column.placeholder }}</el-checkbox
                >
              </template>
              <template v-if="columnType(column) === 'input'">
                <my-input
                  :ref="column.prop + 'input'"
                  :placeholder="
                    validatenull(column.placeholder)
                      ? '请输入'
                      : column.placeholder
                  "
                  :clearable="true"
                  @keyup.enter.native="handleSearch"
                  @clear="handleSearch"
                  v-model.trim="form[column.prop]"
                >
                  <template slot="prepend" v-if="column.prepend">{{
                    column.prependLabel
                  }}</template>
                </my-input>
              </template>
              <template v-if="columnType(column) === 'select'">
                <my-select
                  :ref="column.prop + 'select'"
                  v-model="form[column.prop]"
                  :filterable="columnfilterable(column)"
                  :placeholder="
                    validatenull(column.placeholder)
                      ? '请选择'
                      : column.placeholder
                  "
                  @clear="handleSearch"
                  :clearable="column.clearable === false ? false : true"
                >
                  <my-option
                    v-for="item in column.dicData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </my-option>
                </my-select>
              </template>
              <template v-if="columnType(column) === 'multiple_select'">
                <my-select
                  :ref="column.prop + 'multiple_select'"
                  v-model="form[column.prop]"
                  :clearable="column.clearable === false ? false : true"
                  @clear="handleSearch"
                  multiple
                  collapse-tags
                  default-first-option
                  :filterable="columnfilterable(column)"
                  :placeholder="
                    validatenull(column.placeholder)
                      ? '请选择'
                      : column.placeholder
                  "
                >
                  <my-option
                    v-for="item in column.dicData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </my-option>
                </my-select>
              </template>

              <template v-if="['daterange'].includes(columnType(column))">
                <my-date-picker
                  :ref="column.prop + 'daterange'"
                  :type="column.type"
                  :editable="false"
                  :clearable="true"
                  :default-time="['00:00:00', '23:59:59']"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :range-separator="$t('Prompt.datePicker.separator')"
                  :start-placeholder="`${$t(
                    'Prompt.datePicker.startPlaceholder'
                  )}`"
                  :picker-options="column.pickerOptions"
                  :end-placeholder="`${$t('Prompt.datePicker.endPlaceholder')}`"
                  v-model="form[column.prop]"
                >
                </my-date-picker>
              </template>
              <template v-if="['datetimerange'].includes(columnType(column))">
                <my-date-picker
                  :ref="column.prop + 'datetimerange'"
                  :type="column.type"
                  :editable="false"
                  :clearable="true"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :range-separator="$t('Prompt.datePicker.separator')"
                  :start-placeholder="`${$t(
                    'Prompt.datePicker.startPlaceholder'
                  )}`"
                  :picker-options="column.pickerOptions"
                  :end-placeholder="`${$t('Prompt.datePicker.endPlaceholder')}`"
                  v-model="form[column.prop]"
                >
                </my-date-picker>
              </template>
              <template v-if="['pcdCode'].includes(columnType(column))">
                <selection-ad-code
                  :ref="column.prop + 'pcdCode'"
                  @change="changePcdCode"
                  :checkStrictly="true"
                  v-model.trim="form[column.prop]"
                  style="width: 100%"
                ></selection-ad-code>
                <!-- <my-date-picker
                  :ref="column.prop + 'datetimerange'"
                  :type="column.type"
                  :editable="false"
                  :clearable="true"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :range-separator="$t('Prompt.datePicker.separator')"
                  :start-placeholder="`${$t(
                    'Prompt.datePicker.startPlaceholder'
                  )}`"
                  :picker-options="column.pickerOptions"
                  :end-placeholder="`${$t('Prompt.datePicker.endPlaceholder')}`"
                  v-model="form[column.prop]"
                >
                </my-date-picker> -->
              </template>
            </template>
          </el-form-item>
          <el-form-item
            v-if="column.kind === 'fold-input'"
            label-width="0px"
            class="d-flex flex-justify-content-end fold_input"
          >
            <my-input :placeholder="'请输入'"
                      clearable
                      v-model.trim="form[column.searchValue]">
              <my-select v-model="column.searchKey" slot="prepend" :style="{ width: labelWidth + ' !important' }" @change="changeFoldSearchKey(column)">
                <my-option v-for="ele in column.item" :label="ele.label" :value="ele.prop" :key="ele.prop"></my-option>
              </my-select>
            </my-input>
          </el-form-item>

          <el-form-item
            v-if="column.kind === 'searchBtn'"
            label-width="0px"
            class="d-flex flex-justify-content-end"
          >
            <el-button
              type="primary"
              size="mini"
              icon="el-icon-search"
              class="search-btn"
              :class="{ 'search-btn2': fontSize === 2 }"
              @click.stop="handleSearch"
              >{{ $t('Button.search') }}
            </el-button>
            <el-button @click.stop="handleSearchReset" icon="el-icon-delete" 
              class="search-btn"
              :class="{ 'search-btn2': fontSize === 2 }"
              size="mini"
              >{{ $t('Button.clear') }}
            </el-button>
            <template
              v-if="columns.length > columnsRowCount || moreSearchVisible"
            >
              <el-button
                type="text"
                size="mini"
                class="search-btn"
                :class="{ 'search-btn2': fontSize === 2 }"
                @click.stop="changeMoreSearchVisible"
                >{{ moreSearchVisible ? '收起' : '展开' }}
                <i
                  :class="
                    moreSearchVisible
                      ? 'el-icon-arrow-up'
                      : 'el-icon-arrow-down'
                  "
                  class="el-icon--right"
                ></i>
              </el-button>
            </template>
          </el-form-item>
        </div>
        <el-row :span="24">
          <el-col :span="24">
            <slot name="btnOther"></slot>
          </el-col>
        </el-row>
      </section>
    </el-form>
    <section
      ref="columnsHasValueBox"
      class="columns-has-value-box"
      :class="moreSearchVisible ? 'bottom-shadow2' : ''"
      v-if="showColumnsHasValueBox && columnsHasValue.length > 0"
    >
      <div
        class="columns-has-value-item"
        v-for="column in columnsHasValue"
        :key="column.prop"
      >
        <el-tooltip
          class="item"
          effect="dark"
          :content="buildColumsValue2(column)"
          placement="bottom"
        >
          <div class="tag-item">
            <div class="tag-item-content" @click="focusPropInput(column)">
              {{ buildColumsValue(column) }}
            </div>
            <i
              class="el-icon-close close-icon"
              @click.prevent="clearColumnValue(column)"
            ></i>
          </div>
        </el-tooltip>
      </div>
    </section>
  </section>
</template>

<script>
import { vaildData } from '@util/validate'
import { mapGetters } from "vuex"

import { updateMixin } from "@/mixins/global"
export default {
  mixins: [updateMixin],
  name: 'tableHeaderSearch',
  model: {
    prop: 'value', // props接受的变量名称
    event: 'change' // 定义一个方法
  },
  components: {
    selectionAdCode: () => import('@/views/components/selectionAdCode')
  },
  watch: {
    value: {
      handler(val) {
        this.init(val)
      },
      deep: true
    },
    columnsRowCount: {
      handler(val) {
        const searchBtnIndex = this.options.column.findIndex(item => {
          return item.kind === 'searchBtn'
        })
        if (searchBtnIndex > -1) {
          this.options.column.splice(searchBtnIndex, 1)
        }
        this.options.column.splice(this.screen + 1, 0, this.searchBtnColumn)
        this.options.column = this.options.column.map((item, index) => {
          if (index >= this.columnsRowCount) { // 大于显示个数
            if (this.moreSearchVisible) { // 展示
              item.show = true
            } else { // 不展示
              item.show = false
            }
          } else {
            item.show = true
          }
          return item
        })
      },
      deep: true
    },
    columnsHasValueLength: {
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.$nextTick(() => {
            this.$emit('calcTableHeight')
          })
        }
      },
      deep: true
    }
    // form: {
    //   handler(val) {
    //     this.$emit('change', val)
    //   },
    //   deep: true
    // }
  },
  methods: {
    // getScreen() {
    //   const width = document.body.clientWidth
    //   if (width >= 1800) {
    //     return 4 // 超大屏幕
    //   } else if (width >= 1280) {
    //     return 3 // 大屏幕
    //   } else if (width >= 1024) {
    //     return 2 // 中屏幕
    //   } else if (width >= 768) {
    //     return 1 // 小屏幕
    //   } else {
    //     return 0 // 超小屏幕
    //   }
    // },
    changePcdCode(codes, labels) {
      this.pcdName = labels.join('/')
    },
    changeFoldSearchKey(column) {
      column.searchKeyLabel = column.item.find((ele) => {
        return ele.prop === column.searchKey
      }).label
    },
    focusPropInput(column) {
      if (!this.moreSearchVisible && column.propIndex > this.screen) {
        this.changeMoreSearchVisible()
      }
      this.$nextTick(() => {
        const refName = column.prop.toString() + column.type.toString()
        if (column.type === 'select' || column.type === 'multiple_select') {
          this.$refs[refName][0].toggleMenu()
        } else {
          this.$refs[refName][0].focus()
        }
      })
    },
    buildColumsValue(column) {
      let propValue = ''
      if (column.slot && this.validatenull(column.type)) {
        propValue = this.form[column.prop]
      } else if (this.validatenull(column.type) || column.type === 'input') {
        propValue = this.form[column.prop]
      } else if (column.type === 'select') {
        const dicDataItem = column.dicData.find((item) => {
          return item.value === this.form[column.prop]
        })
        propValue = dicDataItem ? dicDataItem.label : ''
      } else if (column.type === 'daterange' || column.type === 'datetimerange') {
        propValue = this.form[column.prop]
      } else if (column.type === 'pcdCode') {
        propValue = this.pcdName
      } else if (column.type === 'multiple_select') {
        let multiDicDataItem = ''
        column.dicData.forEach(item => {
          if (this.form[column.prop].indexOf(item.value) > -1) {
            multiDicDataItem += ',' + item.label
          }
        })
        if (multiDicDataItem !== '') {
          propValue = multiDicDataItem.slice(1, multiDicDataItem.length)
        }
      } else if (column.type === 'checkbox') {
        propValue = this.form[column.prop] ? column.placeholder + ':是' : column.placeholder + ':否'
      }
      let values = column.label + ': ' + propValue
      if (column.type === 'checkbox') {
        values = values.substring(1, values.length)
      }
      if (column.kind === 'fold-input') {
        values = column.searchKeyLabel + ':' + this.form[column.searchValue]
      }
      if (values.length > 16) {
        return values.slice(0, 16) + '...'
      }
      return values
    },
    buildColumsValue2(column) {
      let propValue = ''
      if (column.slot && this.validatenull(column.type)) {
        propValue = this.form[column.prop]
      } else if (this.validatenull(column.type) || column.type === 'input') {
        propValue = this.form[column.prop]
      } else if (column.type === 'select') {
        const dicDataItem = column.dicData.find((item) => {
          return item.value === this.form[column.prop]
        })
        propValue = dicDataItem ? dicDataItem.label : ''
      } else if (column.type === 'daterange' || column.type === 'datetimerange') {
        propValue = this.form[column.prop][0] + '-' + this.form[column.prop][1]
      } else if (column.type === 'pcdCode') {
        propValue = this.pcdName
      } else if (column.type === 'multiple_select') {
        let multiDicDataItem = ''
        column.dicData.forEach(item => {
          if (this.form[column.prop].indexOf(item.value) > -1) {
            multiDicDataItem += ',' + item.label
          }
        })
        if (multiDicDataItem !== '') {
          propValue = multiDicDataItem.slice(1, multiDicDataItem.length)
        }
      } else if (column.type === 'checkbox') {
        propValue = this.form[column.prop] ? '是' : column.placeholder + '否'
      }
      if (column.kind === 'fold-input') {
        propValue = this.form[column.searchValue]
      }
      return propValue
    },
    clearColumnValue(column) {
      this.form[column.prop] = ''
      if (column.kind === 'fold-input') {
        this.form[column.searchValue] = ''
      }
      this.$emit('change', this.form)
      this.$emit('search')
    },
    colxl(v, index = 0) {
      return vaildData(v.xl, index ? 4 : 5)
    },
    collg(v, index = 0) {
      return vaildData(v.lg, vaildData(v.span, 8))
    },
    colmd(v, index = 0) {
      return vaildData(v.md, vaildData(v.span, 8))
    },
    colsm(v, index = 0) {
      return vaildData(v.sm, vaildData(v.span, 12))
    },
    changeMoreSearchVisible() {
      this.moreSearchVisible = !this.moreSearchVisible // 判断后面的搜索条件要不要展示
      this.options.column = this.options.column.map((item, index) => {
        if (index >= this.columnsRowCount) { // 大于显示个数
          if (this.moreSearchVisible) { // 展示
            item.show = true
          } else { // 不展示
            item.show = false
          }
        } else {
          item.show = true
        }
        return item
      })
      this.$emit('calcTableHeight')
    },
    init(value) {
      this.form = Object.assign({}, value)
    },
    handleSearchReset() {
      this.$refs['searchFormRef'].resetFields()
      this.$emit('search-reset')
    },
    handleSearch() {
      this.$emit('change', this.form)
      this.$emit('search')
      this.closeMoreSearch()
    },
    closeMoreSearch() {
      if (this.moreSearchVisible) {
        this.moreSearchVisible = false // 判断后面的搜索条件要不要展示
        this.options.column = this.options.column.map((item, index) => {
          if (index >= this.columnsRowCount) { // 大于显示个数
            if (this.moreSearchVisible) { // 展示
              item.show = true
            } else { // 不展示
              item.show = false
            }
          } else {
            item.show = true
          }
          return item
        })
        this.$emit('calcTableHeight')
      }
    },
    handleChangeSearchDate() {
      this.$emit('dateChange', this.form)
    }
  },
  props: {
    value: { // 高级搜索表单
      type: Object,
      default: () => {
        return '{}'
      }
    },
    options: {
      type: Object,
      default: () => {
        return {}
      }
    },
    showColumnsHasValueBox: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },
  computed: {
    ...mapGetters(["screen", 'fontConfig']),
    columnsRowCount() {
      return 3
    },
    columnfilterable(v) {
      return (v) => {
        return vaildData(v.filterable, false)
      }
    },
    fontSize() {
      if (this.validatenull(this.fontConfig)) {
        return 1
      }
      return JSON.parse(this.fontConfig).fontSize
    },
    fontConfigWidth() {
      if (this.fontSize === 2) {
        return 15
      }
      return 0
    },
    columnLabelWidth(v) {
      return (v) => {
        const width = vaildData(v.labelWidth, this.labelWidth)
        const width2 = width.split('px')[0]
        return Number(width2) + this.fontConfigWidth + 'px'
      }
    },
    labelWidth() {
      const width = vaildData(this.options.labelWidth, '75px')
      const width2 = width.split('px')[0]
      return Number(width2) + this.fontConfigWidth + 'px'
    },
    columns() {
      return this.options.column.map((item, index) => {
        item.propIndex = index
        return item
      })
    },
    columnsHasValue() {
      return this.columns.filter((column) => {
        return !this.validatenull(this.form[column.prop]) || !this.validatenull(this.form[column.searchValue])
      })
    },
    columnsHasValueLength() {
      return this.columnsHasValue.length
    },
    searchAbosuluteHeight() {
      if (this.moreSearchVisible) {
        let columnsHasValueBoxHeight = 0
        if (this.$refs['columnsHasValueBox']) {
          columnsHasValueBoxHeight = this.$refs['columnsHasValueBox'].offsetHeight
        }
        return 41 + columnsHasValueBoxHeight + 'px'
      } else {
        return 'auto'
      }
    },
    columnType(v) {
      return (v) => {
        return vaildData(v.type, 'input')
      }
    }
  },
  created() {
    this.options.column.splice(2, 0, this.searchBtnColumn) // 将搜索，清空按钮插入columns
    this.options.column = this.options.column.map((item, index) => {
      if (index >= this.columnsRowCount) { // 大于显示个数
        if (this.moreSearchVisible) { // 展示
          item.show = true
        } else { // 不展示
          item.show = false
        }
      } else {
        item.show = true
      }
      return item
    })
    this.form = Object.assign({}, this.value)
  },
  data() {
    return {
      form: {},
      pcdName: '',
      searchBtnColumn: {
        kind: 'searchBtn'
      },
      moreSearchVisible: false
    }
  }
}
</script>

<style scoped lang="scss">
.search-btn {
  padding: 8px 2px;
  vertical-align: top;
}
.search-btn2 {
  padding: 6px 2px;
  vertical-align: top;
}
/deep/.el-input__inner {
  padding: 0 8px;
}
// /deep/.el-form-item__label {
//   font-size: 12px;
// }
/deep/.el-range-editor--small .el-range__icon {
  line-height: 32px;
}
.bottom-shadow {
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);
}
.bottom-shadow2 {
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 0px 12px 8px 12px !important;
}
.search-abosulute {
  position: relative;
}
.flex-flow-box {
  display: flex;
  flex-flow: row wrap;
}
.flex-item {
  padding: 0 4px;
  text-align: center;
  box-sizing: border-box;

  .el-form-item {
    margin-bottom: 8px;
  }
  .fold_input {
    /deep/.el-form-item__content {
      width: 100%;
    }
  }
}
@media screen and (min-width: 1024px) {
  .flex-item {
    flex: 0 0 100%;
  }
}
@media screen and (min-width: 1280px) {
  .flex-item {
    flex: 0 0 50%;
  }
}
@media screen and (min-width: 1800px) {
  .flex-item {
    flex: 0 0 33.3%;
  }
}

.el-button--text {
  color: #1890ff;
}
.tag-item-content {
  max-width: 250px;
  // text-overflow: -o-ellipsis-lastline;
  // overflow: hidden; //溢出内容隐藏
  // text-overflow: ellipsis; //文本溢出部分用省略号表示
  // display: -webkit-box; //特别显示模式
  // -webkit-line-clamp: 1; //行数
  // line-clamp: 1;
  // -webkit-box-orient: vertical; //盒子中内容竖直排列
}
.columns-has-value-box {
  color: #666666;
  background: #fff;
  display: flex;
  flex-flow: row wrap;
  padding: 0px 12px 10px 12px;
  border-bottom: 1px solid #f4f4f4;
  .columns-has-value-item {
    padding: 0;
    // text-align: center;
    // box-sizing: border-box;

    .tag-item {
      display: flex;
      padding: 6px 8px 6px 4px;
      justify-content: space-around;
      .close-icon {
        margin: 4px 0 0 8px;
      }

      .close-icon:hover {
        color: #333;
      }
    }
    .tag-item:hover {
      cursor: pointer;
      border-radius: 4px;
      background: #f4f4f4;
    }
  }
}
</style>
