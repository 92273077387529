var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "border-box-1" }, [
    _vm.title
      ? _c(
          "section",
          {
            staticClass: "border-box__header",
            style: { background: _vm.headerBgc }
          },
          [
            _vm.$slots.headerMenu
              ? _c(
                  "section",
                  { staticClass: "border-box__menu" },
                  [_vm._t("headerMenu")],
                  2
                )
              : _vm._e()
          ]
        )
      : _vm._e(),
    _c(
      "section",
      {
        staticClass: "border-box-1__content",
        class: [
          _vm.border ? "border-box-1__b" : "",
          _vm.padding ? "border-box-1__p" : ""
        ]
      },
      [
        _c("span", { staticClass: "border-box__title" }, [
          _vm._v(_vm._s(_vm.title))
        ]),
        _vm.$slots.menuRight
          ? _c(
              "section",
              { staticClass: "border-box__title-right" },
              [_vm._t("menuRight")],
              2
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "border-box__context" },
          [_vm._t("default")],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }