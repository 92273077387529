// import { setStore, getStore } from '@/util/store'
// import { diff } from '@/util/util'
// import website from '@/config/website'

const receipt = {
  state: {
    receiptList: [],
    sendList: [],
    takeList: [],
    giveOutList: []
  },
  actions: {},
  mutations: {
    RECEIPT_LIST: (state, action) => {
        state.receiptList = action
    },
    SEND_LIST: (state, action) => {
        state.sendList = action
    },
    TAKE_LIST: (state, action) => {
        state.takeList = action
    },
    GIVE_OUT_LIST: (state, action) => {
        state.giveOutList = action
    }
  }
}
export default receipt
