<template>
  <div class="upgrade-browser" v-if="isShowUpgrade">
    <div class="left">
      <div>为保证平台运营和使用的稳定性，建议</div>
      <div class="upload-box" @click="uploadBrowser">
        <el-link type="primary" style="font-size: 16px !important"
          >下载安装新版谷歌浏览器</el-link
        ><i
          class="el-icon-arrow-right"
          style="
            transform: translateY(1px);
            font-size: 18px !important;
            font-weight: bold;
          "
        ></i>
      </div>
    </div>
    <div @click="closeUpgrade"><i class="el-icon-close close-icon"></i></div>
  </div>
</template>
<script>
import { isNeedUpgradeBrowser } from '@util/util'
import sysConfigApi from "@/api/system/sysConfig"
import { updateMixin } from '@/mixins/global'
export default {
  mixins: [updateMixin],
  name: 'upgrade-browser',
  data() {
    return {
      isShowUpgrade: false,
      upgradeObj: {}
    }
  },
  methods: {
    getDownLoadBrowserUrl(type) {
      const params = {
        code: ''
      }
      if (type === "chrome") {
        params.code = 'CHROME_DOWN_LOAD_URL_WINDOWS'
      } else if (type === "safari") {
        params.code = 'CHROME_DOWN_LOAD_URL_APPLE'
      }
      sysConfigApi.getValueByCode(params)
        .then((ret) => {
          window.open(ret.data, '_blank')
        })
    },
    closeUpgrade() {
      this.isShowUpgrade = false
    },
    uploadBrowser() {
      this.getDownLoadBrowserUrl(this.upgradeObj.type)
    }
  },
  created() {
    this.$nextTick(() => {
      this.upgradeObj = isNeedUpgradeBrowser()
      if (this.upgradeObj.isNeed) {
        this.isShowUpgrade = true
      }
    })
  }
}
  </script>
  <style scoped lang="scss">
.upgrade-browser {
  /* position: absolute; */
  width: 100%;
  height: 55px;
  display: flex;
  color: #1890ff;
  justify-content: center;
  align-items: center;
  background: rgba(24, 144, 255, 0.14);

  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    .upload-box {
      width: 210px;
      height: 39px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      border-radius: 4px;
      background: rgba(255, 255, 255, 0.8);
    }
  }

  .close-icon {
    width: 30%;
    font-size: 24px;

    &:hover {
      color: black;
    }
  }
}
</style>
  