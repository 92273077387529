import http from '@/router/axios'
import { clipApiPrefix } from '@/config/env'

const commonApi = {}

/**

 * @date 2019-12-04 11:27:12
 * @Description: 查询字典
 * @Param:
 */
commonApi.queryAllDict = () => {
  return http.$GET(`${clipApiPrefix}/dict/queryAll`, {})
}

/**

 * @date 2019-12-24 16:38:18
 * @Description: 查询费用字典
 * @Param:
 */
commonApi.queryAllDictFee = () => {
  return http.$GET(`${clipApiPrefix}/dictFee/queryAll`, {})
}

/**
 * @date 2021-07-05
 * @Description: 查询员工信息
 * @Param:
 */
commonApi.getEmployeeDetail = (object) => {
  return http.$GET(`${clipApiPrefix}/employee/detail`, {
    params: object
  })
}

/**
 * @Description: 发送验证码(修改密码)
 */
commonApi.sendVerifyCodeByUpdatePwd = () => {
  return http.$POST(`${clipApiPrefix}/api/login/sendVerifyCodeByUpdatePwd`)
}

/**
 * @Description: 查询用户详情
 */
commonApi.getUserDetail = (object) => {
  return http.$POST(`${clipApiPrefix}/user/detail?id=${object}`)
}

/**
 * @date 2021-07-05
 * @Description: 查询员工资源树根据员工ID
 * @Param:
 */
commonApi.getResourceTreeByEmployeeId = (object) => {
  return http.$GET(`${clipApiPrefix}/employee/resourceTreeById`, {
    showLoading: false,
    params: object
  })
}

/**
 * @date 2021-07-05
 * @Description: 查询用户拥有的企业集合
 * @Param:
 */
commonApi.getListOrgByLoginUser = (object) => {
  return http.$POST(`${clipApiPrefix}/organizationCommon/listOrgByLoginUser`, {
    params: object
  })
}

/**
 * @date 2021-07-03
 * @Description: 切换企业
 * @Param:
 */
commonApi.switchOrg = (object) => {
  return http.$POST(`${clipApiPrefix}/api/login/switchOrg?orgId=${object}`, {
    meta: {
      isToken: true
    }
  })
}

/**
 * @date 2021-07-03
 * @Description: 获取最新版本
 * @Param:
 */
commonApi.checkIsNewestVersion = (object) => {
  return http.$GET(`${clipApiPrefix}/config/checkIsNewestVersion`, {
    meta: {
      isToken: true
    }
  })
}

/**
 * @date 2022-07-03
 * @Description: 根据经纬度解析 地址信息
 * @Param:
 */
commonApi.regeoPosition = (object) => {
  return http.$POST(`${clipApiPrefix}/mapService/regeoPosition`, {
    params: object,
    isParams: true
  })
}

/**
 * @date 2022-09-26
 * @Description: 待办项数据
 * @Param:
 */
commonApi.businessDataBacklog = (object) => {
  return http.$GET(`${clipApiPrefix}/businessData/get/backlog/data`, {
    params: object,
    isParams: true
  })
}

/**
 * @Description: 查询服务可用额度
 */
commonApi.queryQuota = (object) => {
  return http.$POST(`${clipApiPrefix}/business/item/quota/queryQuota`,
    {
      showLoading: false,
      params: object
    })
}

/**
 * @Description: 校验服务项额度
 */
commonApi.validQuota = (object) => {
  return http.$POST(`${clipApiPrefix}/business/item/quota/validQuota`,
    {
      showLoading: true,
      params: object
    })
}

/**
 * @Description: 表格查询条件配置查询
 */
commonApi.searchConfigQuery = (object) => {
  return http.$POST(`${clipApiPrefix}/searchConfig/query`,
    {
      showLoading: false,
      params: object
    })
}

/**
 * @Description: 表格查询条件配置修改
 */
commonApi.searchConfigModify = (object) => {
  return http.$POST(`${clipApiPrefix}/searchConfig/modify`,
    {
      showLoading: true,
      params: object
    })
}

/**
 * @Description: 客户端主动获取业务单号
 */
commonApi.getBizNo = (object) => {
  return http.$GET(`${clipApiPrefix}/biz/base/get/bizNo`,
    {
      showLoading: true,
      params: object
    })
}

/**
 * @Description: 查询临时缓存数据
 */
commonApi.getTemp = (object) => {
  return http.$GET(`${clipApiPrefix}/biz/base/get/temp`,
    {
      showLoading: true,
      params: object
    })
}

/**
 * @Description: 保存临时缓存数据
 */
commonApi.saveTemp = (object) => {
  return http.$POST(`${clipApiPrefix}/biz/base/save/temp`,
    {
      showLoading: false,
      params: object
    })
}

/**
 * @Description: 设置自定义多少日内隐藏广告
 */
commonApi.setADHidden = (object) => {
  return http.$POST(`${clipApiPrefix}/biz/base/set/hidden`,
    {
      showLoading: false,
      params: object
    })
}

/**
 * @Description: 查询广告多少日内是否显示(true 显示，false 不显示) 
 */
commonApi.showOrHiddenAD = (object) => {
  return http.$GET(`${clipApiPrefix}/biz/base/get/showOrHidden`,
    {
      showLoading: false,
      params: object
    })
}


export default commonApi
