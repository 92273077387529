var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "empty" },
    [
      _c("div", { staticClass: "mb8", staticStyle: { height: "50px" } }, [
        _c("img", { attrs: { src: _vm.image, alt: "" } })
      ]),
      _c("p", { staticClass: "desc" }, [_vm._v(_vm._s(_vm.desc))]),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }