var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "view-form", staticStyle: { width: "100%" } },
    [
      _c(
        "el-form",
        {
          attrs: {
            "label-position": _vm.labelPosition,
            "label-suffix": "：",
            "label-width": _vm.labelWidth
          }
        },
        [
          !_vm.hasGroup
            ? [
                _c(
                  "el-row",
                  { attrs: { span: 24, gutter: 20 } },
                  [
                    _vm._l(_vm.columns, function(column, index) {
                      return [
                        !column.hide
                          ? _c(
                              "el-col",
                              {
                                key: index,
                                attrs: {
                                  xl: column.span || 8,
                                  xs: 24,
                                  lg: column.span || 8
                                }
                              },
                              [
                                _c("form-item", {
                                  attrs: { column: column },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: column.prop + "Label",
                                        fn: function(scope) {
                                          return column.slotLabel
                                            ? [
                                                _vm._t(
                                                  column.prop + "Label",
                                                  null,
                                                  null,
                                                  scope
                                                )
                                              ]
                                            : undefined
                                        }
                                      },
                                      {
                                        key: column.prop,
                                        fn: function(scope) {
                                          return column.slot
                                            ? [
                                                _vm._t(
                                                  column.prop,
                                                  null,
                                                  null,
                                                  Object.assign(scope)
                                                )
                                              ]
                                            : undefined
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    })
                  ],
                  2
                )
              ]
            : _vm._e(),
          _vm.hasGroup
            ? _vm._l(_vm.columnGroup, function(group, gIndex) {
                return _c(
                  "section",
                  { key: group.prop, class: [gIndex > 0 ? "mt20" : ""] },
                  [
                    _c(
                      "border-box-3",
                      { attrs: { title: group.label } },
                      [
                        _c(
                          "el-row",
                          {
                            staticClass: "mt10",
                            attrs: { span: 24, gutter: 20 }
                          },
                          [
                            _vm._l(group.column, function(column, index) {
                              return [
                                !column.hide
                                  ? _c(
                                      "el-col",
                                      {
                                        key: index,
                                        attrs: {
                                          xl: column.span || 8,
                                          xs: 24,
                                          lg: column.span || 8
                                        }
                                      },
                                      [
                                        _c("form-item", {
                                          attrs: { column: column },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: column.prop + "Label",
                                                fn: function(scope) {
                                                  return column.slotLabel
                                                    ? [
                                                        _vm._t(
                                                          column.prop + "Label",
                                                          null,
                                                          null,
                                                          scope
                                                        )
                                                      ]
                                                    : undefined
                                                }
                                              },
                                              {
                                                key: column.prop,
                                                fn: function(scope) {
                                                  return column.slot
                                                    ? [
                                                        _vm._t(
                                                          column.prop,
                                                          null,
                                                          null,
                                                          Object.assign(scope)
                                                        )
                                                      ]
                                                    : undefined
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }