var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-autocomplete",
    {
      ref: "autocompleteRef",
      attrs: {
        valueKey: _vm.valueKey,
        popperClass: _vm.popperClass,
        popperOptions: _vm.popperOptions,
        placeholder: _vm.placeholderNew,
        size: _vm.size,
        maxlength: _vm.maxlength,
        minlength: _vm.minlength,
        fetchSuggestions: _vm.fetchSuggestions,
        triggerOnFocus: _vm.triggerOnFocus,
        customItem: _vm.customItem,
        selectWhenUnmatched: _vm.selectWhenUnmatched,
        debounce: _vm.debounce,
        placement: _vm.placement,
        hideLoading: _vm.hideLoading,
        resize: _vm.resize,
        popperAppendToBody: _vm.popperAppendToBody,
        highlightFirstItem: _vm.highlightFirstItem,
        form: _vm.form,
        disabled: _vm.disabled,
        readonly: _vm.readonly,
        type: _vm.type,
        autosize: _vm.autosize,
        autofocus: _vm.autofocus,
        max: _vm.max,
        min: _vm.min,
        autocomplete: _vm.autocomplete,
        validateEvent: _vm.validateEvent,
        suffixIcon: _vm.suffixIcon,
        prefixIcon: _vm.prefixIcon,
        label: _vm.label,
        clearable: _vm.clearable,
        showPassword: _vm.showPassword,
        showWordLimit: _vm.showWordLimit,
        tabindex: _vm.tabindex
      },
      on: {
        select: _vm.handleSelect,
        focus: _vm.handleFocus,
        change: _vm.handleChange,
        inpput: _vm.handleInput,
        clear: _vm.handleClear
      },
      scopedSlots: _vm._u(
        [
          {
            key: "default",
            fn: function(ref) {
              var item = ref.item
              return [_vm._t("default", null, { item: item })]
            }
          }
        ],
        null,
        true
      ),
      model: {
        value: _vm.setValue,
        callback: function($$v) {
          _vm.setValue = $$v
        },
        expression: "setValue"
      }
    },
    [
      _vm._l(_vm.slotNames, function(slotName) {
        return _c("template", { slot: slotName }, [_vm._t(slotName)], 2)
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }