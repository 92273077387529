import http from '@/router/axios'
import { clipApiPrefix } from '@/config/env'

const userApi = {}

// ****** System User ******

/**
 * @Description: 用户引导是否已读
 * @Param:
 */
userApi.isVersionNoviceGuide = (object) => {
  return http.$POST(`${clipApiPrefix}/api/versionNoviceGuide/isVersionNoviceGuide`,
    {
      params: object,
      isParams: true
    })
}

/**
 * @Description: 确认完成引导
 * @Param:
 */
 userApi.operVersionNoviceGuide = (object) => {
  return http.$POST(`${clipApiPrefix}/api/versionNoviceGuide/operVersionNoviceGuide`,
    {
      params: object,
      isParams: true
    })
}

/**

 * @date 2019-12-01 16:52:17
 * @Description: 用户列表查询
 * @Param:
 */
userApi.queryPageUser = (object) => {
  return http.$POST(`${clipApiPrefix}/userInfo/queryPage`,
    {
      params: object
    })
}

/**

 * @date 2019-12-03 14:30:08
 * @Description: 创建用户
 * @Param:
 */
userApi.onCreateUser = (object) => {
  return http.$POST(`${clipApiPrefix}/userInfo/add`,
    {
      showLoading: true,
      params: object
    })
}

/**

 * @date 2019-12-04 13:55:37
 * @Description: 更改用户信息
 * @Param:
 */
userApi.onModifyUser = (object) => {
  return http.$POST(`${clipApiPrefix}/userInfo/modify`,
    {
      showLoading: true,
      params: object
    })
}

/**

 * @date 2019-12-04 18:18:57
 * @Description: 删除用户
 * @Param:
 */
userApi.onRemoveUser = (object) => {
  return http.$POST(`${clipApiPrefix}/userInfo/modify`,
    {
      params: object,
      showLoading: true
    })
}

/**

 * @date 2019-12-04 17:47:32
 * @Description: 批量启用/停用
 * @Param:
 */
userApi.onBatchModifyState = (object) => {
  return http.$POST(`${clipApiPrefix}/userInfo/modifyBatch`,
    {
      params: object,
      showLoading: true
    })
}

/**

 * @date 2019-12-05 09:29:24
 * @Description: 查询登陆账号
 * @Param:
 */
userApi.queryPageUserAuth = (object) => {
  return http.$POST(`${clipApiPrefix}/userAuth/queryPage`,
    {
      params: object
    })
}

/**

 * @date 2019-12-05 11:24:46
 * @Description: 修改密码
 * @Param:
 */
userApi.onModifyUserPassword = (object) => {
  return http.$POST(`${clipApiPrefix}/userAuth/modifyPassword`,
    {
      params: object,
      showLoading: true
    })
}

/**

 * @date 2019-12-08 10:48:12
 * @Description: 查询用户列表（不分页）
 * @Param:
 */
userApi.queryAllUser = (object) => {
  return http.$POST(`${clipApiPrefix}/userInfo/queryFilteredUserInfo`,
    {
      params: object
    })
}

// ****** System User ******

/**

 * @date 2019-12-17 16:23:18
 * @Description: 查询平台管理员
 * @Param:
 */
userApi.queryUserManager = () => {
  return http.$POST(`${clipApiPrefix}/userInfo/queryManager`,
    {})
}

/**
 * @author：cc@zdu56.com
 * @date：2020/5/29
 * @Description：启用/停用企业账号
 * @Param：
 */
userApi.onModifyAuthBySubPlatform = (object) => {
  return http.$POST(`${clipApiPrefix}/userAuth/modifyAuthBySubPlatform`,
    {
      params: object,
      showLoading: true
    })
}

export default userApi
