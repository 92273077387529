import { render, staticRenderFns } from "./image-viewer.vue?vue&type=template&id=de91474e&scoped=true&"
import script from "./image-viewer.vue?vue&type=script&lang=js&"
export * from "./image-viewer.vue?vue&type=script&lang=js&"
import style0 from "./image-viewer.vue?vue&type=style&index=0&id=de91474e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de91474e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("G:\\logistic-view\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('de91474e')) {
      api.createRecord('de91474e', component.options)
    } else {
      api.reload('de91474e', component.options)
    }
    module.hot.accept("./image-viewer.vue?vue&type=template&id=de91474e&scoped=true&", function () {
      api.rerender('de91474e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/my-image/image-viewer.vue"
export default component.exports