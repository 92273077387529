var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-input",
    {
      ref: "inputRef",
      attrs: {
        placeholder: _vm.placeholderNew,
        size: _vm.size,
        resize: _vm.resize,
        form: _vm.form,
        disabled: _vm.disabled,
        readonly: _vm.readonly,
        type: _vm.type,
        autosize: _vm.autosize,
        autofocus: _vm.autofocus,
        max: _vm.max,
        min: _vm.min,
        autocomplete: _vm.autocomplete,
        validateEvent: _vm.validateEvent,
        suffixIcon: _vm.suffixIcon,
        prefixIcon: _vm.prefixIcon,
        label: _vm.label,
        clearable: _vm.clearable,
        showPassword: _vm.showPassword,
        showWordLimit: _vm.showWordLimit,
        tabindex: _vm.tabindex
      },
      on: {
        blur: _vm.handleBlur,
        focus: _vm.handleFocus,
        change: _vm.handleChange,
        input: _vm.handleInput,
        clear: _vm.handleClear
      },
      model: {
        value: _vm.value,
        callback: function($$v) {
          _vm.value = $$v
        },
        expression: "value"
      }
    },
    [
      _vm._t("default"),
      _vm._l(_vm.slotNames, function(slotName) {
        return _c("template", { slot: slotName }, [_vm._t(slotName)], 2)
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }