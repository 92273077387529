<template>
  <el-input
    ref="inputRef"
    :placeholder="placeholderNew"
    :size="size"
    :resize="resize"
    v-model="value"
    :form="form"
    :disabled="disabled"
    :readonly="readonly"
    :type="type"
    :autosize="autosize"
    :autofocus="autofocus"
    :max="max"
    :min="min"
    :autocomplete="autocomplete"
    :validateEvent="validateEvent"
    :suffixIcon="suffixIcon"
    :prefixIcon="prefixIcon"
    :label="label"
    :clearable="clearable"
    :showPassword="showPassword"
    :showWordLimit="showWordLimit"
    :tabindex="tabindex"
    @blur="handleBlur"
    @focus="handleFocus"
    @change="handleChange"
    @input="handleInput"
    @clear="handleClear"
  >
    <slot></slot>
    <template v-for="slotName in slotNames" :slot="slotName">
      <slot :name="slotName"></slot>
      <!-- 渲染原本的插槽内容 -->
    </template>
  </el-input>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'my-input',

  props: {
    placeholder: String,
    value: [String, Number],
    size: String,
    resize: String,
    form: String,
    disabled: Boolean,
    readonly: Boolean,
    autofocus: Boolean,
    max: Number,
    min: Number,
    type: {
      type: String,
      default: 'text'
    },
    autosize: {
      type: [Boolean, Object],
      default: false
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    validateEvent: {
      type: Boolean,
      default: true
    },
    suffixIcon: String,
    prefixIcon: String,
    label: String,
    clearable: {
      type: Boolean,
      default: false
    },
    showPassword: {
      type: Boolean,
      default: false
    },
    showWordLimit: {
      type: Boolean,
      default: false
    },
    tabindex: String
  },
  computed: {
    ...mapGetters(['globalReplaceTextList']),
    placeholderNew() {
      let placeholderNew = this.placeholder
      if(placeholderNew) {
        this.globalReplaceTextList.forEach((element) => {
          if (placeholderNew.indexOf(element.name) > -1) {
            placeholderNew = placeholderNew.replaceAll(
              element.name,
              element.value
            )
          }
        })
      }
      return placeholderNew
    },
    slotNames() {
      // 这里列出了 el-select 原本的插槽名称
      return ['prefix', 'suffix', 'append', 'prePend']
    }
  },
  methods: {
    blur() {
      this.$refs['inputRef'].blur()
    },
    focus() {
      this.$refs['inputRef'].focus()
    },
    select() {
      this.$refs['inputRef'].change()
    },
    handleBlur(event) {
      this.$emit('blur', event)
    },
    handleFocus(event) {
      this.$emit('focus', event)
    },
    handleChange(value) {
      this.$nextTick(() => {
        this.$emit('change', value)
      })
    },
    handleInput(value) {
      this.$emit('input', value)
    },
    handleClear() {
      this.$emit('clear')
    }
  }
}
</script>
