var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "scroll-container--height",
      class: { "scroll-container__menu": _vm.$slots.menu },
      attrs: { shadow: "always" }
    },
    [
      _vm._t("default"),
      _vm.$slots.menu
        ? _c(
            "section",
            { staticClass: "scroll-container--menu" },
            [_vm._t("menu")],
            2
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }