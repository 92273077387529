var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      ref: "selectRef",
      attrs: {
        placeholder: _vm.placeholderNew,
        name: _vm.name,
        id: _vm.id,
        disabled: _vm.disabled,
        filterable: _vm.filterable,
        allowCreate: _vm.allowCreate,
        loading: _vm.loading,
        popperClass: _vm.popperClass,
        remote: _vm.remote,
        loadingText: _vm.loadingText,
        noMatchText: _vm.noMatchText,
        noDataText: _vm.noDataText,
        remoteMethod: _vm.remoteMethod,
        filterMethod: _vm.filterMethod,
        multiple: _vm.multiple,
        defaultFirstOption: _vm.defaultFirstOption,
        reserveKeyword: _vm.reserveKeyword,
        valueKey: _vm.valueKey,
        collapseTags: _vm.collapseTags,
        popperAppendToBody: _vm.popperAppendToBody,
        multipleLimit: _vm.multipleLimit,
        automaticDropdown: _vm.automaticDropdown,
        autocomplete: _vm.autocomplete,
        label: _vm.label,
        clearable: _vm.clearable
      },
      on: {
        blur: _vm.handleBlur,
        focus: _vm.handleFocus,
        change: _vm.handleChange,
        visibleChange: _vm.handleVisibleChange,
        handleRemoveTag: _vm.handleRemoveTag,
        clear: _vm.handleClear
      },
      model: {
        value: _vm.selectedValue,
        callback: function($$v) {
          _vm.selectedValue = $$v
        },
        expression: "selectedValue"
      }
    },
    [
      _vm._t("default"),
      _vm._l(_vm.slotNames, function(slotName) {
        return _c("template", { slot: slotName }, [_vm._t(slotName)], 2)
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }